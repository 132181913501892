import React, { useState, useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import * as Constant from '../Constant'
import Skeleton from 'react-loading-skeleton';
import { FaChevronRight } from "react-icons/fa"
import Modal from 'react-bootstrap/Modal';
import quizvideo from '../../assets/videos/Quizz.mp4'
import './scroll.css'
import Swal from "sweetalert2";

import { FaCheckCircle, FaTimesCircle, FaCheck } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { AiOutlineStar } from 'react-icons/ai';
import yellowStar from '../../assets/img/yellow_star.png'

function TopicsDetails() {
    const modalRef = useRef(null);

    const { cid } = useParams()
    const { id } = useParams()
    const [htmlContent, setHtmlContent] = useState('');
    const [videourl, setVideoUrl] = useState('');
    const [topicList, setTopicList] = useState([]);
    const [subLevel, setSubLevel] = useState([]);
    const [loadingLevel, isLoadingLevel] = useState(true);
    const [loadingTopic, isLoadingTopic] = useState(true);
    const [loadingvideo, isLoadingVideo] = useState(true);
    const [subjectName, setsubjectName] = useState();
    const [levelName, setlevelName] = useState();
    const [modal, setShowModal] = useState(false);
    const [quiz, setQuiz] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [queIndexMedium, setQueIndexMedium] = useState(0);
    const [queIndexHard, setQueIndexHard] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [modaltype, setModalType] = useState();
    const [topictitle, setTopicTitle] = useState('');
    const [easyQues, setEasyQues] = useState([]);
    const [mediumQues, setMediumQues] = useState([]);
    const [hardQues, setHardQues] = useState([]);
    const [totaleasyQues, setTotalEasyQues] = useState(0);
    const [totalmediumQues, seTtotalMediumQues] = useState(0);
    const [totalhardQues, seTtotalHardQues] = useState(0);
    const [playeasyQues, setPlayEasyQues] = useState(false);
    const [playmediumQues, seTPlayMediumQues] = useState(false);
    const [playhardQues, seTPlayHardQues] = useState(false);
    const [easyAns, setEasyAns] = useState(false);
    const [mediumAns, setMediumAns] = useState(false);
    const [hardAns, setHardAns] = useState(false);
    const [righteasyAns, setRightEasyAns] = useState(0);
    const [rightmediumAns, setRightMediumAns] = useState(0);
    const [righthardAns, setRightHardAns] = useState(0);
    const [easyExp, setEasyExp] = useState(false);
    const [mediumExp, setMediumExp] = useState(false);
    const [hardExp, setHardExp] = useState(false);
    const [expIndex, setExpIndex] = useState(0);
    const [expIndexMedium, setExpIndexMedium] = useState(0);
    const [expIndexHard, setExpIndexHard] = useState(0);
    const [easyAnswer, setEasyAnswer] = useState([]);
    const [mediumAnswer, setMediumAnswer] = useState([]);
    const [hardAnswer, setHardAnswer] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [optionnew, setOptionnew] = useState([]);
    const [email, setEmail] = useState();

    const [isActive, setIsActive] = useState(null);
    const [isActiveMedium, setIsActiveMedium] = useState(null);
    const [isActiveHard, setIsActiveHard] = useState(null);

    const [isError, setIsError] = useState(false);
    const [isErrorMedium, setIsErrorMedium] = useState(false);
    const [isErrorHard, setIsErrorHard] = useState(false);

    const [valuesArray, setValuesArray] = useState([]);
    const [valuesArrayMedium, setValuesArrayMedium] = useState([]);
    const [valuesArrayHard, setValuesArrayHard] = useState([]);

    const [showidExp, setShowIdExp] = useState(0);
    const [showidExpmedium, setShowIdExpMedium] = useState(0);
    const [showidExpHard, setShowIdExpHard] = useState(0);

    const userId = localStorage.getItem('userId');

    const getTopics = async () => {

        await fetch(Constant.getTopicContent + '/' + cid, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setIsLoading(false)
                    setTopicTitle(data.data.title)
                    if (data.data.video_url !== null) {
                        setVideoUrl(data.data.video_url)
                        isLoadingVideo(false)
                    }

                    setHtmlContent(data.data.content)
                } else {
                    console.log('Topic not found')
                }
            })
        getTopicList();
        getProfile()

    }

    const getTopicList = async () => {

        await fetch(Constant.getTopics + '/' + id, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    isLoadingTopic(false)
                    getSubLevel(data.data[0].subject_id)
                    setTopicList(data.data)
                    setsubjectName(data.data[0].subject)
                    setlevelName(data.data[0].level)
                } else {
                    console.log('Topic not found')
                }
            })

    }

    const getSubLevel = async (value) => {

        await fetch(Constant.getSubjectLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    isLoadingLevel(false)
                    setSubLevel(data.data)
                } else {
                    console.log('data not found')
                }
            })
    }


    const getProfile = async () => {

        const userId = localStorage.getItem('userId');

        await fetch(Constant.getUserProfile + '/' + userId, {
            method: "GET"
        })

            .then(Response => Response.json())
            .then(data => {
                console.log(data.data)
                if (data.success === true) {
                    setEmail(data.data.email)
                } else {
                    console.log('Not Found')
                }
            }
            )
    }


    const onsubmitQuiz = async (value) => {
        setIsLoading(true)

        const formData = new FormData();
        if (value === 'easy') {
            formData.append("score", righteasyAns);
        }
        if (value === 'medium') {
            formData.append("score", rightmediumAns);
        }
        if (value === 'hard') {
            formData.append("score", righthardAns);
        }

        formData.append("email", email);


        await fetch(Constant.quizScore, {
            method: 'POST',
            body: formData
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setIsLoading(false)
                } else {
                    console.log('Quiz Not Submited')
                }
            })
    }

    useEffect(() => {
        getTopics();
        getQuizData();
    }, [])


    const startQuiz = (value) => {
        if (value === 'easy') {
            setPlayEasyQues(true)
        }
        if (value === 'medium') {
            seTPlayMediumQues(true)
        }
        if (value === 'hard') {
            seTPlayHardQues(true)
        }
    }

    const storeAnswer = (useranswer, level) => {
        const value = useranswer.replace(/[\s.]/g, '').toLowerCase();

        if (level === 'easy') {
            let count = righteasyAns;
            if (value) {
                setEasyAnswer(prevAnswer => [...prevAnswer, value]);
                setInputValue('');
                setSelectedOption(value);
            }
            const answerEasy = easyQues[currentQuestionIndex].answer.replace(/[\s.]/g, '').toLowerCase();
            if (value === answerEasy) {
                count++;
                setIsActive(true)
                setValuesArray(prevArray => [...prevArray, 'right']);

            } else {
                setIsActive(false)
                setValuesArray(prevArray => [...prevArray, 'wrong']);
            }
            setRightEasyAns(count)
            setIsError(false)
        }
        if (level === 'medium') {
            let count = rightmediumAns;
            if (value) {
                setMediumAnswer((prevAnswer) => [...prevAnswer, value]);
                setInputValue('');
                setSelectedOption(value);
            }
            const answerMedium = mediumQues[queIndexMedium].answer.replace(/[\s.]/g, '').toLowerCase();
            if (value === answerMedium) {
                count++;
                setIsActiveMedium(true)
                setValuesArrayMedium(prevArray => [...prevArray, 'right']);

            } else {
                setIsActiveMedium(false)
                setValuesArrayMedium(prevArray => [...prevArray, 'wrong']);
            }
            setRightMediumAns(count)
            setIsErrorMedium(false)
        }
        if (level === 'hard') {
            let count = righthardAns
            if (value) {
                setHardAnswer((prevAnswer) => [...prevAnswer, value]);
                setInputValue('');
                setSelectedOption(value);
            }
            const answerHard = mediumQues[queIndexMedium].answer.replace(/[\s.]/g, '').toLowerCase();
            if (value === answerHard) {
                count++;
                setIsActiveHard(true)
                setValuesArrayHard(prevArray => [...prevArray, 'right']);
            } else {
                setIsActiveHard(false)
                setValuesArrayHard(prevArray => [...prevArray, 'wrong']);
            }
            setRightHardAns(count)
            setIsErrorHard(false)
        }


    }

    const handleClick = (value, currentQuestionIndex) => {
        console.log('hhhh', easyAnswer, currentQuestionIndex)
        if (value === 'easy') {
            if (easyAnswer) {
                setSelectedOption(null)
            } else {
                setEasyAnswer([]);
            }
            setCurrentQuestionIndex(prevIndex => prevIndex + 1);
            setIsActive(null)
        }
        if (value === 'medium') {
            if (mediumAnswer) {
                setSelectedOption(null)
            } else {
                setMediumAnswer([]);
            }
            setQueIndexMedium(prevIndex => prevIndex + 1);
            setIsActiveMedium(null)
        }
        if (value === 'hard') {
            if (hardAnswer) {
                setSelectedOption(null)
            } else {
                setHardAnswer([]);
            }
            setQueIndexHard(prevIndex => prevIndex + 1);
            setIsActiveHard(null)
        }

    };

    const showAnswer = (value) => {
        if (value === 'easy') {
            let count = 0;
            for (let index = 0; index < easyQues.length; index++) {
                if (easyAnswer[index] === (easyQues[index].answer)) {
                    count++;
                }
            }
            setPlayEasyQues(false)
            setEasyAns(true)
            setRightEasyAns(count)
            onsubmitQuiz('easy')
            setIsActive(null)
            setIsError(false)
        }
        if (value === 'medium') {
            let countmedium = 0;
            for (let index = 0; index < mediumQues.length; index++) {
                if (mediumAnswer[index] === (mediumQues[index].answer)) {
                    countmedium++;
                }
            }

            seTPlayMediumQues(false)
            setMediumAns(true)
            setRightMediumAns(countmedium)
            onsubmitQuiz('medium')
            setIsActiveMedium(null)
            setIsErrorMedium(false)
        }
        if (value === 'hard') {
            let counthard = 0;
            for (let index = 0; index < hardQues.length; index++) {
                if (hardAnswer[index] === (hardQues[index].answer)) {
                    counthard++;
                }
            }

            seTPlayHardQues(false)
            setHardAns(true)
            setRightHardAns(counthard)
            onsubmitQuiz('hard')
            setIsActiveHard(null)
            setIsErrorHard(false)
        }
    }

    const handleClickPrevious = (value, currentQuestionIndex) => {
        console.log(isActive)
        if (value === 'easy') {
            setOptionnew(easyAnswer)
            setSelectedOption(easyAnswer[currentQuestionIndex - 1])
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
            setIsActive(false)
        }
        if (value === 'medium') {
            setOptionnew(mediumAnswer)
            setSelectedOption(mediumAnswer[currentQuestionIndex - 1])
            setQueIndexMedium(prevIndex => prevIndex - 1);
            setIsActiveMedium(false)
        }
        if (value === 'hard') {
            setOptionnew(hardAnswer)
            setSelectedOption(hardAnswer[currentQuestionIndex - 1])
            setQueIndexHard(prevIndex => prevIndex - 1);
            setIsActiveHard(false)
        }

    };
    const nextExp = (value) => {
        if (value === 'easy') {
            setExpIndex(prevIndex => prevIndex + 1);
        }
        if (value === 'medium') {
            setExpIndexMedium(prevIndex => prevIndex + 1);
        }
        if (value === 'hard') {
            setExpIndexHard(prevIndex => prevIndex + 1);
        }
    }

    const prevExp = (value) => {
        if (value === 'easy') {
            setExpIndex(prevIndex => prevIndex - 1);

        }
        if (value === 'medium') {
            setExpIndexMedium(prevIndex => prevIndex - 1);
        }
        if (value === 'hard') {
            setExpIndexHard(prevIndex => prevIndex - 1);
        }
    }

    const loadPage = () => {
        window.location.href = Constant.BaseUrl + 'subject-details/' + id
    }

    const loadSubject = (value) => {
        window.location.href = Constant.BaseUrl + value.toLowerCase()
    }

    const loadlevel = (value) => {
        window.location.href = Constant.BaseUrl + 'subject-details/' + value
    }

    const showModal = (value) => {
        setModalType(value)
        if (modal === true) {
            setShowModal(false)
        } else {
            setShowModal(true)
        }
    }

    const handleClose = () => {
        setShowModal(false)
        setSelectedOption(null)
    }

    const handleCloseQuiz = (value) => {
        if (value === 'easy') {
            setPlayEasyQues(false)
            setEasyExp(false)
            setShowModal(false)
            setEasyAns(false)
            setCurrentQuestionIndex(0);
            setExpIndex(0)
            setSelectedOption(null)
            setRightEasyAns(0)
            setEasyAnswer([])
            setValuesArray([])
            setIsActive(null)
            setShowIdExp(0)
        }
        if (value === 'medium') {
            seTPlayMediumQues(false)
            setMediumExp(false)
            setShowModal(false)
            setMediumAns(false)
            setQueIndexMedium(0)
            setExpIndexMedium(0)
            setSelectedOption(null)
            setRightMediumAns(0)
            setMediumAnswer([])
            setIsActiveMedium(null)
            setValuesArrayMedium([])
        }
        if (value === 'hard') {
            seTPlayHardQues(false)
            setHardAns(false)
            setShowModal(false)
            setHardExp(false)
            setQueIndexHard(0)
            setExpIndexHard(0)
            setSelectedOption(null)
            setHardAnswer([])
            setRightHardAns(0)
            setIsActiveHard(null)
            setValuesArrayHard([])
        }
    }

    const showExp = (value) => {
        if (value === 'easy') {
            setEasyExp(true)
        } if (value === 'medium') {
            setMediumExp(true)
        } if (value === 'hard') {
            setHardExp(true)
        }
    }

    const getQuizData = async () => {

        await fetch(Constant.getQuiz + '/' + cid, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                // console.log(data.data)
                if (data.success === true) {
                    var QuizQuestion = data.data;
                    setQuiz(QuizQuestion)

                    const questionEasy = 'easy';
                    const easyQuestion = QuizQuestion.filter((item) => item.question_level === questionEasy);
                    setEasyQues(easyQuestion);
                    const easyQuestionCount = easyQuestion.length;
                    setTotalEasyQues(easyQuestionCount);

                    const questionMedium = 'medium';
                    const mediumQuestion = QuizQuestion.filter((item) => item.question_level === questionMedium);
                    setMediumQues(mediumQuestion)
                    const mediumQuestionCount = mediumQuestion.length;
                    seTtotalMediumQues(mediumQuestionCount)

                    const questionHard = 'hard';
                    const hardQuestion = QuizQuestion.filter((item) => item.question_level === questionHard);
                    setHardQues(hardQuestion)
                    var hardquestion = hardQuestion.length;
                    seTtotalHardQues(hardquestion)

                } else {
                    console.log('Not Found')
                }
            })

    }

    const showError = (value) => {
        console.log(easyAnswer, isActive)
        if (value === 'easy') {
            setIsError(true)
        }
        if (value === 'medium') {
            setIsErrorMedium(true)
        }
        if (value === 'hard') {
            setIsErrorHard(true)
        }
    }

    const HandleClick = (value) => {
        if (value === 'easy') {
            Swal.fire({
                text: 'Are you sure you wants to quit the quiz?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                icon: 'warning',
                iconColor: '#490B32',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCloseQuiz('easy')
                };
            });
        }
        if (value === 'medium') {
            Swal.fire({
                text: 'Are you sure you wants to quit the quiz?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                icon: 'warning',
                iconColor: '#490B32',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCloseQuiz('medium')
                };
            });

        }
        if (value === 'hard') {
            Swal.fire({
                text: 'Are you sure you wants to quit the quiz?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                icon: 'warning',
                iconColor: '#490B32',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    handleCloseQuiz('hard')
                };
            });
        }
    }

    const retryQuiz = (value) => {
        if (value === 'easy') {
            setPlayEasyQues(true)
            setRightEasyAns(0)
            setIsError(null)
            setSelectedOption(null)
            setEasyAns(false)
            setIsActive(null)
            setCurrentQuestionIndex(0)
            setEasyAnswer([])
            setValuesArray([])
            setShowIdExp(0)
        }
        if (value === 'medium') {
            seTPlayMediumQues(true)
            setRightMediumAns(0)
            setIsErrorMedium(null)
            setSelectedOption(null)
            setMediumAns(false)
            setIsActiveMedium(null)
            setQueIndexMedium(0)
            setMediumAnswer([])
            setValuesArrayMedium([])
        }
        if (value === 'hard') {
            seTPlayHardQues(true)
            setRightHardAns(0)
            setIsErrorHard(null)
            setSelectedOption(null)
            setHardAns(false)
            setIsActiveHard(null)
            setQueIndexHard(null)
            setQueIndexHard(0)
            setHardAnswer([])
            setValuesArrayHard([])
        }

    }

    const reviewExp = (value, i) => {
        if (value === 'easy') {
            if (modalRef.current) {
                modalRef.current.scrollTop = modalRef.current.scrollHeight;
            }
            // setEasyExp(true)
            setShowIdExp(i)
        }
        if (value === 'medium') {
            // setMediumExp(true)
            setShowIdExpMedium(i)
        }
        if (value === 'hard') {
            // setHardExp(true)
            setShowIdExpHard(i)
        }
    }

    return (
        <>
            <section id="chapterrr" className="sptb">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12">
                            <div className="chapter-headings">
                                {
                                    loadingTopic === true ? <div className="text-center mt-4 mb-4"> <Skeleton height={50} width={200} count={1} /></div>
                                        :
                                        <>
                                            {
                                                subjectName !== '' ? <div className="text-center mt-4 mb-4"><div style={{ display: 'flex', justifyContent: 'center' }}><h1 style={{ cursor: 'pointer' }} onClick={() => loadSubject(subjectName)} >{subjectName} &nbsp;&nbsp;&nbsp;</h1><h1><FaChevronRight style={{ marginLeft: '-30px' }} /> {levelName}</h1></div></div > : ''
                                            }
                                        </>
                                }
                                {
                                    isLoading === true ? <div className="text-center mt-4 mb-4"> <Skeleton height={40} width={400} count={1} /></div> : <h3 style={{ fontSize: 30 }} className="text-center mb-4">{topictitle}</h3>
                                }
                                {
                                    loadingvideo === true ?
                                        <></>
                                        : <div className="card border" >
                                            <div className="card-body paddingtop">
                                                <section style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    <div >
                                                        <iframe src={videourl} width={730} height={480} title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                }
                                {
                                    isLoading === true ? <div className="row">
                                        <div className="col-md-12">
                                            <section className="sptb bg-white" style={{ padding: '10px 5px' }}>
                                                <Skeleton className="grayColor" height={15} width={700} count={20} />
                                            </section>
                                        </div>
                                    </div> : <div className="row">
                                        <div className="col-md-12">
                                            <section className="sptb bg-white" style={{ padding: '10px 5px' }}>
                                                <div dangerouslySetInnerHTML={{ __html: htmlContent }} className="container colorBlack"></div>
                                            </section>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="card">
                                <div className="card-body" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div className="lending-btn ">
                                        <NavLink to="/book-a-tutor" >Book Free Lesson</NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">More Chapters</h3>
                                </div>
                                {
                                    loadingTopic === true ? <div className="card-body">
                                        <div id="container">
                                            <div className="filter-product-checkboxs">
                                                <label className="custom-control mb-3">
                                                    <span >
                                                        <a style={{ cursor: 'pointer' }} className="text-dark">
                                                            <Skeleton className="colorChange" height={20} width={300} count={5} />
                                                        </a>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div> : <div className="card-body">
                                        <div className="" id="container">
                                            <div className="filter-product-checkboxs">
                                                {
                                                    topicList.map((item, i) => (
                                                        <label key={i} className="custom-control mb-3">
                                                            <span className="" >
                                                                <a style={{ cursor: 'pointer' }} onClick={() => loadPage()} className="text-dark">{item.name}
                                                                    <span className="label label-secondary " style={{ float: 'right' }}>{item.topicdetails.length}</span>
                                                                </a>
                                                            </span>
                                                        </label>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">More Levels</h3>
                                </div>
                                {
                                    loadingLevel === true ? <div className="card-body">
                                        <div id="container">
                                            <div className="filter-product-checkboxs">
                                                <label className="custom-control mb-3">
                                                    <span >
                                                        <a style={{ cursor: 'pointer' }} className="text-dark">
                                                            <Skeleton className="colorChange" height={20} width={300} count={5} />
                                                        </a>
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div> : <div className="card-body">
                                        <div className="" id="container">
                                            <div className="filter-product-checkboxs">
                                                {
                                                    subLevel.map((item, i) => (
                                                        <div key={i}>
                                                            {
                                                                item.sub_levels.length !== 0 ?
                                                                    <>
                                                                        {
                                                                            item.sub_levels.map((value, i) => (
                                                                                <label key={i} className="custom-control mb-3">
                                                                                    <span >
                                                                                        <a style={{ cursor: 'pointer' }} onClick={() => loadlevel(item.sub_levels[i].id)} className="text-dark">
                                                                                            <span className="label label-secondary float-right">{value.name}</span>
                                                                                        </a>
                                                                                    </span>
                                                                                </label>
                                                                            ))
                                                                        }
                                                                    </>
                                                                    :
                                                                    <label className="custom-control mb-3">
                                                                        <span>
                                                                            <a style={{ cursor: 'pointer' }} onClick={() => loadlevel(item.sub_levels[i].id)} className="text-dark">
                                                                                <span className="label label-secondary float-right">{item.name}</span>
                                                                            </a>
                                                                        </span>
                                                                    </label>
                                                            }
                                                        </div>

                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <>
                        {/* {
                            userId === null ? <> {
                                quiz.length === 0 ? '' : <>
                                    <div className="row mt-4 text-center">
                                        <div className="col-xl-8 col-lg-8 col-md-12">
                                            <h2>Start Quiz</h2>
                                        </div>
                                    </div>

                                    <div className="row mt-4 text-center">
                                        <div className="col-xl-8 col-lg-8 col-md-12">
                                            <a href="/login"><button type="button" className="btn btn-info btn-lg model start_quiz" datatype="easy">Easy</button></a>
                                            <a href="/login"><button type="button" className="btn btn-info btn-lg model start_quiz" datatype="medium">Medium</button></a>
                                            <a href="/login"><button type="button" className="btn btn-info btn-lg model start_quiz" datatype="hard">Hard</button></a>
                                        </div>
                                    </div>
                                </>
                            }
                            </>
                                :
                                <> */}
                                    {
                                        quiz.length === 0 ? '' : <>
                                            <div className="row mt-4 text-center">
                                                <div className="col-xl-8 col-lg-8 col-md-12">
                                                    <h2>Start Quiz</h2>
                                                </div>
                                            </div>

                                            <div className="row mt-4 text-center">
                                                <div className="col-xl-8 col-lg-8 col-md-12">
                                                    <button type="button" className="btn btn-info btn-lg model start_quiz" datatype="easy" onClick={() => showModal('easy')}>Easy</button>
                                                    <button type="button" className="btn btn-info btn-lg model start_quiz" datatype="medium" onClick={() => showModal('medium')}>Medium</button>
                                                    <button type="button" className="btn btn-info btn-lg model start_quiz" datatype="hard" onClick={() => showModal('hard')}>Hard</button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                {/* </>
                        } */}
                    </>
                </div>
                {
                    modaltype === 'easy' ?
                        <Modal show={modal} onHide={handleClose} className="modal-bg">
                            <Modal.Header>
                                <div className="container">
                                    <div className="row" >
                                        <div className="col-sm-12 col-md-10">
                                            <h3 style={{ fontSize: 25, color: '#fff' }} className="text-center mb-2">{topictitle} - {levelName}</h3>
                                        </div>
                                        <div className="col-sm-12 col-md-2">
                                            <button type="button" style={{ float: 'right' }} className="cross-sign" onClick={() => HandleClick('easy')}> <ImCross /></button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Header>
                            {
                                easyExp === true ?
                                    <>
                                        <Modal.Body>
                                            <div style={{ height: 'auto' }}>
                                                <div className="quizheading">
                                                    {
                                                        easyQues[expIndex].question !== null ?
                                                            <div dangerouslySetInnerHTML={{ __html: easyQues[expIndex].question }} className="container quiztext colorBlack"></div>
                                                            : ''
                                                    }
                                                </div>
                                                <h4 className="mt-3">Explanation:</h4>
                                                <p>
                                                    {
                                                        easyQues[expIndex].explaination !== null ? <div>
                                                            <div dangerouslySetInnerHTML={{ __html: easyQues[expIndex].explaination }} className="container colorBlack">
                                                            </div></div> : ''
                                                    }
                                                </p>
                                                {
                                                    easyAnswer.length !== 0 ? <div>
                                                        <h4>Your answer : {easyAnswer[expIndex]}</h4>
                                                    </div> : ''
                                                }

                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer style={{ paddingTop: 40 }}>
                                            {
                                                easyExp === true ? <div >
                                                    {expIndex < easyQues.length - 1 ? (
                                                        <div className="text-center">
                                                            <div className="row col-xl-12 col-lg-12 col-md-12" style={{ marginTop: 30 }}>
                                                                {
                                                                    expIndex === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "><button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => prevExp('easy')}>Previous Question</button></div>
                                                                }
                                                                <div className="col-md-6 "><button type="button" className="btn btn-info btn-lg model" style={{ float: 'right' }} onClick={() => nextExp('easy')}>Next Question</button></div>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="quizheading nextbutton" >
                                                            <button className="btn btn-info btn-lg model" onClick={() => handleCloseQuiz('easy')} >Close</button>
                                                        </div>
                                                    )}
                                                </div> : ' '
                                            }

                                        </Modal.Footer>
                                    </>
                                    : <>
                                        {
                                            easyQues.length === 0 ? <Modal.Body></Modal.Body>
                                                :
                                                <>
                                                    <Modal.Body>
                                                        {
                                                            playeasyQues === true ? <>
                                                                <div style={{ height: 'auto' }}>
                                                                    {
                                                                        <h4 >Your Total Score is:{righteasyAns}/{totaleasyQues}</h4>
                                                                    }
                                                                    <div className="quizheading">
                                                                        {
                                                                            easyQues[currentQuestionIndex].question !== null ?

                                                                                <div dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].question }} className="container quiztext colorBlack"></div>

                                                                                : ''
                                                                        }
                                                                    </div>

                                                                    <div>
                                                                        {
                                                                            easyQues[currentQuestionIndex].question_type === 'mcq' ?
                                                                                <>
                                                                                    <div className="radio-with-Icon" >
                                                                                        {
                                                                                            isActive === null ?
                                                                                                <>
                                                                                                    <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                        {
                                                                                                            easyQues[currentQuestionIndex].option_1 !== null ? <div className="radioOption-Item">
                                                                                                                <div className='custom-control custom-checkbox' onClick={() => storeAnswer('option_1', 'easy')}>
                                                                                                                    <input type="radio" className='custom-control-input' name="available_for_session" id="option_1" />
                                                                                                                    <label dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].option_1 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_1' ? 'checked' : `${optionnew[currentQuestionIndex] === 'option_1' ? 'checked' : ''}`} `} ></label>
                                                                                                                </div>
                                                                                                            </div> : ''
                                                                                                        }
                                                                                                        {
                                                                                                            easyQues[currentQuestionIndex].option_3 !== null ? <div className="radioOption-Item">
                                                                                                                <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_3', 'easy')}>
                                                                                                                    <input type="radio" className="custom-control-input " name="available_for_session" id="option_3" />
                                                                                                                    <label dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].option_3 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_3' ? 'checked' : `${optionnew[currentQuestionIndex] === 'option_3' ? 'checked' : ''}`}`}></label>
                                                                                                                </div>
                                                                                                            </div> : ''
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                        {
                                                                                                            easyQues[currentQuestionIndex].option_2 !== null ? <div className="radioOption-Item">
                                                                                                                <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_2', 'easy')}>
                                                                                                                    <input type="radio" className="custom-control-input " name="available_for_session" id="option_2" />
                                                                                                                    <label dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].option_2 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_2' ? 'checked' : `${optionnew[currentQuestionIndex] === 'option_2' ? 'checked' : ''}`}`}></label>
                                                                                                                </div>
                                                                                                            </div> : ''
                                                                                                        }
                                                                                                        {
                                                                                                            easyQues[currentQuestionIndex].option_4 !== null ? <div className="radioOption-Item">
                                                                                                                <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_4', 'easy')}>
                                                                                                                    <input type="radio" className="custom-control-input " name="available_for_session" id="option_4" />
                                                                                                                    <label dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].option_4 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_4' ? 'checked' : `${optionnew[currentQuestionIndex] === 'option_4' ? 'checked' : ''}`}`}></label>
                                                                                                                </div>
                                                                                                            </div> : ''
                                                                                                        }

                                                                                                    </div>
                                                                                                </> :
                                                                                                <> <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                    {
                                                                                                        easyQues[currentQuestionIndex].option_1 !== null ? <div className="radioOption-Item">
                                                                                                            <div className='custom-control custom-checkbox' >
                                                                                                                <input type="radio" className='custom-control-input' name="available_for_session" id="option_1" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].option_1 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_1' ? 'checked' : `${optionnew[currentQuestionIndex] === 'option_1' ? 'checked' : ''}`} `} ></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                    {
                                                                                                        easyQues[currentQuestionIndex].option_3 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" >
                                                                                                                <input type="radio" className="custom-control-input " name="available_for_session" id="option_3" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].option_3 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_3' ? 'checked' : `${optionnew[currentQuestionIndex] === 'option_3' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }

                                                                                                </div>

                                                                                                    <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                        {
                                                                                                            easyQues[currentQuestionIndex].option_2 !== null ? <div className="radioOption-Item">
                                                                                                                <div className="custom-control custom-checkbox" >
                                                                                                                    <input type="radio" className="custom-control-input " name="available_for_session" id="option_2" />
                                                                                                                    <label dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].option_2 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_2' ? 'checked' : `${optionnew[currentQuestionIndex] === 'option_2' ? 'checked' : ''}`}`}></label>
                                                                                                                </div>
                                                                                                            </div> : ''
                                                                                                        }
                                                                                                        {
                                                                                                            easyQues[currentQuestionIndex].option_4 !== null ? <div className="radioOption-Item">
                                                                                                                <div className="custom-control custom-checkbox" >
                                                                                                                    <input type="radio" className="custom-control-input " name="available_for_session" id="option_4" />
                                                                                                                    <label dangerouslySetInnerHTML={{ __html: easyQues[currentQuestionIndex].option_4 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_4' ? 'checked' : `${optionnew[currentQuestionIndex] === 'option_4' ? 'checked' : ''}`}`}></label>
                                                                                                                </div>
                                                                                                            </div> : ''
                                                                                                        }

                                                                                                    </div></>
                                                                                        }

                                                                                    </div>
                                                                                </>
                                                                                : <div className="radio-with-Icon col-md-12 mt-5" >
                                                                                    <div className="form-group ">
                                                                                        <input type="text" className="form-control" placeholder="Type Your Answer" onChange={(event) => storeAnswer(event.target.value, 'easy')} />
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                                {
                                                                    easyAns === true ?
                                                                        <>
                                                                            <h4 style={{ textAlign: 'center' }}>Your Total Score is:{righteasyAns}/{totaleasyQues}</h4>
                                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                {(() => {
                                                                                    const stars = [];
                                                                                    for (let i = 1; i <= totaleasyQues; i++) {
                                                                                        if (i <= righteasyAns) {
                                                                                            stars.push(<img key={i} alt="star" src={yellowStar} style={{ height: 69, margin: 22 }} />);
                                                                                        } else {
                                                                                            stars.push(<AiOutlineStar key={i} style={{ fontSize: 80, color: '#000', margin: 22 }} />);
                                                                                        }
                                                                                    }
                                                                                    return stars;
                                                                                })()}
                                                                            </div>
                                                                            <div>
                                                                                {(() => {
                                                                                    const stars = [];
                                                                                    for (let i = 1; i <= totaleasyQues; i++) {
                                                                                        stars.push(
                                                                                            <div className="row">
                                                                                                <div className="col-md-4"></div>
                                                                                                <div className="col-md-4 mt-3 " style={{ display: 'flex', justifyContent: 'space-between' }}><text style={{ fontSize: 25 }}>Question{i}:{valuesArray[i - 1] === 'right' ? <FaCheck style={{ color: 'green', fontSize: 30, marginLeft: 10 }} /> : <ImCross style={{ color: 'red', fontSize: 30, marginLeft: 10 }} />}</text><button type="button" className="btn btn-info btn-lg model" onClick={() => reviewExp('easy', i)} >Review Explanation</button><br></br></div>
                                                                                                <div className="col-md-4"></div>
                                                                                            </div>
                                                                                        );
                                                                                    }
                                                                                    return stars;
                                                                                })()}
                                                                            </div>
                                                                            {
                                                                                showidExp !== 0 ?
                                                                                    <div className="mt-5" style={{ height: 'auto' }}>
                                                                                        <div className="quizheading">
                                                                                            {
                                                                                                easyQues[showidExp - 1].question !== null ?
                                                                                                    <div>
                                                                                                        <div dangerouslySetInnerHTML={{ __html: easyQues[showidExp - 1].question }} className="container colorBlack"></div>

                                                                                                    </div> : ''
                                                                                            }
                                                                                        </div>
                                                                                        <h4 className="mt-3">Explanation:</h4>
                                                                                        <p>
                                                                                            {
                                                                                                easyQues[showidExp - 1].explaination !== null ? <div>
                                                                                                    <div dangerouslySetInnerHTML={{ __html: easyQues[showidExp - 1].explaination }} className="container colorBlack">
                                                                                                    </div></div> : ''
                                                                                            }
                                                                                        </p>
                                                                                        {
                                                                                            easyAnswer.length !== 0 ? <div>
                                                                                                <h4>Your answer : {easyAnswer[showidExp - 1]}</h4>
                                                                                            </div> : ''
                                                                                        }

                                                                                    </div> : ''
                                                                            }
                                                                        </> : <>

                                                                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                <video className="main_video" src={quizvideo} autoPlay={true} style={{ width: 675 }} />
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>
                                                        }

                                                    </Modal.Body>

                                                    <Modal.Footer style={{ paddingTop: 40 }}>
                                                        {isActive !== null && (
                                                            <p className={isActive ? "success" : "danger"}>
                                                                {isActive ? <><FaCheckCircle className="success-icon" /><text style={{ marginLeft: 5 }}>Correct answer!</text></> : <><FaTimesCircle className="error-icon" /><text style={{ marginLeft: 5 }}>Wrong answer!</text></>}
                                                            </p>
                                                        )}
                                                        {
                                                            playeasyQues === true ? < >
                                                                {currentQuestionIndex < easyQues.length - 1 ? (
                                                                    <div className="text-center">
                                                                        {
                                                                            isError === true ? <text style={{ textAlign: 'left', color: 'red' }}>Please select at least one option or enter answer</text> : ''
                                                                        }
                                                                        {
                                                                            isActive === null ?
                                                                                <div className="row col-xl-12 col-lg-12 col-md-12" style={{ marginTop: 30 }}>
                                                                                    {
                                                                                        currentQuestionIndex === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "> <button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => handleClickPrevious('easy', currentQuestionIndex)}>Previous Question</button></div>
                                                                                    }
                                                                                    <div className="col-md-6 "> <button type="button" className="btn btn-info btn-lg model " style={{ float: 'right' }} onClick={() => showError('easy')}>Next Question</button></div>

                                                                                </div> :
                                                                                <div className="row col-xl-12 col-lg-12 col-md-12" style={{ marginTop: 30 }}>
                                                                                    {
                                                                                        currentQuestionIndex === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "> <button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => handleClickPrevious('easy', currentQuestionIndex)}>Previous Question</button></div>
                                                                                    }
                                                                                    <div className="col-md-6 "> <button type="button" className="btn btn-info btn-lg model " style={{ float: 'right' }} onClick={() => handleClick('easy', currentQuestionIndex)}>Next Question</button></div>

                                                                                </div>
                                                                        }
                                                                    </div>

                                                                ) : (
                                                                    <>
                                                                        {
                                                                            isError === true ? <text style={{ textAlign: 'left', color: 'red' }}>Please select at least one option</text> : ''
                                                                        }
                                                                        {
                                                                            isActive === null ? <div className="quizheading nextbutton" >
                                                                                <button className="btn btn-info btn-lg model" onClick={() => showError('easy')}>Submit</button>
                                                                            </div> :
                                                                                <div className="quizheading nextbutton" >
                                                                                    <button className="btn btn-info btn-lg model" onClick={() => showAnswer('easy')} >Submit</button>
                                                                                </div>
                                                                        }
                                                                    </>
                                                                )}
                                                            </> : <>
                                                                {
                                                                    easyAns !== true ? <>
                                                                        <div className="mt-1" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                            <button type="button" className="btn btn-info btn-lg model" onClick={() => startQuiz('easy')} >Play Now</button>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            easyAns === true ?
                                                                <div className="row">
                                                                    <div className="col-md-4"></div>
                                                                    <div className="col-md-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <button type="button" className="btn btn-info btn-lg model " onClick={() => showExp('easy')} style={{ marginLeft: 0 }}>Review All</button>
                                                                        <button type="button" className="btn btn-info btn-lg model" onClick={() => retryQuiz('easy')} style={{ marginLeft: 0 }} >Retry Quiz</button>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                    </div>
                                                                </div>
                                                                : ''
                                                        }

                                                    </Modal.Footer>
                                                </>
                                        }

                                    </>
                            }

                        </Modal> : ''
                }
                {
                    modaltype === 'medium' ?
                        <Modal show={modal} onHide={handleClose} className="modal-bg" >
                            <Modal.Header>
                                <div className="container">
                                    <div className="row" >
                                        <div className="col-sm-12 col-md-11">
                                            <h3 style={{ fontSize: 25, color: '#fff' }} className="text-center mb-2">{topictitle} - {levelName}</h3>
                                        </div>
                                        <div className="col-sm-12 col-md-1">
                                            <button type="button" style={{ float: 'right' }} className="cross-sign" onClick={() => HandleClick('medium')}> <ImCross /></button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Header>

                            {
                                mediumExp === true ?
                                    <>
                                        <Modal.Body>
                                            <div style={{ height: 'auto' }}>
                                                <div className="quizheading">
                                                    {
                                                        (mediumQues[expIndexMedium].question !== null) ?

                                                            <div dangerouslySetInnerHTML={{ __html: mediumQues[expIndexMedium].question }} className="container quiztext colorBlack"></div>

                                                            : ''
                                                    }
                                                </div>
                                                <h4 className="mt-3">Explanation:</h4>
                                                <p>
                                                    {
                                                        mediumQues[expIndexMedium].explaination !== null ? <div> <div dangerouslySetInnerHTML={{ __html: mediumQues[expIndexMedium].explaination }} className="container colorBlack">

                                                        </div></div> : ''
                                                    }
                                                </p>
                                                {
                                                    mediumAnswer.length !== 0 ? <div>
                                                        <h4>Your answer : {mediumAnswer[expIndexMedium]}</h4>
                                                    </div> : ''
                                                }

                                            </div>


                                        </Modal.Body>
                                        <Modal.Footer style={{ paddingTop: 40 }}>
                                            {
                                                mediumExp === true ? <div>
                                                    {expIndexMedium < mediumQues.length - 1 ? (
                                                        <div className="text-center">
                                                            <div className="row col-xl-12 col-lg-12 col-md-12" style={{ marginTop: 30 }}>
                                                                {
                                                                    expIndexMedium === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "> <button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => prevExp('medium')}>Previous Question</button></div>
                                                                }
                                                                <div className="col-md-6 "> <button type="button" className="btn btn-info btn-lg model " style={{ float: 'right' }} onClick={() => nextExp('medium')}>Next Question</button></div>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="quizheading nextbutton" >
                                                            <button className="btn btn-info btn-lg model" onClick={() => handleCloseQuiz('medium')} >Close</button>
                                                        </div>
                                                    )}
                                                </div> : ' '
                                            }

                                        </Modal.Footer>
                                    </>
                                    : <>
                                        {
                                            mediumQues.length === 0 ? <Modal.Body></Modal.Body> :
                                                <>
                                                    <Modal.Body>
                                                        {
                                                            playmediumQues === true ? <>
                                                                <div style={{ height: 'auto' }}>
                                                                    {
                                                                        <h4>Your Total Score is:{rightmediumAns}/{totalmediumQues}</h4>
                                                                    }
                                                                    <div className="quizheading">
                                                                        {
                                                                            mediumQues[queIndexMedium].question !== null ?

                                                                                <div dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].question }} className="container quiztext colorBlack"></div>

                                                                                : ''
                                                                        }
                                                                    </div>

                                                                    <div>
                                                                        {
                                                                            mediumQues[queIndexMedium].question_type === 'mcq' ?
                                                                                <>
                                                                                    <div className="radio-with-Icon" >
                                                                                        {
                                                                                            isActiveMedium === null ? <>  <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                {
                                                                                                    mediumQues[queIndexMedium].option_1 !== null ? <div className="radioOption-Item">
                                                                                                        <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_1', 'medium')}>
                                                                                                            <input type="radio" className="custom-control-input " name="available_for_session" id="option_1" />
                                                                                                            <label dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].option_1 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_1' ? 'checked' : `${optionnew[queIndexMedium] === 'option_1' ? 'checked' : ''}`}`}></label>
                                                                                                        </div>
                                                                                                    </div> : ''
                                                                                                }
                                                                                                {
                                                                                                    mediumQues[queIndexMedium].option_3 !== null ? <div className="radioOption-Item">
                                                                                                        <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_3', 'medium')}>
                                                                                                            <input type="radio" className="custom-control-input " name="available_for_session" id="option_3" />
                                                                                                            <label dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].option_3 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_3' ? 'checked' : `${optionnew[queIndexMedium] === 'option_3' ? 'checked' : ''}`}`}></label>
                                                                                                        </div>
                                                                                                    </div> : ''
                                                                                                }


                                                                                            </div>

                                                                                                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                    {
                                                                                                        mediumQues[queIndexMedium].option_2 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_2', 'medium')}>
                                                                                                                <input type="radio" className="custom-control-input " name="available_for_session" id="option_2" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].option_2 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_2' ? 'checked' : `${optionnew[queIndexMedium] === 'option_2' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                    {
                                                                                                        mediumQues[queIndexMedium].option_4 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_4', 'medium')}>
                                                                                                                <input type="radio" className="custom-control-input " name="available_for_session" id="option_4" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].option_4 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_4' ? 'checked' : `${optionnew[queIndexMedium] === 'option_4' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                </div></> : <>  <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                    {
                                                                                                        mediumQues[queIndexMedium].option_1 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" >
                                                                                                                <input type="radio" className="custom-control-input " name="available_for_session" id="option_1" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].option_1 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_1' ? 'checked' : `${optionnew[queIndexMedium] === 'option_1' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                    {
                                                                                                        mediumQues[queIndexMedium].option_3 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" >
                                                                                                                <input type="radio" className="custom-control-input " name="available_for_session" id="option_3" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].option_3 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_3' ? 'checked' : `${optionnew[queIndexMedium] === 'option_3' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }


                                                                                                </div>

                                                                                                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                    {
                                                                                                        mediumQues[queIndexMedium].option_2 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox">
                                                                                                                <input type="radio" className="custom-control-input " name="available_for_session" id="option_2" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].option_2 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_2' ? 'checked' : `${optionnew[queIndexMedium] === 'option_2' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                    {
                                                                                                        mediumQues[queIndexMedium].option_4 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" >
                                                                                                                <input type="radio" className="custom-control-input " name="available_for_session" id="option_4" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: mediumQues[queIndexMedium].option_4 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_4' ? 'checked' : `${optionnew[queIndexMedium] === 'option_4' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                </div></>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                : <div className="radio-with-Icon col-md-12 mt-5" >
                                                                                    <div className="form-group ">
                                                                                        <input type="text" className="form-control" placeholder="Type Your Answer" onChange={(event) => storeAnswer(event.target.value, 'medium')} />
                                                                                    </div>
                                                                                </div>

                                                                        }
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                                {
                                                                    mediumAns === true ? <><h4 style={{ textAlign: 'center' }}>Your Total Score is:{rightmediumAns}/{totalmediumQues}</h4>
                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            {(() => {
                                                                                const stars = [];
                                                                                for (let i = 1; i <= totalmediumQues; i++) {
                                                                                    if (i <= rightmediumAns) {
                                                                                        stars.push(<img key={i} alt="star" src={yellowStar} style={{ height: 69, marginLeft: 5 }} />);
                                                                                    } else {
                                                                                        stars.push(<AiOutlineStar key={i} style={{ fontSize: 80, color: '#000' }} />);
                                                                                    }
                                                                                }
                                                                                return stars;
                                                                            })()}
                                                                        </div>
                                                                        <div>
                                                                            {(() => {
                                                                                const stars = [];
                                                                                for (let i = 1; i <= totalmediumQues; i++) {
                                                                                    stars.push(
                                                                                        <div className="row">
                                                                                            <div className="col-md-4"></div>
                                                                                            <div className="col-md-4 mt-3 " style={{ display: 'flex', justifyContent: 'space-between' }}><text style={{ fontSize: 25 }}>Question{i}:{valuesArrayMedium[i - 1] === 'right' ? <FaCheck style={{ color: 'green', fontSize: 30, marginLeft: 10 }} /> : <ImCross style={{ color: 'red', fontSize: 30, marginLeft: 10 }} />}</text><button type="button" className="btn btn-info btn-lg model" onClick={() => reviewExp('medium', i)} >Review Explanation</button><br></br></div>
                                                                                            <div className="col-md-4"></div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                return stars;
                                                                            })()}
                                                                        </div>
                                                                        {
                                                                            showidExpmedium !== 0 ?
                                                                                <div className="mt-5" style={{ height: 'auto' }}>
                                                                                    <div className="quizheading">
                                                                                        {
                                                                                            (mediumQues[showidExpmedium - 1].question !== null) ?
                                                                                                <div>
                                                                                                    <div dangerouslySetInnerHTML={{ __html: mediumQues[showidExpmedium - 1].question }} className="container colorBlack"></div>

                                                                                                </div> : ''
                                                                                        }
                                                                                    </div>
                                                                                    <h4 className="mt-3">Explanation:</h4>
                                                                                    <p>
                                                                                        {
                                                                                            mediumQues[showidExpmedium - 1].explaination !== null ? <div> <div dangerouslySetInnerHTML={{ __html: mediumQues[showidExpmedium - 1].explaination }} className="container colorBlack">

                                                                                            </div></div> : ''
                                                                                        }
                                                                                    </p>
                                                                                    {
                                                                                        mediumAnswer.length !== 0 ? <div>
                                                                                            <h4>Your answer : {mediumAnswer[showidExpmedium - 1]}</h4>
                                                                                        </div> : ''
                                                                                    }

                                                                                </div> : ''}
                                                                    </> :
                                                                        <>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                <video className="main_video" src={quizvideo} autoPlay={true} style={{ width: 675 }} />
                                                                            </div>
                                                                        </>
                                                                }
                                                            </>
                                                        }

                                                    </Modal.Body>
                                                    <Modal.Footer style={{ paddingTop: 40 }}>
                                                        {isActiveMedium !== null && (
                                                            <p className={isActiveMedium ? "success" : "danger"}>
                                                                {isActiveMedium ? <><FaCheckCircle className="success-icon" /><text style={{ marginLeft: 5 }}>Correct answer!</text></> : <><FaTimesCircle className="error-icon" /><text style={{ marginLeft: 5 }}>Wrong answer!</text></>}
                                                            </p>
                                                        )}
                                                        {
                                                            playmediumQues === true ? < >
                                                                {queIndexMedium < mediumQues.length - 1 ? (
                                                                    <div className="text-center">
                                                                        {
                                                                            isErrorMedium === true ? <text style={{ textAlign: 'left', color: 'red' }}>Please select at least one option</text> : ''
                                                                        }
                                                                        {
                                                                            isActiveMedium === null ? <div className="row col-xl-12 col-lg-12 col-md-12 " style={{ marginTop: 30 }}>
                                                                                {
                                                                                    queIndexMedium === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "> <button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => handleClickPrevious('medium')}>Previous Question</button></div>
                                                                                }
                                                                                <div className="col-md-6 "> <button type="button" className="btn btn-info btn-lg model " style={{ float: 'right' }} onClick={() => showError('medium')}>Next Question</button></div>
                                                                            </div> :
                                                                                <div className="row col-xl-12 col-lg-12 col-md-12 " style={{ marginTop: 30 }}>
                                                                                    {
                                                                                        queIndexMedium === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "> <button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => handleClickPrevious('medium')}>Previous Question</button></div>
                                                                                    }
                                                                                    <div className="col-md-6 "> <button type="button" className="btn btn-info btn-lg model " style={{ float: 'right' }} onClick={() => handleClick('medium')}>Next Question</button></div>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div className="quizheading nextbutton" >
                                                                        {
                                                                            isErrorMedium === true ? <text style={{ textAlign: 'left', color: 'red' }}>Please select at least one option</text> : ''
                                                                        }
                                                                        {
                                                                            isActiveMedium === null ? <button className="btn btn-info btn-lg model" onClick={() => showError('medium')}>Submit</button>
                                                                                :
                                                                                <button className="btn btn-info btn-lg model" onClick={() => showAnswer('medium')} >Submit</button>
                                                                        }

                                                                    </div>
                                                                )}
                                                            </> : <>
                                                                {
                                                                    mediumAns !== true ? <>
                                                                        <div className="mt-1" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                            <button type="button" className="btn btn-info btn-lg model" onClick={() => startQuiz('medium')} >Play Now</button>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            mediumAns === true ? <div className="row">
                                                                <div className="col-md-4"></div>
                                                                <div className="col-md-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <button type="button" className="btn btn-info btn-lg model " onClick={() => showExp('medium')} style={{ marginLeft: 0 }}>Review All</button>
                                                                    <button type="button" className="btn btn-info btn-lg model" onClick={() => retryQuiz('medium')} style={{ marginLeft: 0 }} >Retry Quiz</button>
                                                                </div>
                                                                <div className="col-md-4">
                                                                </div>
                                                            </div> : ''
                                                        }


                                                    </Modal.Footer>
                                                </>



                                        }

                                    </>
                            }
                        </Modal> : ''
                }

                {
                    modaltype === 'hard' ?
                        <Modal show={modal} onHide={handleClose} className="modal-bg" >
                            <Modal.Header>
                                <div className="container">
                                    <div className="row" >
                                        <div className="col-sm-12 col-md-11">
                                            <h3 style={{ fontSize: 25, color: '#fff' }} className="text-center mb-2">{topictitle} - {levelName}</h3>
                                        </div>
                                        <div className="col-sm-12 col-md-1">
                                            <button type="button" style={{ float: 'right' }} className="cross-sign" onClick={() => HandleClick('hard')}> <ImCross /></button>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Header>
                            {
                                hardExp === true ?
                                    <>
                                        <Modal.Body>
                                            <div style={{ height: 'auto' }}>
                                                <div className="quizheading">
                                                    {
                                                        (hardQues[expIndexHard].question !== null) ?

                                                            <div dangerouslySetInnerHTML={{ __html: hardQues[expIndexHard].question }} className="container quiztext colorBlack"></div>

                                                            : ''
                                                    }
                                                </div>
                                                <h4 className="mt-3">Explanation:</h4>
                                                <p>
                                                    {
                                                        hardQues[expIndexHard].explaination !== null ? <div> <div dangerouslySetInnerHTML={{ __html: hardQues[expIndexHard].explaination }} className="container colorBlack">

                                                        </div></div> : ''
                                                    }
                                                </p>
                                                {
                                                    hardAnswer.length !== 0 ? <div>
                                                        <h4>Your answer : {hardAnswer[expIndexHard]}</h4>
                                                    </div> : ''
                                                }
                                            </div>

                                        </Modal.Body>
                                        <Modal.Footer style={{ paddingTop: 40 }}>
                                            {
                                                hardExp === true ? <div >
                                                    {expIndexHard < hardQues.length - 1 ? (
                                                        <div className="text-center">
                                                            <div className="row col-xl-12 col-lg-12 col-md-12" style={{ marginTop: 30 }}>
                                                                {
                                                                    expIndexHard === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "> <button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => prevExp('hard')}>Previous Question</button></div>
                                                                }
                                                                <div className="col-md-6 "> <button type="button" className="btn btn-info btn-lg model " style={{ float: 'right' }} onClick={() => nextExp('hard')}>Next Question</button></div>

                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="quizheading nextbutton" >
                                                            <button className="btn btn-info btn-lg model" onClick={() => handleCloseQuiz('hard')} >Close</button>
                                                        </div>
                                                    )}
                                                </div> : ' '
                                            }

                                        </Modal.Footer>
                                    </>


                                    : <>
                                        {
                                            hardQues.length === 0 ? <><Modal.Body>
                                            </Modal.Body>
                                            </> :
                                                <>
                                                    <Modal.Body>
                                                        {
                                                            playhardQues === true ? <>
                                                                <div style={{ height: 'auto' }}>
                                                                    {
                                                                        <h4>Your Total Score is:{righthardAns}/{totalhardQues}</h4>
                                                                    }
                                                                    <div className="quizheading">
                                                                        {
                                                                            (hardQues[queIndexHard].question !== null) ?

                                                                                <div dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].question }} className="container quiztext colorBlack"></div>
                                                                                : ''
                                                                        }
                                                                    </div>

                                                                    <div>
                                                                        {
                                                                            hardQues[queIndexHard].question_type === 'mcq' ?
                                                                                <>
                                                                                    <div className="radio-with-Icon" >
                                                                                        {
                                                                                            isActiveHard === null ? <>  <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                {
                                                                                                    hardQues[queIndexHard].option_1 !== null ? <div className="radioOption-Item">
                                                                                                        <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_1', 'hard')}>
                                                                                                            <input type="radio" className="custom-control-input " name="available_for_session" id="option_1" />
                                                                                                            <label dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].option_1 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_1' ? 'checked' : `${optionnew[queIndexHard] === 'option_1' ? 'checked' : ''}`}`}></label>
                                                                                                        </div>
                                                                                                    </div> : ''
                                                                                                }
                                                                                                {
                                                                                                    hardQues[queIndexHard].option_3 !== null ? <div className="radioOption-Item">
                                                                                                        <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_3', 'hard')}>
                                                                                                            <input type="radio" className="custom-control-input" name="available_for_session" id="option_3" />
                                                                                                            <label dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].option_3 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_3' ? 'checked' : `${optionnew[queIndexHard] === 'option_3' ? 'checked' : ''}`}`}></label>
                                                                                                        </div>
                                                                                                    </div> : ''
                                                                                                }

                                                                                            </div>

                                                                                                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                    {
                                                                                                        hardQues[queIndexHard].option_2 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_2', 'hard')}>
                                                                                                                <input type="radio" className="custom-control-input" name="available_for_session" id="option_2" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].option_2 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_2' ? 'checked' : `${optionnew[queIndexHard] === 'option_2' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                    {
                                                                                                        hardQues[queIndexHard].option_4 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" onClick={() => storeAnswer('option_4', 'hard')}>
                                                                                                                <input type="radio" className="custom-control-input" name="available_for_session" id="option_4" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].option_4 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_4' ? 'checked' : `${optionnew[queIndexHard] === 'option_4' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }

                                                                                                </div></> : <>  <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                    {
                                                                                                        hardQues[queIndexHard].option_1 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" >
                                                                                                                <input type="radio" className="custom-control-input " name="available_for_session" id="option_1" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].option_1 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_1' ? 'checked' : `${optionnew[queIndexHard] === 'option_1' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                    {
                                                                                                        hardQues[queIndexHard].option_3 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" >
                                                                                                                <input type="radio" className="custom-control-input" name="available_for_session" id="option_3" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].option_3 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_3' ? 'checked' : `${optionnew[queIndexHard] === 'option_3' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }

                                                                                                </div>

                                                                                                <div className="col-md-12" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                                    {
                                                                                                        hardQues[queIndexHard].option_2 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" >
                                                                                                                <input type="radio" className="custom-control-input" name="available_for_session" id="option_2" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].option_2 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_2' ? 'checked' : `${optionnew[queIndexHard] === 'option_2' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }
                                                                                                    {
                                                                                                        hardQues[queIndexHard].option_4 !== null ? <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" >
                                                                                                                <input type="radio" className="custom-control-input" name="available_for_session" id="option_4" />
                                                                                                                <label dangerouslySetInnerHTML={{ __html: hardQues[queIndexHard].option_4 }} className={`container pinkcolor center-class custom-control-label colorBlack ${selectedOption === 'option_4' ? 'checked' : `${optionnew[queIndexHard] === 'option_4' ? 'checked' : ''}`}`}></label>
                                                                                                            </div>
                                                                                                        </div> : ''
                                                                                                    }

                                                                                                </div></>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                                : <div className="radio-with-Icon col-md-12 mt-5" >
                                                                                    <div className="form-group ">
                                                                                        <input type="text" className="form-control" placeholder="Type Your Answer" onChange={(event) => storeAnswer(event.target.value, 'hard')} />
                                                                                    </div>
                                                                                </div>

                                                                        }
                                                                    </div>
                                                                </div>

                                                            </> : <>
                                                                {
                                                                    hardAns === true ? <><h4 style={{ textAlign: 'center' }}>Your Total Score is:{righthardAns}/{totalhardQues}</h4>
                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            {(() => {
                                                                                const stars = [];
                                                                                for (let i = 1; i <= totalhardQues; i++) {
                                                                                    if (i <= righthardAns) {
                                                                                        stars.push(<img key={i} alt="star" src={yellowStar} style={{ height: 69, marginLeft: 20 }} />);
                                                                                    } else {
                                                                                        stars.push(<AiOutlineStar key={i} style={{ fontSize: 80, color: '#000', marginLeft: 20 }} />);
                                                                                    }
                                                                                }
                                                                                return stars;
                                                                            })()}
                                                                        </div>
                                                                        <div>
                                                                            {(() => {
                                                                                const stars = [];
                                                                                for (let i = 1; i <= totalhardQues; i++) {
                                                                                    stars.push(
                                                                                        <div className="row">
                                                                                            <div className="col-md-4"></div>
                                                                                            <div className="col-md-4 mt-3 " style={{ display: 'flex', justifyContent: 'space-between' }}><text style={{ fontSize: 25 }}>Question{i}:{valuesArrayHard[i - 1] === 'right' ? <FaCheck style={{ color: 'green', fontSize: 30, marginLeft: 10 }} /> : <ImCross style={{ color: 'red', fontSize: 30, marginLeft: 10 }} />}</text><button type="button" className="btn btn-info btn-lg model" onClick={() => reviewExp('hard', i)} >Review Explanation</button><br></br></div>
                                                                                            <div className="col-md-4"></div>
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                                return stars;
                                                                            })()}
                                                                        </div>
                                                                        {
                                                                            showidExpHard !== 0 ?
                                                                                <div style={{ height: 'auto' }}>
                                                                                    <div className="quizheading">
                                                                                        {
                                                                                            (hardQues[showidExpHard - 1].question !== null) ?
                                                                                                <div>
                                                                                                    <div dangerouslySetInnerHTML={{ __html: hardQues[showidExpHard - 1].question }} className="container colorBlack"></div>

                                                                                                </div> : ''
                                                                                        }
                                                                                    </div>
                                                                                    <h4 className="mt-3">Explanation:</h4>
                                                                                    <p>
                                                                                        {
                                                                                            hardQues[showidExpHard - 1].explaination !== null ? <div> <div dangerouslySetInnerHTML={{ __html: hardQues[showidExpHard - 1].explaination }} className="container colorBlack">

                                                                                            </div></div> : ''
                                                                                        }
                                                                                    </p>
                                                                                    {
                                                                                        hardAnswer.length !== 0 ? <div>
                                                                                            <h4>Your answer : {hardAnswer[showidExpHard - 1]}</h4>
                                                                                        </div> : ''
                                                                                    }
                                                                                </div> :
                                                                                ''
                                                                        }
                                                                    </> :
                                                                        <>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                                <video className="main_video" src={quizvideo} autoPlay={true} style={{ width: 675 }} />
                                                                            </div>

                                                                        </>
                                                                }
                                                            </>
                                                        }

                                                    </Modal.Body>

                                                    <Modal.Footer style={{ paddingTop: 40 }}>
                                                        {isActiveHard !== null && (
                                                            <p className={isActiveHard ? "success" : "danger"}>
                                                                {isActiveHard ? <><FaCheckCircle className="success-icon" /><text style={{ marginLeft: 5 }}>Correct answer!</text></> : <><FaTimesCircle className="error-icon" /><text style={{ marginLeft: 5 }}>Wrong answer!</text></>}
                                                            </p>
                                                        )}
                                                        {
                                                            playhardQues === true ? < >
                                                                {queIndexHard < hardQues.length - 1 ? (
                                                                    <div className="text-center">
                                                                        {
                                                                            isErrorHard === true ? <text style={{ textAlign: 'left', color: 'red' }}>Please select at least one option</text> : ''
                                                                        }
                                                                        {
                                                                            isActiveHard === null ?
                                                                                <div className="row col-xl-12 col-lg-12 col-md-12" style={{ marginTop: 30 }}>
                                                                                    {
                                                                                        queIndexHard === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "> <button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => handleClickPrevious('hard')}>Previous Question</button></div>
                                                                                    }
                                                                                    <div className="col-md-6 "> <button type="button" className="btn btn-info btn-lg model " style={{ float: 'right' }} onClick={() => showError('hard')} >Next Question</button></div>
                                                                                </div> : <div className="row col-xl-12 col-lg-12 col-md-12" style={{ marginTop: 30 }}>
                                                                                    {
                                                                                        queIndexHard === 0 ? <div className="col-md-6 "></div> : <div className="col-md-6 "> <button type="button" style={{ float: 'left' }} className="btn btn-info btn-lg model" onClick={() => handleClickPrevious('hard')}>Previous Question</button></div>
                                                                                    }
                                                                                    <div className="col-md-6 "> <button type="button" className="btn btn-info btn-lg model " style={{ float: 'right' }} onClick={() => handleClick('hard')}>Next Question</button></div>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div className="quizheading nextbutton" >
                                                                        {
                                                                            isErrorHard === true ? <text style={{ textAlign: 'left', color: 'red' }}>Please select at least one option</text> : ''
                                                                        }
                                                                        {
                                                                            isActiveHard === null ? <button className="btn btn-info btn-lg model" onClick={() => showError('hard')}>Submit</button> : <button className="btn btn-info btn-lg model" onClick={() => showAnswer('hard')} >Submit</button>
                                                                        }
                                                                    </div>
                                                                )}
                                                            </> : <>
                                                                {
                                                                    hardAns !== true ? <>
                                                                        <div className="mt-1" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                            <button type="button" className="btn btn-info btn-lg model" onClick={() => startQuiz('hard')} >Play Now</button>
                                                                        </div>
                                                                    </> : ''
                                                                }
                                                            </>
                                                        }
                                                        {
                                                            hardAns === true ? <div className="row">
                                                                <div className="col-md-4"></div>
                                                                <div className="col-md-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <button type="button" className="btn btn-info btn-lg model " onClick={() => showExp('hard')} style={{ marginLeft: 0 }}>Review All</button>
                                                                    <button type="button" className="btn btn-info btn-lg model" onClick={() => retryQuiz('hard')} style={{ marginLeft: 0 }} >Retry Quiz</button>
                                                                </div>
                                                                <div className="col-md-4">
                                                                </div>
                                                            </div> : ''
                                                        }


                                                    </Modal.Footer>
                                                </>
                                        }
                                    </>
                            }


                        </Modal> : ''
                }

            </section >
        </>

    )
}

export default TopicsDetails;