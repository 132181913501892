import thanktwo from '../../assets/img/thanktwo.png'
import thankthree from '../../assets/img/thankthree.png'

function PaymentSuccess() {
    return (
        <section id="thank-you">
            <div class="container">
                <div class="thank-you-outer">
                    <div class="row">
                        <div className='col-md-2'></div>
                        <div class="col-md-8">
                            <div class="thank-img-2">
                                <span><img src={thanktwo} alt="thankyou" /></span>
                                <img src={thankthree} alt="thankyou" />
                                <p>Your Payment Successfully Completed</p>
                            </div>
                        </div>
                        <div className='col-md-2'></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PaymentSuccess


