import React, { useState } from 'react';
import {
    useStripe, useElements,
    CardNumberElement, CardExpiryElement, CardCvcElement, CardElement
} from '@stripe/react-stripe-js';

import thanktwo from '../../assets/img/thanktwo.png'
import thankthree from '../../assets/img/thankthree.png'

import * as Constant from '../Constant'
import { useParams } from 'react-router-dom';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            lineHeight: "27px",
            color: "#212529",
            fontSize: "1.1rem",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export default function CheckoutForm(props) {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        var userId = localStorage.getItem('userId');
        event.preventDefault();
        setLoading(true);

        // Send the token and amount to your server
        await fetch(Constant.BaseUrlApi + 'payment/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email, // Get the email from the form input
                amount: props.amount,
                member_ships_id: id,
                user_id: userId
            }),
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setLoading(false)
                    localStorage.setItem('planId', id)
                    window.location.href = Constant.BaseUrl + 'payment-success'
                }
            })
    }

    return (
        <React.Fragment>
            <h4 className="d-flex justify-content-between align-items-center mb-3">
                <span className="text-muted">Pay with card</span>
            </h4>
            <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="cc-name">Name on card</label>
                        <input
                            id="cc-name"
                            type="text"
                            className="form-control"
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="cc-email">Email</label>
                        <input
                            id="cc-email"
                            type="text"
                            className="form-control"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mb-3">
                        <label htmlFor="cc-number">Card Number</label>
                        <CardNumberElement
                            id="cc-number"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="expiry">Expiration Date</label>
                        <CardExpiryElement
                            id="expiry"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="cvc">CVC</label>
                        <CardCvcElement
                            id="cvc"
                            className="form-control"
                            options={CARD_ELEMENT_OPTIONS}
                        />
                    </div>
                </div>

                <hr className="mb-4" />
                <button className="btn btn-dark w-100" type="submit" disabled={loading}>
                    {loading ? <div className="spinner-border spinner-border-sm text-light" role="status"></div> : `PAY`}
                </button>
                {errorMsg && <div className="text-danger mt-2">{errorMsg}</div>}
            </form>
        </React.Fragment>
    );
}