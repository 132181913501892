import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import exploremore from '../../assets/img/subjects/exploremore.png'
import video_one from '../../assets/videos/firstvideo.mp4'
import * as Constant from '../Constant'

import Skeleton from 'react-loading-skeleton';

function TopicDetailsNew() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [topicdetails, setTopicDetails] = useState([])
    const [subtopic, setSubTopic] = useState()
    const [clickedItem, setClickedItem] = useState(null);
    const [state, setState] = useState({
        TopicList: [],
        subjectName: '',
        levelName: '',
    })

    useEffect(() => {

        const getTopics = async () => {

            await fetch(Constant.getTopics + '/' + id, {
                method: 'GET'
            })

                .then(Response => Response.json())
                .then(data => {
                    if (data.success === true) {
                        console.log(data.data)
                        setLoading(false)
                        setState({
                            ...state,
                            TopicList: data.data,
                            subjectName: data.data[0].subject,
                            levelName: data.data[0].level,
                        })
                        if (data.data.length > 0 && clickedItem === null) {
                            setClickedItem(data.data[0].id);
                            showSubTopic(data.data[0].id); // Call showSubTopic for the default tab ID
                        }
                    } else {
                        console.log('Topic not found')
                    }
                })
        }
        getTopics();

    }, [])



    const showSubject = () => {
        window.location.href = Constant.BaseUrl + state.subjectName;
    }
    const showSubTopic = async (value) => {
        setClickedItem(value)

        await fetch(Constant.getTopics + '/' + id, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setLoading(false)
                    const topicData = data.data.find(topic => topic.id === value);
                    if (topicData) {
                        setTopicDetails(topicData.topicdetails);
                        setSubTopic(topicData.name);
                    } else {
                        // Handle case where topic with the specified ID is not found
                        console.log('Topic not found');
                    }
                } else {
                    console.log('Topic not found')
                }
            })
    }

    return (
        <>
            {
                loading === true ? <div className="text-center mt-4 mb-4"> <Skeleton height={50} width={200} count={1} /></div> :
                    <>
                        {
                            state.subjectName !== '' ? <div className="text-center mt-4 mb-4"><div style={{ display: 'flex', justifyContent: 'center' }}><h1 style={{ cursor: 'pointer' }} onClick={() => showSubject()}>{state.subjectName}  -  {state.levelName}</h1></div></div > : <div className="text-center mt-4 mb-4"><h3>List Not Found</h3></div >
                        }
                    </>

            }

            <div className='row mt-2'>
                <div className='col-md-1'></div>
                <div className='col-md-10' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {
                        state.TopicList.map((item, i) => (
                            <>
                                <div className={`tab_div mt-3 ${clickedItem === item.id ? 'active' : ''}`} onClick={() => showSubTopic(item.id)} key={i}>
                                    <p className={`tab_text ${clickedItem === item.id ? 'active' : ''}`}>{item.name}</p>
                                </div>
                            </>
                        ))
                    }
                </div>
                <div className='col-md-1'></div>
            </div>

            <div className='row mt-5'>
                <div className='col-md-1'></div>
                <div className='col-md-4' style={{ marginTop: '5%' }}>
                    <p className='mt-4' style={{ textAlign: 'justify', fontWeight: 500, fontSize: 32, color: '#000' }} >{subtopic}</p>
                    <ul className='mt-5 m-4' style={{ listStyleType: 'disc', color: '#000' }}>
                        {
                            topicdetails.map((item, i) => (
                                <li style={{ fontSize: 25, color: '#000' }}>{item.title}</li>
                            ))
                        }
                    </ul>
                    <div className="mt-3">
                        <NavLink className="btn btn-info student-registration" to="/membership" style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Buy Subscription</span></NavLink>
                    </div>
                </div>

                <div className='col-md-1'></div>
                <div className='col-md-6 video_bg' >
                    <video controls width="800" height="auto">
                        <source src={video_one} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            <div className="bg-black mt-5" style={{ border: 'none' }}>
                <p className="text-center pt-5" style={{ fontWeight: 700, fontSize: 38, color: '#fff' }}>Explore More</p>
                <div className="row pb-5">
                    <div className="col-md-4 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <div >
                            <p className="pt-5 head_Text" >Build your<br></br><br></br> Child’s Future </p>
                            <p className="mt-4" style={{ textAlign: 'justify', color: '#fff', fontSize: 17 }}>For Key Stage 1, Education <br></br> Capsule provides <br></br> personalized sessions that<br></br> are engaging for kids.</p>
                        </div>
                    </div>

                    <div className="col-md-4 mt-5">
                        <p className="text-center mt-4" onClick={() => this.showPaper('7+')} style={{ color: '#fff', fontWeight: 600, fontSize: 20, cursor: 'pointer' }} >7+ Exam Preparation</p>
                        <hr className="horizontal_line"></hr>
                        <p className="text-center mt-4" onClick={() => this.showPaper('11+')} style={{ color: '#fff', fontWeight: 600, fontSize: 20, cursor: 'pointer' }} >11+ Exam Preparation</p>
                        <hr className="horizontal_line"></hr>
                        <p className="text-center mt-4" onClick={() => this.showPaper('13+')} style={{ color: '#fff', fontWeight: 600, fontSize: 20, cursor: 'pointer' }} >13+ Exam Preparation</p>
                        <hr className="horizontal_line"></hr>

                        <div className="mt-3 mobile">
                            <NavLink className="btn btn-info student-registration" to="/book-a-tutor" style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Book free Lesson</span></NavLink>
                        </div>
                    </div>
                    <div className="col-md-4 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <div>
                            <img src={exploremore} alt="Exploremore" className="mt-4" style={{ width: 320 }} />
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TopicDetailsNew
