import React from "react";

//images
import { NavLink } from "react-router-dom";


function Registration() {
    return (
        <>
            <section id="book-a-turorrr">
                <div className="container">
                    <div className="registration-form-outer">
                        <div className="container register">
                            <div className="row" style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <div className="col-md-12 register-right" style={{ height: '350px' }}>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="row button mt-4">
                                            <div className="col-md-2"></div>
                                            <NavLink to='/student-register' className="col-md-4 category-card btn" style={{ background: 'none' }} >

                                                <h5 style={{ color: '#fff' }}>Student</h5>
                                            </NavLink>
                                            <NavLink to="/tutor-register" className="col-md-4 category-card btn" style={{ background: 'none' }} >

                                                <h5 style={{ color: '#fff' }}>Tutor</h5>
                                            </NavLink>

                                            <div className="col-md-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Registration;