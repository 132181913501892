import React from "react";

import * as Constant from '../components/Constant'
import LoadingOverlay from 'react-loading-overlay';

import { Navigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";

class BookSession extends React.Component {

    constructor() {
        super();

        this.state = {
            firstform: true,
            secondform: false,
            thirdform: false,
            subject_id: '',
            level_id: '',
            available_for_session: [],

            subject_idError: null,
            level_idError: null,

            LevelList: [],
            SubjectList: [],
            isLoading: false,
            isLoaded: false,
            isRequest: false,
            total_session: '',
            demo_session: '',
            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            captchError: null,
            from_date_and_time: ''
        }

        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
        this.handleChangeSession = this.handleChangeSession.bind(this);
    }

    componentDidMount = () => {
        this.getSubject();
        this.getStudentProfile();
    }

    getStudentProfile = async () => {

        var userId = localStorage.getItem('userId');

        await fetch(Constant.getUserProfile + '/' + userId, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        demo_session: data.data.demo_session,
                    });
                }

            })
    }

    getSubject = async () => {
        this.setState({ isLoading: true })

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    validate = () => {
        let subject_idError = '';
        // let level_idError = '';

        if (!this.state.subject_id) {
            subject_idError = "Please Select Subject"
        }
        // if (!this.state.level_id) {
        //     level_idError = "Please Select Level"
        // }


        if (subject_idError ) {
            this.setState({
                subject_idError
            })
            return false;
        }

        return true;
    }

    handleChangeSubject(e) {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel(e) {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
        console.log(this.state.level_id)
    }

    handleChangeSession(e) {
        console.log("total_session Selected!!", e.target.value);
        this.setState({ total_session: e.target.value });
        console.log(this.state.total_session)
    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                subject_idError: null,
            })
            this.bookSession()
        }
    }


    getLevel = async (value) => {
        this.setState({ isLoaded: true })

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoaded: false
                    })
                }
            })
    }

    bookSession = async () => {
        this.setState({
            isRequest: true
        })

        let userId = await localStorage.getItem('userId')

        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);
        formData.append("available_for_session", this.state.available_for_session);
        formData.append("total_session", this.state.total_session);
        formData.append("from_date_and_time", this.state.from_date_and_time);

        await fetch(Constant.BookSession, {
            method: 'POST',
            body: formData
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isRequest: false
                    })
                    window.location.href = Constant.BaseUrl + 'student-dashboard';
                    toast.success("Session Requested Successfully", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({
                        isRequest: false
                    })
                    console.log(data.message)
                }
            })
    }

    render() {
        var userId = localStorage.getItem("userId");
        if (userId) {
            return (
                <>
                    <LoadingOverlay
                        active={this.state.isRequest}
                        spinner
                        fadeSpeed={10}
                        text='Requested...'
                    >
                        <section id="book-a-turorrr">
                            <div class="main-content">
                                <h1 class="atf-single-details text-center text-light mb-3">Book Lesson</h1>
                                <div class="row atf-content-details d-flex align-items-center justify-content-center">
                                    <div class="col-md-6 wizard_mainwarp" >
                                        <ToastContainer />
                                        <div class="stepwarp form-main" id="step_1">
                                            <h3 class="step_title">How many sessions do you want to book?</h3>
                                            <div class="form-group has-feedback form-input">
                                                <select class="form-control" name="total_session" id="total_session" value={this.state.total_session} onChange={this.handleChangeSession}>
                                                    <option value="">Select Session</option>
                                                    {
                                                        this.state.demo_session === 1 ? '' : <option value="0">Book Free Session(30 mins)</option>
                                                    }
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                    <option value="17">17</option>
                                                    <option value="18">18</option>
                                                    <option value="19">19</option>
                                                    <option value="20">20</option>

                                                </select>
                                            </div>
                                            <h3 class="step_title">Which subject do you want to learn?</h3>
                                            <div class="form-group has-feedback form-input">
                                                <select class="form-control" autoComplete="on" name="subject_id" id="subject_id" value={this.state.subject_id} onChange={this.handleChangeSubject}>
                                                    <option value="">Select Subject</option>
                                                    {
                                                        this.state.SubjectList.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.title}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            {!!this.state.subject_idError && (
                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                    {this.state.subject_idError}
                                                </text>
                                            )}
                                            <h3 class="step_title">What level is this for?</h3>
                                            <div class="form-group ">
                                                {
                                                    this.state.LevelList.length === 0 ? <select autoComplete="on" class="form-control" >
                                                        <option value="">Level Not Found</option>
                                                    </select> : <select name="level_id" autoComplete="on" id="level_id" class="form-control" value={this.state.level_id} onChange={this.handleChangeLevel}>
                                                        <option value="">Select Level</option>
                                                        {
                                                            this.state.LevelList.map((item, i) => (
                                                                <option key={i} value={item.id}>{item.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                }
                                            </div>
                                            {/* {!!this.state.level_idError && (
                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                    {this.state.level_idError}
                                                </text>
                                            )} */}

                                            <h3 class="step_title">For what day and time do you want your session to be booked?</h3>
                                            <div class="form-group mb-3 " >
                                                <input type="datetime-local" name="from_date_and_time" onChange={(e) => this.setState({ from_date_and_time: e.target.value })} class="form-control" />
                                            </div>

                                            <button class="btn btn-next" type="submit" id="submit" style={{ float: 'right' }} onClick={() => this.onSubmit()}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </LoadingOverlay>
                </>
            );
        } else {
            return <Navigate to="/" />;
        }

    }
}

export default BookSession;