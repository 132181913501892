import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

import * as Constant from '../Constant'
import { useParams } from 'react-router-dom';

const stripePromise = loadStripe("pk_test_51MbIU5SJg6rvCqYBClPCMjgqiJe10P6CfcI6ERurQuVGh3jATRwkAIM23MGFMRXT1HQxLi6yacTpZNYgvEmyFqBQ00P6y1hDL5");

const cart = (amount) => {
    return (<React.Fragment>
        <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-muted">Your cart</span>
        </h4>
        <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                    <h6 className="my-0">Cart item</h6>
                </div>
                {/* <span className="text-muted">₹500</span> */}
            </li>
            <li className="list-group-item d-flex justify-content-between">
                <span>Total </span>
                <span className="text-muted">{amount}</span>
            </li>
        </ul>
    </React.Fragment>)
}

function Payment() {
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const { id } = useParams();

    const [amount, setAmount] = useState();

    const paymentDetails = async () => {

        await fetch(Constant.getPayMembership + '/' + id, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setAmount(data.data.price)
                }
            })
    }

    useEffect(() => {
        paymentDetails()
    }, [])

    return (
        <section id="book-a-turorrr">
            <div className='registration-form-outer'>
                <div className="container register">
                    <div className="row register-right" style={{ padding: 30 }}>
                        <React.Fragment>
                            <div className="col-md-5 order-md-2 mb-4">
                                {cart(amount)}
                            </div>
                            <div className="col-md-7 order-md-1">
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm amount={amount} setPaymentCompleted={setPaymentCompleted} />
                                </Elements>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Payment;