import React from "react";

import * as Constant from '../Constant'

class UploadHomeworkTutor extends React.Component {

    constructor() {
        super();
        this.state = {
            formone: true,
            formtwo: '',
            SubjectList: [],
            LevelList: [],
            SubLevelList: [],
            isLoading: false,
            TopicList: [],
            ChapterList: [],
            chapter_id: '',
            QuizList: [],

            subject_id: '',
            isLoaded: false,
            level_id: '',
            sublevel_id: '',
            topic_id: '',
            quiz_id: '',
            quiz_level: ''

        }

        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
        this.handleChangeSubLevel = this.handleChangeSubLevel.bind(this);
        this.handleChangeTopic = this.handleChangeTopic.bind(this);
        this.handleChangeChapter = this.handleChangeChapter.bind(this);
        this.handleChangeQuiz = this.handleChangeQuiz.bind(this);
        this.handleChangeQuizType = this.handleChangeQuizType.bind(this);
    }

    componentDidMount = () => {
        this.getSubject();

    }

    handleChangeLevel(e) {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
        this.getSubLevel(e.target.value)
    }

    handleChangeTopic(e) {
        console.log("topic_id Selected!!", e.target.value);
        this.setState({ topic_id: e.target.value });
        this.getChapter(e.target.value)
    }

    handleChangeChapter(e) {
        console.log("chapter_id Selected!!", e.target.value);
        this.setState({ chapter_id: e.target.value });
        this.getQuiz(e.target.value)
    }

    handleChangeQuiz(e) {
        console.log("quiz_id Selected!!", e.target.value);
        this.setState({ quiz_id: e.target.value });
    }

    handleChangeQuizType(e) {
        console.log("quiz_level Selected!!", e.target.value);
        this.setState({ quiz_level: e.target.value });
    }

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    handleChangeSubject(e) {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value)
        // console.log(this.state.country_id)
    }

    handleChangeSubLevel(e) {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ sublevel_id: e.target.value });
        this.getTopics(e.target.value)
        // console.log(this.state.country_id)
    }

    getLevel = async (value) => {
        this.setState({ isLoading: true })

        await fetch(Constant.getSubjectLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                console.log(data)
                if (data.success === true) {
                    this.setState({ LevelList: data.data })
                } else {
                    console.log('data not found')
                }
            })
    }

    getSubLevel = async (value) => {
        this.setState({ isLoaded: true })

        await fetch(Constant.getSubLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                console.log('+_+_', data)
                if (data.success === true) {
                    this.setState({
                        SubLevelList: data.data,
                        isLoaded: false
                    })
                }
            })
    }


    getTopics = async (value) => {

        await fetch(Constant.getTopics + '/' + value, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        TopicList: data.data,
                    })
                } else {
                    console.log('Topic not found')
                }
            })
    }

    getChapter = async (value) => {

        await fetch(Constant.getChapters + '/' + value, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        ChapterList: data.data,
                    })
                } else {
                    console.log('Topic not found')
                }
            })
    }

    getQuiz = async (value) => {

        await fetch(Constant.getQuizTitle + '/' + value, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        QuizList: data.data,
                    })
                } else {
                    console.log('Quiz not found')
                }
            })
    }

    uploadHomeWork = async () => {
        this.setState({ isLoading: true })

        var meetingId = await localStorage.getItem('meetingId')

        const formData = new FormData();
        formData.append("meeting_id", meetingId);
        formData.append("quiz_id", this.state.quiz_id);
        formData.append("quiz_level", this.state.quiz_level);

        await fetch(Constant.uploadHomeWorkTutor, {
            method: 'POST',
            body: formData
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false
                    })
                    window.location.href = Constant.BaseUrl + 'tutor-dashboard'
                }
            })
    }



    render() {
        return (

            <>
                <section id="feature" className="atf-section-padding tutor-page student-feedback">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12">
                                <div className="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                    <div className="student-feedback-page">
                                        <h1 className="colorBlack">Upload Homework Form</h1>
                                        <div className="student-feedback-form-outer tutor-feedback-form">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <h3 style={{ textAlign: 'left' }}>Please select Subject</h3>
                                                                <div class="form-group has-feedback form-input">
                                                                    <select class="form-control" name="subject_id" id="subject_id" value={this.state.subject_id} onChange={this.handleChangeSubject}>
                                                                        <option value="">Select Subject</option>
                                                                        {
                                                                            this.state.SubjectList.map((item, i) => (
                                                                                <option key={i} value={item.id}>{item.title}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h3 style={{ textAlign: 'left' }}>PLease Select Level</h3>
                                                            <div class="form-group ">
                                                                <select name="level_id" id="level_id" class="form-control" value={this.state.level_id} onChange={this.handleChangeLevel}>
                                                                    {
                                                                        this.state.LevelList.length === 0 ? <option>Level Not Found</option> : <><option value="">Select Level</option>
                                                                            {
                                                                                this.state.LevelList.map((item, i) => (
                                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <h3 style={{ textAlign: 'left' }}>Select SubLevel</h3>
                                                            <div class="form-group ">
                                                                <select name="sublevel_id" id="sublevel_id" class="form-control" value={this.state.sublevel_id} onChange={this.handleChangeSubLevel}>
                                                                    {
                                                                        this.state.SubLevelList.length === 0 ? <option>Level Not Found</option> : <><option value="">Select Level</option>
                                                                            {
                                                                                this.state.SubLevelList.map((item, i) => (
                                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h3 style={{ textAlign: 'left' }}>Select Chapter</h3>
                                                            <div class="form-group ">
                                                                <select name="topic_id" id="topic_id" class="form-control" value={this.state.topic_id} onChange={this.handleChangeTopic} >
                                                                    {
                                                                        this.state.TopicList.length === 0 ? <option>Chapter Not Found</option> : <><option value="">Select Level</option>
                                                                            {
                                                                                this.state.TopicList.map((item, i) => (
                                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                                ))
                                                                            }
                                                                        </>
                                                                    }

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h3 style={{ textAlign: 'left' }}>Select Topic</h3>
                                                    <div class="form-group ">
                                                        <select name="chapter_id" id="chapter_id" class="form-control" value={this.state.chapter_id} onChange={this.handleChangeChapter} >
                                                            {
                                                                this.state.ChapterList.length === 0 ? <option>Chapter Not Found</option> : <><option value="">Select Level</option>
                                                                    {
                                                                        this.state.ChapterList.map((item, i) => (
                                                                            <option key={i} value={item.id}>{item.title}</option>
                                                                        ))
                                                                    }
                                                                </>
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h3 style={{ textAlign: 'left' }}>Select Quiz</h3>
                                                    <div class="form-group ">
                                                        <select name="quiz_id" id="quiz_id" class="form-control" value={this.state.quiz_id} onChange={this.handleChangeQuiz}  >
                                                            {
                                                                this.state.QuizList.length === 0 ? <option>Quiz Not Found</option> : <><option value="">Select Level</option>
                                                                    {
                                                                        this.state.QuizList.map((item, i) => (
                                                                            <option key={i} value={item.id}>{item.quiz_title}</option>
                                                                        ))
                                                                    }
                                                                </>
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <h3 style={{ textAlign: 'left' }}>Select Quiz Level</h3>
                                                    <div class="form-group ">
                                                        <select name="quiz_level" id="quiz_level" class="form-control" value={this.state.quiz_level} onChange={this.handleChangeQuizType}  >
                                                            <option value=''>Select Level</option>
                                                            <option value='easy'>Easy</option>
                                                            <option value='medium'>Medium</option>
                                                            <option value='hard'>Hard</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-primary" aria-label="Close" onClick={this.uploadHomeWork}>Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section >

            </>
        )
    }
}

export default UploadHomeworkTutor