import React from "react";

//images
import * as Constant from '../components/Constant'
import LoadingOverlay from 'react-loading-overlay';
import ReCAPTCHA from 'react-google-recaptcha';

class StudentRegistration extends React.Component {

    constructor() {
        super();
        this.state = {
            CountryList: [],

            first_name: '',
            last_name: '',
            email: '',
            contact_number: '',
            password: '',
            c_password: '',
            country_id: 13,
            isLoading: false,
            first_nameError: null,
            last_nameError: null,
            emailError: null,
            contact_numberError: null,
            passwordError: null,
            c_passwordError: null,
            isActive: false,
            SubjectList: [],
            subject_id: '',
            LevelList: [],
            level_id: '',
            SubLevelList: [],
            sub_level_id: '',

            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            captchError: null,
            num1: Math.floor(Math.random() * 10) + 1,
            num2: Math.floor(Math.random() * 10) + 1,
            answer: '',
            isCorret: ''
        }

        this.recaptchaRef = React.createRef();

        this.handleChangeCountry = this.handleChangeCountry.bind(this);
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
        this.handleChangeSubLevel = this.handleChangeSubLevel.bind(this);

    }
    componentDidMount = () => {
        this.getCountry();
        this.getSubject();
    }

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    getCountry = async () => {
        await fetch(Constant.getCountry, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    // console.log(data.data)
                    this.setState({ CountryList: data.data })
                }
            })
    }

    handleChangeCountry(e) {
        console.log("country_id Selected!!", e.target.value);
        this.setState({ country_id: e.target.value });
    }

    handleChangeSubject(e) {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value)
    }

    handleChangeLevel(e) {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
        this.getSubLevel(e.target.value)
    }

    handleChangeSubLevel(e) {
        console.log("sub_level_id Selected!!", e.target.value);
        this.setState({ sub_level_id: e.target.value });
    }

    getLevel = async (value) => {
        this.setState({ isLoading: true })

        await fetch(Constant.getSubjectLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                console.log(data.data)
                if (data.success === true) {
                    this.setState({ LevelList: data.data })
                } else {
                    console.log('data not found')
                }
            })
    }

    getSubLevel = async (value) => {
        this.setState({ isLoaded: true })

        await fetch(Constant.getSubLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        SubLevelList: data.data,
                        isLoaded: false
                    })
                }
            })
    }

    validate = () => {
        let first_nameError = '';
        let last_nameError = '';
        let emailError = '';
        let contact_numberError = '';
        let passwordError = '';
        let c_passwordError = '';


        if (!this.state.first_name) {
            first_nameError = "Please Enter First Name"
        }


        if (!this.state.last_name) {
            last_nameError = "Please Enter Last Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "The Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (!this.state.contact_number) {
            contact_numberError = "Please Enter Contact Number"
        } else {
            if (this.state.contact_number.length <= 7) {
                contact_numberError = "Phone number cannot be less than eight digit"
            }
        }

        var pwd = this.state.password;
        var regPwd = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        );

        if (!this.state.password) {
            passwordError = "The Password cannot be empty";
        } else {
            if (pwd.length < 8) {
                passwordError = "Password cannot be less than eight characters";
            } else {
                if (!regPwd.test(pwd)) {
                    passwordError =
                        "Please enter a valid password, and it should contain at least one uppercase letter, one lowercase letter, one number and one special character. e.g. (Welcome@1)";
                }
            }
        }


        if (!this.state.c_password) {
            c_passwordError = "Please Enter Confirm Password"
        } else {
            if (this.state.password !== this.state.c_password) {
                c_passwordError = "The Password does not match. Try again";
            }
        }


        if (first_nameError || last_nameError || emailError || contact_numberError || passwordError || c_passwordError) {
            this.setState({
                first_nameError,
                last_nameError,
                emailError,
                contact_numberError,
                passwordError,
                c_passwordError
            });


            return false;
        }

        console.log('first_name', this.state.first_name)
        console.log('last_name', this.state.last_name)
        console.log('email', this.state.email)
        console.log('contact_number', this.state.contact_number)
        console.log('password', this.state.password)
        console.log('subject_id', this.state.subject_id)
        console.log('level_id', this.state.level_id)
        console.log('sub_level_id', this.state.sub_level_id)


        return true;
    }

    onSubmit = async () => {
        if (this.validate()) {
            this.setState({
                first_nameError: null,
                last_nameError: null,
                emailError: null,
                contact_numberError: null,
                passwordError: null,
                c_passwordError: null
            });
            this.onRegister();
        }
    }

    onRegister = async () => {
        this.setState({ isLoading: true, isActive: true })

        const recaptchaValue = await this.recaptchaRef.current.executeAsync();
        console.log(recaptchaValue)

        const formData = new FormData();
        formData.append("first_name", this.state.first_name);
        formData.append("last_name", this.state.last_name);
        formData.append("email", this.state.email);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);
        formData.append("sub_level_id", this.state.sub_level_id);
        formData.append("contact_number", this.state.contact_number);
        formData.append("password", this.state.password);
        formData.append("c_password", this.state.c_password);
        formData.append("country_id", this.state.country_id);
        formData.append("recaptchaToken", recaptchaValue);

        // console.log(formData);
        await fetch(Constant.RegisterUrl, {
            method: 'post',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    console.log(data)
                    this.setState({ isLoading: false, isActive: false })
                    window.location.href = Constant.BaseUrl + 'thank-you-register'
                } else {
                    this.setState({ isLoading: false, isActive: false })
                    if (data.data.email) {
                        this.setState({ emailError: 'Email Already Has been taken' })
                    } else if (data.data.contact_number) {
                        this.setState({ contact_numberError: 'Phone Number Already Has been taken' })
                    }
                }
            })

    }
    handleChangeFirstName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ first_name: result })
    };
    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ last_name: result })
    };
    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ contact_number: result })
    };

    render() {
        return (
            <LoadingOverlay
                active={this.state.isActive}
                spinner
                fadeSpeed={10}
                text='Register...'
            >
                <section id="loginnn">
                    <div className="container">
                        <div className="registration-form-outer ">
                            <div className="container registration">
                                <div className="row" style={{ marginLeft: '10px', marginRight: '10px' }}>
                                    <div className="col-md-12 register-right">
                                        <div className="tab-content" id="myTabContent" >
                                            <div className="row register-form " style={{ marginTop: '0%', padding: '10%' }}>
                                                <div className="col-md-12 text-center">
                                                    <h1 className="">Sign Up</h1>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>First Name</h5>
                                                            <input type="text" autoComplete="on" className="form-control" name="first_name" value={this.state.first_name} id="first_name" onChange={this.handleChangeFirstName} placeholder="First Name *" />
                                                            {!!this.state.first_nameError && (
                                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                                    {this.state.first_nameError}
                                                                </text>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Last Name</h5>
                                                            <input type="text" autoComplete="on" className="form-control" name="last_name" value={this.state.last_name} id="last_name" onChange={this.handleChangeLastName} placeholder="Last Name *" />
                                                            {!!this.state.last_nameError && (
                                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                                    {this.state.last_nameError}
                                                                </text>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Email</h5>
                                                            <input type="email" autoComplete="on" className="form-control" name="email" onChange={(event) =>
                                                                this.setState({ email: event.target.value })
                                                            } id="email" placeholder="Email *" />
                                                            {!!this.state.emailError && (
                                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                                    {this.state.emailError}
                                                                </text>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Contact Number</h5>
                                                            <input type="text" autoComplete="on" className="form-control" id="contact_number" value={this.state.contact_number} onChange={this.handleChangePhone} name="contact_number" placeholder="Phone No *" minLength="11" maxLength="11" />
                                                            {!!this.state.contact_numberError && (
                                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                                    {this.state.contact_numberError}
                                                                </text>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Password</h5>
                                                            <input type="password" autoComplete="on" id="password" className="form-control" onChange={(event) =>
                                                                this.setState({ password: event.target.value })
                                                            } name="password" placeholder="Password *" />
                                                            {!!this.state.passwordError && (
                                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                                    {this.state.passwordError}
                                                                </text>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Confirm Password</h5>
                                                            <input type="password" autoComplete="on" className="form-control" onChange={(event) =>
                                                                this.setState({ c_password: event.target.value })
                                                            } name="c_password" id="c_password" placeholder="Confirm Password *" />
                                                            {!!this.state.c_passwordError && (
                                                                <text style={{ color: "red", marginLeft: "10px" }}>
                                                                    {this.state.c_passwordError}
                                                                </text>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Subject</h5>
                                                            <select name="subject_id" id="subject_id" value={this.state.subject_id} onChange={this.handleChangeSubject} className="form-control country_dropdown">
                                                                <option value="">Select Subject</option>
                                                                {
                                                                    this.state.SubjectList.map((item, i) => (
                                                                        <option key={i} value={item.id}>{item.title}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Level</h5>
                                                            <select name="level_id" id="level_id" value={this.state.level_id} onChange={this.handleChangeLevel} className="form-control country_dropdown">
                                                                <option value="">Select Level</option>
                                                                {
                                                                    this.state.LevelList.map((item, i) => (
                                                                        <option key={i} value={item.id}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Sub Level</h5>
                                                            <select name="sub_level_id" id="sub_level_id" value={this.state.sub_level_id} onChange={this.handleChangeSubLevel} className="form-control country_dropdown">
                                                                <option value="">Select Sub Level</option>
                                                                {
                                                                    this.state.SubLevelList.map((item, i) => (
                                                                        <option key={i} value={item.id}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <h5>Country</h5>
                                                            <select name="country_id" id="country_id" value={this.state.country_id} onChange={this.handleChangeCountry} className="form-control country_dropdown">
                                                                <option value="">Select Country</option>
                                                                {
                                                                    this.state.CountryList.map((item, i) => (
                                                                        <option key={i} value={item.id}>{item.name}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 ">
                                                        <ReCAPTCHA
                                                            sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                                            size="invisible"
                                                            ref={this.recaptchaRef}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6 text-center">
                                                        <button
                                                            type="submit"
                                                            name="submit"
                                                            onClick={() => this.onSubmit()}
                                                            class="btnRegister"
                                                        >

                                                            Register

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </LoadingOverlay>
        )
    }
}

export default StudentRegistration;