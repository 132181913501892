import React from "react";
import { NavLink, Navigate } from "react-router-dom";

import * as Constant from '../Constant'
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { SpinnerCircular } from 'spinners-react';

import { ImCross } from 'react-icons/im';

class StudentDashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            tabone: true,
            tabtwo: false,
            tabthree: false,
            checked: false,

            getTodaySession: [],
            completedSession: [],
            isLoading: false,
            modalshow: false,

            question: '',
            question_type: '',
            answer: '',
            homeWork: [],
            meetingId: '',
            student_answer: [],
            is_submited: '',
            getanswer: [],
            rightAnswer: '',
            total_question: '',
            sessionRequest: [],

            editmodal: false,
            LevelList: [],
            SubjectList: [],

            subject_id: '',
            level_id: '',
            level_name: '',
            user_id: '',
            pageUrl: '',
            currentPage: 0,
            isLoadMore: false,
            upcomingSession: [],
            pageUrlUpcoming: '',
            pageUrlPrevious: '',
            isLoadMoreUpcoming: false,
            tomorrowSession: [],
            upcomingPrevious: false,
            feedbackSubmitted: false,

            QuestionList: [],
            Question_id: [],
            questionArray: [],
            rightAnswer: 0,
            is_submitted: false,

            subject_name: '',
            is_feedback_submited: '',
            from_date_and_time: '',
            answerArray: [],
            submited_date: ''
        }

        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);

    }

    handleChangeSubject(e) {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value)
        // console.log(this.state.country_id)
    }

    handleChangeLevel(e) {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });

    }

    getSubject = async () => {
        this.setState({ isLoading: true })

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })

    }

    getLevel = async (value) => {
        this.setState({ isLoaded: true })

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoaded: false
                    })
                }
            })
    }

    componentDidMount = () => {
        this.getTodaySession()
        this.getSubject();
        this.getSessionReports();
        this.getLastSession();
    }

    getTodaySession = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId')

        await fetch(Constant.getScheduledSession + '/' + userId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        getTodaySession: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.error('Oops!!!... There is no Internet Connection', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
        this.getCompletedSession();
        this.getUpcoming()
        this.getTomorrow()
    }

    getTomorrow = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId')

        await fetch(Constant.getTomorrowSession + '/' + userId + '?page=' + 1, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        tomorrowSession: data.data.data,
                        isLoading: false,
                        pageUrl: data.data.next_page_url
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.error('Oops!!!... There is no Internet Connection', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
    }

    getUpcoming = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId')

        await fetch(Constant.getUpcomingSession + '/' + userId + '?page=' + 1, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        upcomingSession: data.data.data,
                        isLoading: false,
                        pageUrlUpcoming: data.data.next_page_url,
                        pageUrlPrevious: data.data.prev_page_url
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.error('Oops!!!... There is no Internet Connection', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
    }

    upComingSessionchange = async () => {
        this.setState({ isLoadMoreUpcoming: true })
        if (this.state.pageUrlUpcoming !== null) {
            await fetch(this.state.pageUrlUpcoming, {
                method: 'GET'
            })
                .then(Response => Response.json())
                .then(data => {
                    if (data.success === true) {
                        this.setState({ isLoadMoreUpcoming: false })
                        this.setState({
                            upcomingSession: data.data.data,
                            isLoading: false,
                            pageUrlUpcoming: data.data.next_page_url,
                            pageUrlPrevious: data.data.prev_page_url
                        })
                    } else {
                        this.setState({ isLoading: false })
                        toast.success('Oops!!!... There is no Internet Connection', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                })
        }
    }

    upcomingSessionprevious = async () => {
        this.setState({ upcomingPrevious: true })
        if (this.state.pageUrlPrevious !== null) {
            await fetch(this.state.pageUrlPrevious, {
                method: 'GET'
            })
                .then(Response => Response.json())
                .then(data => {
                    if (data.success === true) {
                        this.setState({ upcomingPrevious: false })
                        this.setState({
                            upcomingSession: data.data.data,
                            isLoading: false,
                            pageUrlUpcoming: data.data.next_page_url,
                            pageUrlPrevious: data.data.prev_page_url
                        })
                    } else {
                        this.setState({ isLoading: false })
                        toast.success('Oops!!!... There is no Internet Connection', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                })
        }
    }

    getCompletedSession = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId')

        await fetch(Constant.getCompletedSession + '/' + userId + '?page=' + 1, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                console.log(data.data.data)
                if (data.success === true) {
                    this.setState({
                        completedSession: data.data.data,
                        isLoading: false,
                        pageUrl: data.data.next_page_url
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.success('Oops!!!... There is no Internet Connection', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }

            })
    }

    getHomework = async (value) => {
        this.setState({
            isLoading: true,
            modalshow: true
        })

        var meetingId = value;
        this.setState({
            meetingId: value
        })

        await fetch(Constant.homeworkStudent + '/' + meetingId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        QuestionList: data.data,
                        total_question: data.data.length,
                        submited_date: data.data.created_at
                    })

                } else {
                    console.log('not found')
                }
            })
    }

    handleClose = () => {
        this.setState({ modalshow: false })
    }

    handleCloseEdit = () => {
        this.setState({ editmodal: false })
    }

    submitHomeWork = async () => {
        this.setState({ isLoading: true, is_submitted: true })

        const formData = new FormData();

        var studentAnswer = JSON.stringify(this.state.student_answer);

        formData.append("student_answer", studentAnswer);
        formData.append("right_answer", this.state.rightAnswer);
        formData.append("meeting_id", this.state.meetingId);

        await fetch(Constant.submitHomeWork, {
            method: 'POST',
            body: formData
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        rightAnswer: data.data.right_answer
                    })
                } else {
                    console.log('There is something wrong')
                }
            })

    }

    ShowTab = (value) => {
        if (value === 'tabone') {
            this.setState({ tabone: true, tabthree: false, tabtwo: false })
        } else if (value === 'tabtwo') {
            this.setState({ tabtwo: true, tabthree: false, tabone: false })
        } else {
            this.setState({ tabtwo: false, tabthree: true, tabone: false })
        }
    }

    getSessionReports = async () => {
        this.setState({
            isLoading: true
        })

        var userId = localStorage.getItem('userId')
        var tutorId = localStorage.getItem('tutorId')
        // console.log(tutorId)

        await fetch(Constant.sessionReport + '?user_id=' + userId + '&tutor_id=' + tutorId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    if (data.data.length !== 0) {
                        this.setState({
                            feedbackSubmitted: true
                        })
                    } else {
                        this.setState({
                            feedbackSubmitted: false
                        })
                    }

                }
            })

    }

    saveId = (value) => {
        localStorage.setItem('tutorId', value)
    }

    handleChangePage = async () => {
        this.setState({ isLoadMore: true })
        if (this.state.pageUrl !== null) {
            await fetch(this.state.pageUrl, {
                method: 'GET'
            })
                .then(Response => Response.json())
                .then(data => {
                    if (data.success === true) {
                        this.setState({ isLoadMore: false })
                        this.setState({
                            completedSession: this.state.completedSession.concat(data.data.data),
                            isLoading: false,
                            pageUrl: data.data.next_page_url,
                        })
                    } else {
                        this.setState({ isLoading: false })
                        toast.success('Oops!!!... There is no Internet Connection', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                })
        }


    }

    updateSession = async () => {
        this.setState({ isLoading: false })

        const formData = new FormData();
        formData.append("id", this.state.user_id);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);

        await fetch(Constant.editSessionRequest, {
            method: 'POST',
            body: formData
        })
            .then(Response => Response.json())
            .then((data) => {
                if (data.success === true) {
                    this.setState({
                        editmodal: false,
                    })
                } else {
                    console.log('error')
                }
            })
    }

    getSessionValue = async (value) => {
        this.setState({ isLoading: false, editmodal: true })

        var id = value;
        this.setState({
            user_id: id
        })

        await fetch(Constant.getSession + '/' + id, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        subject_id: data.data.subject_id,
                        level_name: data.data.level.name,
                        from_date_and_time: data.data.from_date_and_time,
                    })
                } else {
                    console.log('okkkk')
                }

            })
    }

    storeAnswer = (id, value) => {
        let count = this.state.rightAnswer;

        this.state.QuestionList.forEach((item) => {
            if (item.id === id) {
                if (item.answer === value) {
                    count++;
                    this.setState({
                        rightAnswer: count
                    })
                }
            }
        });

        this.setState((prev) => ({
            student_answer: {
                ...prev.student_answer,
                [id]: value
            }
        }));

        console.log(this.state.student_answer)

    }

    getLastSession = async () => {

        var userId = localStorage.getItem('userId')

        await fetch(Constant.getLastSession + '?user_id=' + userId, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    console.log(data)
                    this.setState({
                        is_feedback_submited: data.data.feedback_submited
                    })
                } else {
                    console.log('not Found')
                }
            })
    }

    uploadHomeWorkFile = async (e, id) => {

        var studentFile = e.target.files[0];

        const formData = new FormData();
        formData.append("student_file", studentFile);

        await fetch(Constant.homeWorkFileStudent + '/' + id, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    toast.success("Homework Uploaded Successfully", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    console.log('file Not Uploaded');
                }
            })
    }


    render() {
        var userId = localStorage.getItem("userId");
        if (userId) {
            return (
                <div>
                    <section className="atf-section-padding tutor-page students-page" style={{ minHeight: '1080px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-xl-12">
                                    <div className="atf-section-title text-center" >
                                        <NavLink to="/book-session" style={{ float: 'right', marginBottom: '10px' }}><button className="btn btn-primary dashboard-btnnnnn" type="button">Book lesson now</button></NavLink>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-xl-12">
                                    <div className="tabbing-content-outer">
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li className="nav-item my-booking">
                                                <a className={this.state.tabone === true ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={() => this.ShowTab('tabone')} href="javascript:void(0)" role="tab">My Timetable</a>
                                            </li>
                                            <li className="nav-item start-session">
                                                <a className={this.state.tabtwo === true ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={() => this.ShowTab('tabtwo')} href="javascript:void(0)" role="tab">My Resources</a>
                                            </li>
                                            <li className="nav-item progress-report">
                                                <a data-toggle="tab" className={this.state.tabthree === true ? "nav-link active" : "nav-link"} onClick={() => this.ShowTab('tabthree')} href="javascript:void(0)" role="tab">Student Progress Report</a>
                                            </li>
                                        </ul>
                                        <ToastContainer />
                                        {
                                            this.state.tabone === true ?
                                                <div>
                                                    <div className="tab-content mb-3">
                                                        <div className="tab-pane active" role="tabpanel">
                                                            <h4>Today's Session</h4>
                                                            <table className="table student_dashboard allrecordtable">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="no_border" >Tutor Name</th>
                                                                        <th className="no_border">Date</th>
                                                                        <th className="no_border">Time</th>
                                                                        <th className="no_border">Subject</th>
                                                                        <th className="no_border">Session Link</th>
                                                                        <th className="no_border">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    this.state.getTodaySession.length === 0 ? <tbody className="mainBody"><tr style={{ textAlign: 'center' }}><td className="no_border" colSpan={6}>No Active Sessions. Book Lesson Now!</td></tr></tbody> : <tbody className="mainBody">
                                                                        {
                                                                            this.state.getTodaySession.map((item, i) => (
                                                                                <tr key={i}>
                                                                                    {

                                                                                        item.tutor === null ? <td>Not Assigned Yet</td> : <td className="no_border">{item.tutor.first_name}&nbsp;{item.tutor.last_name}</td>
                                                                                    }
                                                                                    {
                                                                                        item.from_date_and_time === null ? <td>-</td> : <td className="no_border">{moment(item.from_date_and_time).format('DD-MM-YYYY')}</td>
                                                                                    }
                                                                                    {
                                                                                        item.from_date_and_time === null ? <td>-</td> : <td className="no_border">{moment(item.from_date_and_time).format('hh:mm')}</td>
                                                                                    }
                                                                                    <td className="no_border">{item.subject.title}</td>
                                                                                    {
                                                                                        item.session_link === null ? <td>-</td> : <>
                                                                                            {
                                                                                                this.state.is_feedback_submited === 0 ? <td>-</td> : <td className="no_border" style={{ padding: '20px' }}><a target="_blank"
                                                                                                    rel="noreferrer"
                                                                                                    className="join_now"
                                                                                                    href={item.session_link}>Join Now</a></td>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    <td className="no_border" style={{ color: '#c42c89' }} >-</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                }

                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/* <div className="tab-content mb-3">
                                                        <div className="tab-pane active" role="tabpanel">
                                                            <h4>Tomorrow's Session</h4>

                                                            <table className="table student_dashboard allrecordtable">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="no_border">Tutor Name</th>
                                                                        <th className="no_border">Date</th>
                                                                        <th className="no_border">Time</th>
                                                                        <th className="no_border">Subject</th>
                                                                        <th className="no_border">Session Link</th>
                                                                        <th className="no_border">Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    this.state.tomorrowSession.length === 0 ? <tbody className="mainBody" ><tr style={{ textAlign: 'center' }}><td className="no_border" colSpan={6} >No Active Sessions. Book Lesson Now!</td></tr></tbody> : <tbody className="mainBody">
                                                                        {
                                                                            this.state.tomorrowSession.map((item, i) => (
                                                                                <tr key={i}>
                                                                                    {
                                                                                        item.tutor === null ? <td>Not Assigned Yet</td> : <td className="no_border">{item.tutor.first_name}&nbsp;{item.tutor.last_name}</td>
                                                                                    }
                                                                                    {
                                                                                        item.from_date_and_time === null ? <td>-</td> : <td className="no_border">{moment(item.from_date_and_time).format('DD-MM-YYYY')}</td>
                                                                                    }
                                                                                    {
                                                                                        item.from_date_and_time === null ? <td>-</td> : <td className="no_border">{moment(item.from_date_and_time).format('hh:mm')}</td>
                                                                                    }
                                                                                    <td className="no_border">{item.subject.title}</td>
                                                                                    {
                                                                                        item.session_link === null ? <td>-</td> : <>
                                                                                            {
                                                                                                this.state.is_feedback_submited === 0 ? <td>-</td> : <td className="no_border" style={{ padding: '20px' }}><a target="_blank"
                                                                                                    rel="noreferrer"
                                                                                                    className="join_now"
                                                                                                    href={item.session_link}>Join Now</a></td>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    <td className="no_border" style={{ cursor: 'pointer', color: '#c42c89' }} onClick={() => this.getSessionValue(item.id)}>Edit Session</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    <div className="tab-content mb-3" style={{ paddingBottom: '50px' }}>
                                                        <div className="tab-pane active" role="tabpanel">
                                                            <h4>UpComing Session</h4>
                                                            <table className="table student_dashboard allrecordtable">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="no_border">Tutor Name</th>
                                                                        <th className="no_border">Date</th>
                                                                        <th className="no_border">Time</th>
                                                                        <th className="no_border">Subject</th>
                                                                        <th className="no_border">Session Link</th>
                                                                        <th className="no_border">Actions</th>
                                                                    </tr>
                                                                </thead>

                                                                {
                                                                    this.state.upcomingSession.length === 0 ?
                                                                        <tbody className="mainBody" >
                                                                            <tr style={{ textAlign: 'center' }}>
                                                                                <td colSpan={6} className="no_border">No Active Sessions. Book Lesson Now!</td>
                                                                            </tr>
                                                                        </tbody>
                                                                        :
                                                                        <tbody className="mainBody">
                                                                            {
                                                                                this.state.upcomingSession.map((item, i) => (
                                                                                    <tr key={i}>
                                                                                        {
                                                                                            item.tutor === null ? <td>Not Assigned Yet</td> : <td className="no_border">{item.tutor.first_name}&nbsp;{item.tutor.last_name}</td>
                                                                                        }
                                                                                        {
                                                                                            item.from_date_and_time === null ? <td>-</td> : <td className="no_border">{moment(item.from_date_and_time).format('DD-MM-YYYY')}</td>
                                                                                        }
                                                                                        {
                                                                                            item.from_date_and_time === null ? <td>-</td> : <td className="no_border">{moment(item.from_date_and_time).format('hh:mm')}</td>
                                                                                        }
                                                                                        <td className="no_border">{item.subject.title}</td>
                                                                                        {
                                                                                            item.session_link === null ? <td>-</td> : <>
                                                                                                {
                                                                                                    this.state.is_feedback_submited === 0 ? <td className="no_border">-</td> : <td className="no_border" ><a target="_blank"
                                                                                                        rel="noreferrer"
                                                                                                        className="join_now"
                                                                                                        href={item.session_link}>Join Now</a></td>
                                                                                                }
                                                                                            </>
                                                                                        }

                                                                                        <td className="no_border" style={{ cursor: 'pointer', color: '#c42c89' }} onClick={() => this.getSessionValue(item.id)}>Edit Session</td>
                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                }

                                                            </table>
                                                            <div className="row">
                                                                {
                                                                    this.state.pageUrlPrevious === null ? '' :
                                                                        <div className="col-md-6 col-lg-6 col-sm-12 ">
                                                                            {
                                                                                this.state.upcomingPrevious === true ? <Button className="load_more"><SpinnerCircular size="30" /></Button> : <Button className="load_more" onClick={() => this.upcomingSessionprevious()} style={{ width: '115px' }}><i class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Previous</Button>
                                                                            }

                                                                        </div>
                                                                }
                                                                {
                                                                    this.state.pageUrlUpcoming === null ? '' :
                                                                        <div className="col-md-6 col-lg-6 col-sm-12 ">
                                                                            {
                                                                                this.state.isLoadMoreUpcoming === true ? <Button className="load_more" style={{ float: 'right', width: '115px' }}><SpinnerCircular size="30" /></Button> : <Button className="load_more" onClick={() => this.upComingSessionchange()} style={{ float: 'right', width: '115px' }}>Next<i class="fa fa-arrow-right ml-2" aria-hidden="true"></i></Button>
                                                                            }

                                                                        </div>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div> : ''
                                        }
                                        {
                                            this.state.tabtwo === true ?
                                                <div className="tab-content mb-3">
                                                    <div className="tab-pane active" role="tabpanel">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="no_border">Subject</th>
                                                                    <th className="no_border">Topic</th>
                                                                    <th className="no_border">Learning Resources</th>
                                                                    <th className="no_border">Class Resources</th>
                                                                </tr>
                                                            </thead>
                                                            {
                                                                this.state.completedSession.length === 0 ? <tbody className="mainBody" ><tr style={{ textAlign: 'center' }}><td colSpan={6} >Not Completed Sessions Right Now</td></tr></tbody> : <tbody className="mainBody">
                                                                    {
                                                                        this.state.completedSession.map((item, i) => (
                                                                            <tr key={i}>
                                                                                <td className="no_border">{item.subject.title === null ? '' : item.subject.title}</td>
                                                                                <td className="no_border">{item.topic === null ? '' : item.topic.name}</td>
                                                                                {
                                                                                    item.meeting_id !== null ? <>
                                                                                        {
                                                                                            item.is_submited === "0" ? <td className="no_border" style={{ color: '#c42c89', cursor: 'pointer' }} onClick={() => this.getHomework(item.id)}>Homework</td> : <td style={{ color: '#c42c89', cursor: 'pointer' }} >Homework Uploaded</td>
                                                                                        }

                                                                                    </> : <td>-</td>
                                                                                }
                                                                                <td className="no_border">-</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            }


                                                        </table>

                                                        {
                                                            this.state.pageUrl === null ? '' :
                                                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                                    {
                                                                        this.state.isLoadMore === true ? <Button className="load_more"><SpinnerCircular size="30" /></Button> : <Button className="load_more" onClick={() => this.handleChangePage()}>Load More</Button>
                                                                    }

                                                                </div>
                                                        }

                                                    </div></div> : ''
                                        }
                                        {
                                            this.state.tabthree === true ?
                                                <div className="tab-content mb-3">
                                                    <div className="tab-pane active" role="tabpanel">
                                                        <div className="resources-tab">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th className="no_border">Tutor Name</th>
                                                                        <th className="no_border">Date</th>
                                                                        <th className="no_border">Time</th>
                                                                        <th className="no_border">Topic</th>
                                                                        <th className="no_border">Session'S Report</th>
                                                                        <th className="no_border">Homework</th>
                                                                        <th className="no_border">Download Homework</th>
                                                                        <th className="no_border">Upload AnswerSheet</th>
                                                                        <th className="no_border">Feedback</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    this.state.completedSession.length === 0 ? <tbody className="mainBody" ><tr style={{ textAlign: 'center' }}><td colSpan={8} >Not Completed Sessions Right Now</td></tr></tbody> : <tbody className="mainBody">
                                                                        {
                                                                            this.state.completedSession.map((item, i) => (
                                                                                <tr key={i}>
                                                                                    <td className="no_border">{item.tutor.first_name}&nbsp;{item.tutor.last_name}</td>
                                                                                    <td className="no_border">{moment(item.from_date_and_time).format("DD-MM-YYYY")}</td>
                                                                                    <td className="no_border">{moment(item.from_date_and_time).format('hh:mm')}</td>
                                                                                    <td className="no_border">{item.subject.title}</td>
                                                                                    <td className="no_border" style={{ color: '#88185D' }}><NavLink to="/reports-data" onClick={() => this.saveId(item.tutor.id)} >Link To Report</NavLink></td>
                                                                                    {
                                                                                        item.is_submited === 0 ? <td className="no_border">awaited</td> : <td className="no_border">{moment(item.submitdate).format('DD-MM-YYYY')}</td>
                                                                                    }
                                                                                    {
                                                                                        item.homework_file !== null ? <td className="no_border" style={{ color: '#88185D' }}><a href={item.homework_file} target="_blank" download>Download Homework</a></td> : <td></td>
                                                                                    }
                                                                                    {
                                                                                        item.student_file !== null ? <td>-</td> : <td style={{ color: '#88185D', cursor: 'pointer' }} ><input type="file" onChange={(e) => this.uploadHomeWorkFile(e, item.id)}></input></td>
                                                                                    }
                                                                                    {/* {
                                                                                        item.is_submited === 0 ? <td className="no_border">awaited</td> : <td className="no_border">{moment(item.submitdate).format('DD-MM-YYYY')}</td>
                                                                                    } */}

                                                                                    {
                                                                                        this.state.feedbackSubmitted === true ? "-" : <td className="no_border" style={{ color: '#88185D' }}><NavLink to="/student-feedback" onClick={() => this.saveId(item.tutor.id)}  >Give FeedBack</NavLink></td>
                                                                                    }
                                                                                    <td></td>

                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                }

                                                            </table>
                                                        </div>
                                                    </div></div> : ''
                                        }

                                        <Modal show={this.state.modalshow} onHide={this.handleClose}>
                                            {
                                                this.state.is_submitted === true ? <></> :
                                                    <Modal.Header >
                                                        <div className="container">
                                                            <div className="row" >
                                                                <div className="col-sm-12 col-md-10">
                                                                    <h3 style={{ fontSize: 25, color: '#fff' }} className="text-center mb-2">Submit Homework</h3>
                                                                </div>
                                                                <div className="col-sm-12 col-md-2">
                                                                    <button type="button" className="cross-sign" onClick={this.handleClose}> <ImCross /></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Modal.Header>
                                            }

                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {
                                                            this.state.is_submitted === true ? <><h3>Your total Score is:{this.state.rightAnswer}/{this.state.total_question}</h3> </> : <div className="form-group">
                                                                {
                                                                    this.state.QuestionList.length === 0 ? <>
                                                                    </> :
                                                                        <>
                                                                            {
                                                                                this.state.QuestionList.map((item, i) => (
                                                                                    <>
                                                                                        <h3 className="mt-3">Question : {i + 1}</h3>
                                                                                        {
                                                                                            item.question !== null ? <div style={{ color: '#000' }} dangerouslySetInnerHTML={{ __html: item.question }}></div> : ''
                                                                                        }
                                                                                        {
                                                                                            item.question_type === 'mcq' ? <div key={i} className="radio-with-Icon" style={{ display: 'flex' }}>

                                                                                                <div >
                                                                                                    {
                                                                                                        item.option_1 === null ? '' : <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox " style={{ marginRight: 10 }}>
                                                                                                                <input type="radio" className="custom-control-input " onClick={() => this.storeAnswer(item.id, 'option_1')} name={`option_${item.id}`} id={`option_1_${i}`} />
                                                                                                                <label htmlFor={`option_1_${i}`} style={{ color: '#A1467E' }} dangerouslySetInnerHTML={{ __html: item.option_1 }} className={`container center-class custom-control-label colorBlack ${this.state.student_answer[item.id] === 'option_1' ? 'checked' : ''}`}></label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {
                                                                                                        item.option_2 === null ? '' : <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" style={{ marginRight: 10 }}>
                                                                                                                <input type="radio" className="custom-control-input " onClick={() => this.storeAnswer(item.id, 'option_2')} name={`option_${item.id}`} id={`option_2_${i}`} />
                                                                                                                <label className={`container center-class custom-control-label colorBlack ${this.state.student_answer[item.id] === 'option_2' ? 'checked' : ''}`} htmlFor={`option_2_${i}`} style={{ color: '#A1467E' }} dangerouslySetInnerHTML={{ __html: item.option_2 }}></label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }

                                                                                                </div>

                                                                                                <div >
                                                                                                    {
                                                                                                        item.option_3 === null ? '' : <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" style={{ marginRight: 10 }}>
                                                                                                                <input type="radio" className="custom-control-input" onClick={() => this.storeAnswer(item.id, 'option_3')} name={`option_${item.id}`} id={`option_3_${i}`} />
                                                                                                                <label className={`container center-class custom-control-label colorBlack ${this.state.student_answer[item.id] === 'option_3' ? 'checked' : ''}`} htmlFor={`option_3_${i}`} style={{ color: '#A1467E' }} dangerouslySetInnerHTML={{ __html: item.option_3 }}></label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }

                                                                                                    {
                                                                                                        item.option_4 === null ? '' : <div className="radioOption-Item">
                                                                                                            <div className="custom-control custom-checkbox" style={{ marginRight: 10 }}>
                                                                                                                <input type="radio" className="custom-control-input" onClick={() => this.storeAnswer(item.id, 'option_4')} name={`option_${item.id}`} id={`option_4_${i}`} />
                                                                                                                <label className={`container center-class custom-control-label colorBlack ${this.state.student_answer[item.id] === 'option_4' ? 'checked' : ''}`} htmlFor={`option_4_${i}`} style={{ color: '#A1467E' }} dangerouslySetInnerHTML={{ __html: item.option_4 }}></label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    }

                                                                                                </div>
                                                                                            </div> : <div className="radio-with-Icon col-md-12 mt-2" >
                                                                                                <div className="form-group ">
                                                                                                    <input type="text" className="form-control" name="student_answer" style={{ width: '66%' }} onChange={(e) => this.storeAnswer(item.id, e.target.value)} placeholder="Type Your Answer" />
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                    </>
                                                                                ))
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            {
                                                this.state.is_submitted === true ? <Modal.Footer>
                                                    <Button className="closeButton" onClick={this.handleClose}>
                                                        Close
                                                    </Button>
                                                </Modal.Footer> : <Modal.Footer>
                                                    <Button className="closeButton" onClick={this.handleClose}>
                                                        Close
                                                    </Button>
                                                    <Button className="closeButton" onClick={this.submitHomeWork}>
                                                        Submit
                                                    </Button>
                                                </Modal.Footer>
                                            }

                                        </Modal>

                                        <Modal show={this.state.editmodal} onHide={this.handleCloseEdit} centered >
                                            <Modal.Header closeButton>
                                                <Modal.Title style={{ color: '#000' }}>Edit Session</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body >
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <h3 className="step_title">Which Subject Do You Want To Learn?</h3>
                                                                    <div className="form-group has-feedback form-input">
                                                                        <select className="form-control" name="subject_id" id="subject_id" value={this.state.subject_id} onChange={this.handleChangeSubject}>
                                                                            <option value="">Select Subject</option>
                                                                            {
                                                                                this.state.SubjectList.map((item, i) => (
                                                                                    <option key={i} value={item.id}>{item.title}</option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h3 className="step_title">What Level Is This For?</h3>
                                                                <div className="form-group ">
                                                                    <select name="level_id" id="level_id" className="form-control" value={this.state.level_id} onChange={this.handleChangeLevel}>
                                                                        {
                                                                            this.state.LevelList.length === 0 ? <><option value="">{this.state.level_name}</option></> : <> <option value="">Select Level</option>
                                                                                {
                                                                                    this.state.LevelList.map((item, i) => (
                                                                                        <option key={i} value={item.id}>{item.name}</option>
                                                                                    ))
                                                                                }</>
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h3 class="step_title">For what day and time do you want your session to be booked?</h3>
                                                        <div class="form-group mb-3 " >
                                                            <input type="datetime-local" name="from_date_and_time" onChange={(e) => this.setState({ from_date_and_time: e.target.value })} value={this.state.from_date_and_time} class="form-control" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className="btn submit_btn" type="submit" id="submit" onClick={() => this.updateSession()}>SUBMIT</button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )
        } else {
            return <Navigate to="/" />;
        }

    }
}

export default StudentDashboard