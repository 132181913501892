import React from "react";

import * as Constant from '../Constant'
import { toast } from "react-toastify";
import { Navigate, NavLink } from "react-router-dom";
import moment from "moment/moment";

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { SpinnerCircular } from 'spinners-react';
import { ImCross } from 'react-icons/im';
import { FaCheck } from 'react-icons/fa';

class TutorDashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            tabone: true,
            tabtwo: false,
            tabthree: false,

            isLoading: false,
            completedSession: [],
            getSession: [],
            homeWork: [],
            meetingId: '',
            student_answer: [],
            is_submited: '',
            getanswer: [],
            rightAnswer: '',
            total_question: '',
            modalshow: false,
            feedbackSubmitted: false,
            getUpcomingSession: [],
            getTomorrowSession: [],
            upcomingUrl: '',
            upcomingUrlPrevious: '',
            isLoadMoreUpcoming: false,
            upcomingPrevious: false,
            is_feedback_submited: '',
            is_approved: '',
            modal: true,

            AnswerArray: []
        }
    }

    handleClose = () => {
        this.setState({ modalshow: false })
    }

    ShowTab = (value) => {
        if (value === 'tabone') {
            this.setState({ tabone: true, tabthree: false, tabtwo: false })
        } else if (value === 'tabtwo') {
            this.setState({ tabtwo: true, tabthree: false, tabone: false })
        } else {
            this.setState({ tabtwo: false, tabthree: true, tabone: false })
        }
    }

    componentDidMount = () => {
        this.getAllSession();
        this.getTomorrowSession();
        this.getLastSession()
    }

    getStudentProfile = async () => {
        this.setState({ isLoading: true })

        var userId = localStorage.getItem('userId');

        await fetch(Constant.getUserProfile + '/' + userId, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        is_approved: data.data.is_approved,
                    });
                }
            })
    }

    getAllSession = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId')

        await fetch(Constant.getTutorSession + '/' + userId + '?page=' + 1, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        getSession: data.data.data,
                        isLoading: false,
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.success('Oops!!!... There is no Internet Connection', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                this.getCompletedSession()
                this.getStudentProfile();
            })
    }

    getTomorrowSession = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId')

        await fetch(Constant.getTutorTomorrowSession + '/' + userId + '?page=' + 1, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        getTomorrowSession: data.data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.success('Oops!!!... There is no Internet Connection', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
                this.getUpcomingSession()
            })
    }

    getUpcomingSession = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId')

        await fetch(Constant.getTutorUpcomingSession + '/' + userId + '?page=' + 1, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        getUpcomingSession: data.data.data,
                        isLoading: false,
                        upcomingUrl: data.data.next_page_url,
                        upcomingUrlPrevious: data.data.prev_page_url
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.success('Oops!!!... There is no Internet Connection', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
    }

    upComingSessionchange = async () => {
        this.setState({ isLoadMoreUpcoming: true })
        if (this.state.upcomingUrl != null) {
            await fetch(this.state.upcomingUrl, {
                method: 'GET'
            })
                .then(Response => Response.json())
                .then(data => {
                    if (data.success === true) {
                        this.setState({ isLoadMoreUpcoming: false })
                        this.setState({
                            getUpcomingSession: data.data.data,
                            isLoading: false,
                            upcomingUrl: data.data.next_page_url,
                            upcomingUrlPrevious: data.data.prev_page_url
                        })
                    } else {
                        this.setState({ isLoading: false })
                        toast.success('Oops!!!... There is no Internet Connection', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                })
        }
    }

    upcomingSessionprevious = async () => {
        this.setState({ upcomingPrevious: true })
        if (this.state.upcomingUrlPrevious != null) {
            await fetch(this.state.upcomingUrlPrevious, {
                method: 'GET'
            })
                .then(Response => Response.json())
                .then(data => {
                    if (data.success === true) {
                        this.setState({ upcomingPrevious: false })
                        this.setState({
                            getUpcomingSession: data.data.data,
                            isLoading: false,
                            upcomingUrl: data.data.next_page_url,
                            upcomingUrlPrevious: data.data.prev_page_url
                        })
                    } else {
                        this.setState({ isLoading: false })
                        toast.success('Oops!!!... There is no Internet Connection', {
                            position: 'top-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }

                })
        }
    }


    getHomework = async (value) => {
        this.setState({
            isLoading: true,
            modalshow: true
        })

        var meetingId = value;
        this.setState({
            meetingId: value
        })

        await fetch(Constant.homeworkTutor + '/' + meetingId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        rightAnswer: data.data[0].right_answer,
                        total_question: data.data[0].totalQue.length
                    })

                    var studentAnswer = data.data[0].student_answer;
                    var correctAnswer = data.data[0].totalQue;

                    const jsonObject = JSON.parse(studentAnswer);

                    const keyValueArray = Object.entries(jsonObject).map(([key, value]) => ({
                        key: key,
                        value: value
                    }));

                    var count = [];

                    for (let index = 0; index < this.state.total_question; index++) {
                        if (correctAnswer[index].id == keyValueArray[index].key) {
                            if (correctAnswer[index].answer === keyValueArray[index].value) {
                                count.push('right');
                            } else {
                                count.push('wrong');
                            }
                        }
                    }

                    this.setState({
                        AnswerArray: count
                    })


                } else {
                    console.log('not found')
                }
            })
    }

    onCloseSession = async (value) => {
        this.setState({ isLoading: true })

        await fetch(Constant.onCloseSession + '/' + value, {
            method: 'POST'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false
                    })
                    window.location.reload();
                }
            })
    }

    getCompletedSession = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId')

        await fetch(Constant.getTutorCompSession + '/' + userId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                // console.log('file checking', data.data)
                if (data.success === true) {
                    this.setState({
                        completedSession: data.data,
                        isLoading: false
                    })
                } else {
                    this.setState({ isLoading: false })
                    toast.error('Oops!!!... There is no Internet Connection', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
    }

    saveId = async (value, meetingId) => {
        localStorage.setItem('studentId', value)
        localStorage.setItem('meetingId', meetingId)
    }
    saveMeetingId = async (value) => {
        localStorage.setItem('meetingId', value)
    }

    getReportTutor = async () => {
        this.setState({
            isLoading: true
        })

        var tutorId = localStorage.getItem('studentId')
        var userId = localStorage.getItem('userId')
        console.log('-----------', userId)
        console.log('-----------', tutorId)

        await fetch(Constant.sessionReportTutor + '?user_id=' + userId + '&tutor_id=' + tutorId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    if (data.data.length !== 0) {
                        this.setState({
                            feedbackSubmitted: true
                        })
                    } else {
                        this.setState({
                            feedbackSubmitted: false
                        })
                    }
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
    }

    getLastSession = async () => {

        var tutorId = localStorage.getItem('userId')

        await fetch(Constant.getLastSession + '?tutor_id=' + tutorId, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        is_feedback_submited: data.data.feedback_submited
                    })
                } else {
                    console.log('not Found')
                }
            })
    }

    uploadFile = async (e, id) => {

        var homeworkFile = e.target.files[0];

        const formData = new FormData();
        formData.append("homework_file", homeworkFile);

        await fetch(Constant.homeWorkFile + '/' + id, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    toast.success("Homework Uploaded Successfully", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    console.log('file Not Uploaded');
                }
            })
    }

    render() {
        var userId = localStorage.getItem("userId");
        if (userId) {
            return (
                <>
                    {
                        this.state.is_approved === 0 ? <>
                            <div className="alert alert-warning" style={{ textAlign: 'center' }} role="alert">
                                Your profile is under review.
                            </div></> : <></>
                    }
                    <section id="feature" className="atf-section-padding tutor-page" style={{ minHeight: '1080px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-xl-12">
                                    <div className="tabbing-content-outer">
                                        <ul className="nav nav-tabs" role="tablist" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <li className="nav-item my-booking">
                                                <a className={this.state.tabone === true ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={() => this.ShowTab('tabone')} href="javascript:void(0)" role="tab">My Bookings</a>
                                            </li>
                                            <li className="nav-item start-session">
                                                <a className={this.state.tabtwo === true ? "nav-link active" : "nav-link"} data-toggle="tab" onClick={() => this.ShowTab('tabtwo')} href="javascript:void(0)" role="tab">Progress Reports</a>
                                            </li>
                                        </ul>

                                        {
                                            this.state.tabone === true ?
                                                <div>
                                                    <div className="tab-content  mb-3">
                                                        <div className="tab-pane active tutor_table" role="tabpanel">
                                                            <h4>Today's Session</h4>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Student Name</th>
                                                                        <th>Date</th>
                                                                        <th>Time India</th>
                                                                        <th>Topic</th>
                                                                        <th>Session Status</th>
                                                                        <th>Session Link</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    this.state.getSession.length === 0 ? <tbody className="mainBody"><tr scope="row" style={{ textAlign: 'center' }}><td colSpan={6}>Not Active Sessions Right Now</td></tr></tbody> : <tbody className="mainBody">
                                                                        {
                                                                            this.state.getSession.map((item, i) => (
                                                                                <tr key={i}>
                                                                                    <td>{item.user.first_name}&nbsp;{item.user.last_name}</td>
                                                                                    <td>{moment(item.from_date_and_time).format('DD-MM-YYYY')}</td>
                                                                                    <td className="time-zone-india">{moment(item.from_date_and_time).format('hh:mm')}</td>
                                                                                    <td>{item.subject.title}</td>
                                                                                    <td style={{ padding: '20px' }}><a className="join_now" style={{ cursor: 'pointer' }} onClick={() => this.onCloseSession(item.id)}>Close Session</a></td>
                                                                                    {
                                                                                        this.state.is_feedback_submited === 0 ? <td>-</td> : <td style={{ padding: '20px' }}><a target="_blank"
                                                                                            rel="noreferrer"
                                                                                            className="join_now"
                                                                                            href={item.session_link}>Join Now</a></td>
                                                                                    }
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div>
                                                    {/* <div className="tab-content  mb-3">
                                                        <div className="tab-pane active tutor_table" role="tabpanel">
                                                            <h4>Tomorrow's Session</h4>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Student Name</th>
                                                                        <th>Date</th>
                                                                        <th>Time India</th>
                                                                        <th>Topic</th>
                                                                        <th>Session Status</th>
                                                                        <th>Session Link</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    this.state.getTomorrowSession.length === 0 ? <tbody className="mainBody"><tr scope="row" style={{ textAlign: 'center' }}><td colSpan={6}>Not Active Sessions Right Now</td></tr></tbody> : <tbody className="mainBody">

                                                                        {
                                                                            this.state.getTomorrowSession.map((item, i) => (
                                                                                <tr key={i}>
                                                                                    <td>{item.user.first_name}&nbsp;{item.user.last_name}</td>
                                                                                    <td>{moment(item.from_date_and_time).format('DD-MM-YYYY')}</td>
                                                                                    <td className="time-zone-india">{moment(item.from_date_and_time).format('hh:mm')}</td>
                                                                                    <td>{item.subject.title}</td>
                                                                                    <td style={{ color: '#000' }}><a href="#" onClick={() => this.onCloseSession(item.id)}>Close Session</a></td>
                                                                                    {
                                                                                        this.state.is_feedback_submited === 0 ? <td>-</td> : <td style={{ padding: '20px' }}><a target="_blank"
                                                                                            rel="noreferrer"
                                                                                            className="join_now"
                                                                                            href={item.session_link}>Join Now</a></td>
                                                                                    }
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                }
                                                            </table>
                                                        </div>
                                                    </div> */}
                                                    <div className="tab-content  mb-3">
                                                        <div className="tab-pane active tutor_table" role="tabpanel">
                                                            <h4>Upcoming Session</h4>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Student Name</th>
                                                                        <th>Date</th>
                                                                        <th>Time India</th>
                                                                        <th>Topic</th>
                                                                        <th>Session Status</th>
                                                                        <th>Session Link</th>
                                                                    </tr>
                                                                </thead>
                                                                {
                                                                    this.state.getUpcomingSession.length === 0 ? <tbody className="mainBody"><tr scope="row" style={{ textAlign: 'center' }}><td colSpan={6}>Not Active Sessions Right Now</td></tr></tbody> : <tbody className="mainBody">

                                                                        {
                                                                            this.state.getUpcomingSession.map((item, i) => (
                                                                                <tr key={i}>
                                                                                    <td>{item.user.first_name}&nbsp;{item.user.last_name}</td>
                                                                                    <td>{moment(item.from_date_and_time).format('DD-MM-YYYY')}</td>
                                                                                    <td className="time-zone-india">{moment(item.from_date_and_time).format('hh:mm')}</td>
                                                                                    <td>{item.subject.title}</td>
                                                                                    <td style={{ color: '#000' }}><a href="#" onClick={() => this.onCloseSession(item.id)}>Close Session</a></td>

                                                                                    {
                                                                                        this.state.is_feedback_submited === 0 ? <td>-</td> : <td className="btn_padding"><a target="_blank"
                                                                                            rel="noreferrer"
                                                                                            className="join_now"
                                                                                            href={item.session_link}>Join Now</a></td>
                                                                                    }


                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                }
                                                            </table>

                                                            <div className="row">
                                                                {
                                                                    this.state.upcomingUrlPrevious === null ? '' :
                                                                        <div className="col-md-6 col-lg-6 col-sm-12 ">
                                                                            {
                                                                                this.state.upcomingPrevious === true ? <Button className="load_more"><SpinnerCircular size="30" /></Button> : <Button className="load_more" onClick={() => this.upcomingSessionprevious()} style={{ width: '115px' }}><i class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Previous</Button>
                                                                            }

                                                                        </div>
                                                                }
                                                                {
                                                                    this.state.upcomingUrl === null ? '' :
                                                                        <div className="col-md-6 col-lg-6 col-sm-12 ">
                                                                            {
                                                                                this.state.isLoadMoreUpcoming === true ? <Button className="load_more" style={{ float: 'right', width: '115px' }}><SpinnerCircular size="30" /></Button> : <Button className="load_more" onClick={() => this.upComingSessionchange()} style={{ float: 'right', width: '115px' }}>Next<i class="fa fa-arrow-right ml-2" aria-hidden="true"></i></Button>
                                                                            }

                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ''
                                        }
                                        {
                                            this.state.tabtwo === true ?
                                                <div className="tab-content  mb-3">
                                                    <div className="tab-pane active" role="tabpanel">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Student Name</th>
                                                                    <th>Date</th>
                                                                    <th>Time India</th>
                                                                    <th>Topic</th>
                                                                    <th>Session'S Report</th>
                                                                    <th>Feedback</th>
                                                                    <th>Upload Pdf</th>
                                                                    <th>Show HomeWork</th>
                                                                    {/* <th>Learning Resources</th> */}
                                                                    <th>Actions</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="mainBody">
                                                                {
                                                                    this.state.completedSession.map((item, i) => (
                                                                        <tr key={i}>
                                                                            <td>{item.user.first_name}&nbsp;{item.user.last_name}</td>
                                                                            <td>{moment(item.from_date_and_time).format('DD-MM-YYYY')}</td>
                                                                            <td className="time-zone-india">{moment(item.from_date_and_time).format('hh:mm')}</td>
                                                                            <td className="time-zone-india">{item.subject.title}</td>
                                                                            <td><NavLink to="/report-data" onClick={() => this.saveId(item.user.id)} style={{ color: '#8A195F' }}> Link To Report</NavLink></td>
                                                                            {
                                                                                item.feedback_submited === 1 ? <td>-</td> : <td style={{ color: '#88185D' }}><NavLink to="/tutor-feedback" onClick={() => this.saveId(item.user.id, item.id)}  >Give FeedBack</NavLink></td>
                                                                            }
                                                                            <td style={{ color: '#88185D', cursor: 'pointer' }} ><input type="file" onChange={(e) => this.uploadFile(e, item.id)}></input></td>
                                                                            {
                                                                                item.student_file !== null ? <td className="no_border" style={{ color: '#88185D' }}><a href={item.student_file} target="_blank" download>Download File</a></td> : <td>-</td>
                                                                            }
                                                                            {/* {
                                                                                item.is_homeowrk_submit === 0 ? <td style={{ color: '#8A195F' }}><NavLink to="/upload-homework-tutor" onClick={() => this.saveMeetingId(item.id)}>Upload Homework</NavLink></td> : <td style={{ color: '#8A195F', cursor: 'pointer' }} onClick={() => this.getHomework(item.id)}>View Homework</td>
                                                                            } */}
                                                                            <td style={{ color: '#000' }}></td>
                                                                            <td>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }

                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div> : ''
                                        }
                                        <Modal show={this.state.modalshow} onHide={this.handleClose}>
                                            <Modal.Header >
                                                <div className="container">
                                                    <div className="row" >
                                                        <div className="col-sm-12 col-md-10">
                                                            <h3 style={{ fontSize: 25, color: '#fff' }} className="text-center mb-2">Show Student Answer</h3>
                                                        </div>
                                                        <div className="col-sm-12 col-md-2">
                                                            <button type="button" className="cross-sign" onClick={this.handleClose}> <ImCross /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        {
                                                            this.state.is_submited === 0 ? <div></div> :
                                                                <div>
                                                                    <h4>Total Right Answer : {this.state.rightAnswer} / {this.state.total_question}</h4>
                                                                </div>
                                                        }
                                                        {
                                                            this.state.AnswerArray.map((item, i) => (
                                                                <h4>Question : {i + 1} {item == 'wrong' ? <span style={{ color: 'red', fontSize: 23, marginLeft: 16 }}><ImCross /></span> : <span><FaCheck style={{ color: 'green', fontSize: 30, marginLeft: 10 }} /></span>}</h4>
                                                            ))
                                                        }

                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button className="closeButton" onClick={this.handleClose}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </>
            )
        } else {
            return <Navigate to="/" />;
        }

    }
}

export default TutorDashboard