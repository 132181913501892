import React from "react";

import thankone from '../assets/img/thankone.png'
import thanktwo from '../assets/img/thanktwo.png'
import thankthree from '../assets/img/thankthree.png'
import thankfour from '../assets/img/thankfour.png'

class ThankyouFeedback extends React.Component {
    render() {
        return (
            <>
                <section id="thank-you">
                    <div class="container">
                        <div class="thank-you-outer">
                            <div class="row">

                                <div class="col-md-4">
                                    <div class="thank-img">
                                        <img src={thankone} alt="thankyou-1" />

                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="thank-img-2">
                                        <span><img src={thanktwo} alt="thankyou-1" /></span>
                                        <img src={thankthree} alt="thankyou-1" />
                                        <p>Your Feedback is submitted.</p>

                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="thank-img">
                                        <img src={thankfour} alt="thankyou-1" />

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


export default ThankyouFeedback;