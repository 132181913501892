import React, { useEffect } from 'react';

const loadFbSdk = (callback) => {
    ((d, s, id) => {
        let js;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { callback(); return; }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
        js.onload = callback;
    })(document, 'script', 'facebook-jssdk');
};

const FacebookLogin = () => {
    useEffect(() => {
        loadFbSdk(() => {
            window.FB.init({
                appId: '1091832888586707',
                cookie: true,
                xfbml: true,
                version: 'v12.0', // Ensure this is the correct version
            });
            window.FB.AppEvents.logPageView();
        });
    }, []);

    const handleLogin = () => {
        window.FB.login((response) => {
            if (response.authResponse) {
                console.log('Welcome! Fetching your information.... ');
                window.FB.api('/me', (response) => {
                    console.log('Good to see you, ' + response.name + '.');
                });
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        });
    };

    return (
        <div className="col-md-6 ">
            <div className="form-group" >
                <input type="submit" style={{marginTop:0}} className="btnRegister" onClick={handleLogin} value="Login with Facebook" />
            </div>
        </div >
    );
};

export default FacebookLogin;
