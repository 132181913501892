import React from "react";
import { Navigate } from 'react-router-dom'

import * as Constant from './Constant'

class TutorFeedback extends React.Component {
    constructor() {
        super();

        this.state = {
            isLoading: false,
            attractive: '',
            progress: '',
            technical_comments: '',
            red_flag: '',
            resources: ''
        }
    }

    onSubmit = async () => {
        this.setState({ isLoading: true })

        var userId = localStorage.getItem('userId')
        var studentId = localStorage.getItem('studentId')
        var meetingId = localStorage.getItem('meetingId')

        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("tutor_id", studentId);
        formData.append("meetingId", meetingId);
        formData.append("progress", this.state.progress);
        formData.append("attractive", this.state.attractive);
        formData.append("technical_comments", this.state.technical_comments);
        formData.append("red_flag", this.state.red_flag);
        formData.append("resources", this.state.resources);

        await fetch(Constant.tutorFeedback, {
            method: 'POST',
            body: formData
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false
                    })
                    window.location.href = Constant.BaseUrl + 'tutor-dashboard'
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            })

    }

    studentEngagement = (value) => {
        if (value === 'attentive') {
            this.setState({
                attractive: 1
            })
        } else {
            this.setState({
                attractive: 0
            })
        }
    }

    render() {
        var userId = localStorage.getItem('userId')
        if (userId) {
            return (
                <>
                    <section id="feature" class="atf-section-padding tutor-page student-feedback">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                        <div class="student-feedback-page">
                                            <h1 className="colorBlack">Tutor Feedback Form</h1>

                                            <div class="student-feedback-form-outer tutor-feedback-form">
                                                <div class="app">
                                                    <h4>Student’s Engagement </h4>
                                                    <div class="container">
                                                        <div class="checkbox-outer">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" name="attractive"
                                                                    value="1" onChange={() => this.studentEngagement('attentive')} />
                                                                <label class="form-check-label" for="flexRadioDefault1">Attentive</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio" name="attractive" onChange={() => this.studentEngagement('inattentive')} value="0" />
                                                                <label class="form-check-label" for="flexRadioDefault2">In-attentive</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="app">
                                                    <h4>Progress Made in Session</h4>
                                                    <div class="container">
                                                        <div class="rating-text-area"> <textarea id="w3review" name="progress" rows="4" cols="55" onChange={(event) => this.setState({
                                                            progress: event.target.value
                                                        })} placeholder="Message:- "></textarea></div>
                                                    </div>
                                                </div>

                                                <div class="app">
                                                    <h4>Technical Comments (if any)  </h4>
                                                    <div class="container">
                                                        <div class="rating-text-area"> <textarea id="w3review" name="technical_comments" rows="4" cols="55" onChange={(event) => this.setState
                                                            ({
                                                                technical_comments: event.target.value
                                                            })} placeholder="Message:- "></textarea></div>
                                                    </div>
                                                </div>

                                                <div class="app">
                                                    <h4>Red flag (if any)  </h4>
                                                    <div class="container">
                                                        <div class="rating-text-area"> <textarea id="w3review" onChange={(event) => this.setState({
                                                            red_flag: event.target.value
                                                        })} name="red_flag" rows="4" cols="55" placeholder="Message:- "></textarea></div>
                                                    </div>

                                                </div>
                                                <div class="app upload-resource">
                                                    <div class="container"><h4>Upload Resources</h4>
                                                        <input type="file" id="img" onChange={(event) => this.setState({
                                                            resources: event.target.files[0].name
                                                        })} name="resources" /></div>
                                                </div>
                                                <button type="submit" class="btn btn-primary" onClick={() => this.onSubmit()} data-dismiss="modal" aria-label="Close">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
        } else {
            return <Navigate to="/" />;
        }

    }
}

export default TutorFeedback