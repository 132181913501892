import React from "react";

class TermsAndCondition extends React.Component {
    render() {
        return (
            <>
                <section className="terms-condation">
                    <div className="container">
                        <div className="row m-5">
                            <div className="col-md-12">
                                <div className="card m-b-20">
                                    <div className="card-header">
                                        <h1 className="card-title">Terms & Conditions</h1>
                                    </div>
                                    <div className="card-body terms">
                                        <p >• These Terms and Conditions apply to all users of the Education Capsule Platform, including instructors who are also benefactors of User Content on the Education Capsule Platform. The Education Capsule Platform includes all parts of the Website and Application but is not limited to the elements, schedules and services offered through the Education Capsule Platform, for example, the Education Capsule online stage.</p>

                                        <p>• The Education Capsule Platform is an online website instructional exercises by the Users of the Education Capsule Platform and is an intermediary between the teacher and the User. The User may make a film, audio recording or an instructional exercise on the Education Capsule Platform. Such content provided via the Education Capsule Platform is hereinafter referred to as "Customer Content". You agree and acknowledge that Education Capsule has no authority or accepts responsibility for User Content, and by using the Education Capsule Platform, you expressly release Education Capsule from any risk arising from User Content.</p>

                                        <p>• The Education Capsule Platform may include or link to any external site to which Education Capsule may claim or limit a claim. Education Capsule has no influence on or responsibility for the content, security arrangements, or practices of external sites. In addition, Education Capsule cannot and may not change the content of any external sites. By using the Education Capsule Platform, you expressly release Education Capsule from any liability arising from the use of any third-party site or administration. In any case, we suggest that you carefully read and understand the agreements and protection strategies of each website you visit before using any website or third-party services.</p>

                                        <p>• Subject to these Terms, the Privacy Policy, and other various guidelines and approaches available or disseminated elsewhere, Education Capsule grants you limited, non-adaptive, non-sublicensable and non-elite permission to use the Education Capsule Platform under these Terms and Conditions.</p>

                                        <p>• You agree and acknowledge that Education Capsule will be entitled whenever any perspective or element of the Education Capsule Platform changes or ceases, including, but not limited to, User Content, long periods of availability and equipment required to access or use. In addition, Education Capsule may end up broadcasting any data or data class that may change or forgo any transmission strategy and may change the transmission rate or other signal properties. Education Capsule reserves the full right to refuse access to the Education Capsule Platform, close Accounts, delete or change content without notifying the user.</p>

                                        <h3 className="left-class">Permissions and Restrictions</h3>

                                        <p>Accordingly, Education Capsule authorizes you to access and use the Education Capsule Platform in accordance with these Terms, provided that:</p>

                                        <p>• You enter into an agreement to avoid the misappropriation of any part of the Education Capsule Platform or substance in any medium without the prior consent of the Education Capsule.</p>

                                        <p>• You enter into an agreement to avoid changing or adapting any part of the Education Capsule platform.</p>

                                        <p>• Contracts to avoid access to any other User content through any innovation or means other than video playback pages on the Education Capsule Platform.</p>

                                        <p>• You enter into an agreement to avoid using the Education Capsule Platform for any of its accompanying commercial uses unless you obtain an endorsement that previously consisted of Education Capsule:</p>

                                        <p className="text">• an offer of admission to the Education Capsule Platform;</p>
                                        <p className="text-align">• offers of advertisements, sponsorships or trailers posted on or within the Platform or Education Capsule content; or</p>
                                        <p className="text-align">• an offer of advertisements, sponsorships or trailers on any page or site that offers the Education Capsule platform as that of Education Capsule.</p>


                                        <p style={{ textAlign: 'justify' }}>• You agree to receive submissions and updates from Education Capsule from time to time. These updates are designed to further improve, update and promote the Education Capsule platform, and may seem like a solution with bug fixes, improved capabilities, new development modules, and completely new ways. You agree to receive such updates and grant Education Capsule to send them to you as part of your use of the Education Capsule Platform.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


export default TermsAndCondition

