import React from "react";

import homework from '../../assets/img/services/new/homework.png'
import homework_help from '../../assets/img/services/new/homework_help.png'
import computing from '../../assets/img/newhomepage/computing.jpg'
import maths from '../../assets/img/newhomepage/maths.jpg'
import eng from '../../assets/img/newhomepage/eng.jpg'
import science from '../../assets/img/newhomepage/science.jpg'

import unique_learner from '../../assets/img/services/new/unique_learners.png'
import step_by_step from '../../assets/img/services/new/step_by_step.png'
import qualification from '../../assets/img/services/new/qualification.png'
import progress from '../../assets/img/services/new/progress.png'
import video from '../../assets/img/services/new/video.png'
import quality from '../../assets/img/services/new/quality.png'
import round_clock from '../../assets/img/services/new/round_clock.png'


class HomeWorkHelp extends React.Component {
    render() {
        return (
            <>
                <div style={{ marginTop: '-1px' }}>
                    <section className="service-page-new" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-12">
                                    <h1 className="banner-text1" style={{ textAlign: 'left', marginTop: 60, fontSize: 55 }}>Are you worried<br></br> about your child’s<br></br> homework?</h1>
                                    <p className="mt-3" style={{ color: '#fff', textAlign: 'justify', fontSize: 20 }}>Don’t worry at all and get in touch with us now. It’s<br></br> time to get homework help at affordable prices. </p>
                                    <a href="/book-a-tutor" className="lending-btn" >Book free Lesson Now</a>

                                </div>
                                <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12">
                                    <img src={homework} className="p-2" alt="homework" />
                                </div>
                            </div>
                        </div>
                    </section >

                    <section className="sptb" style={{ marginTop: '-265px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xs-12">
                                    <p class="textjustify block_font" >Children need help with their homework. Parents may find it difficult to assist their children with their schoolwork due to their busy schedules. Education Capsule provides homework help services that help students to complete their homework and obtain step-by-step solutions instantly from our expert tutors.
                                    </p>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-md-9 col-xl-9 col-lg-9 col-sm-12 col-xs-12 mt-4">
                                    <p class="textjustify block_font" > We provide online homework help services to students from year 1 to year 11 including the one-to-one tutoring session in which the students can learn effectively. The students learn about the topics and then they go through a series of questions that assess their understanding level of the student. We are here to offer quality homework tuition to all students. We always offer the best services with complete confidentiality. You can now make your school life easier by choosing us, the best online homework help service for your child.</p>
                                </div>
                                <div className="col-md-3 col-xl-3 col-lg-3 col-sm-12 col-xs-12">
                                    <img src={homework_help} className="homework-help-img" alt="Homework Help" style={{ marginTop: '-100px' }} />
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-sm-12 col-md-12 col-xl-12 col-xs-12 col-lg-12">
                                    <p class="textjustify block_font " >When you opt for our services, your homework on different subjects will be handled by our professional writers. With our homework help online tuition, you can rely upon our tutors who are experts in their subject field. Education Capsule's exceptional online tutoring has been shown to help children succeed in their studies. We have been helping students for years in developing their confidence as well as skills. But what if they don’t have sufficient time to hone their skills? Nowadays, parents are generally busy with their work. As a result, they cannot pay much attention to the studies of their child </p>
                                </div>
                            </div>

                            <div className="col-12 mt-5">
                                <h2 className="largefont">Get The Best Homework Help in Different Subjects <br></br>From Our Experienced Tutors </h2>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12">
                                    <p class="textjustify block_font" >Education Capsule provides the best homework help in different subjects. At Education Capsule, we believe that every student is different and has their own learning style, preference, and pace. Some students do not feel comfortable asking questions in a class full of students. In Education Capsule, we offer one-on-one personalized and adaptive sessions for students from Key Stage 1 to 3 to help prepare their curriculum. The tutors in Education Capsule are masters in their subject field and are committed to offering creative and flexible lessons from the curriculum, specifically in the subjects- of Science, Maths, and Computing with exceptional preparation in Key Stage 1, Key Stage 2, and Key Stage 3. Education Capsule can help you with different subjects, like:</p>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12" style={{ padding: 22 }}>
                                    <img src={maths} alt="Maths" className="subimg" />
                                    <h3 style={{ fontSize: 40, textAlign: 'center' }}>Mathematics</h3>
                                    <p className="mt-3 textjustify block_font" >Education Capsule is an online platform that provides one-to-one customized sessions to learners across the UK. We help students of Key Stage 1, Key Stage 2, Key Stage 3, 7+, 11+, 13+ SATS, GCSE, and many more by providing Homework Help online tuition. Our personalized and adaptive one-to-one tutoring is trusted and recommended by hundreds of parents and teachers across the UK.</p>
                                </div>
                                <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12" style={{ padding: 22 }}>
                                    <img src={eng} alt="Science" className="subimg" />
                                    <h3 style={{ fontSize: 40, textAlign: 'center' }}>English</h3>
                                    <p className="mt-3 textjustify block_font" > English is the official language of more than 50 nations. According to recent studies, there are currently 1.35 billion English speakers in the world. This is 17% of the world’s population. Education Capsule provides everything a learner needs to become a confident and fluent English language speaker. We provide tailor-made courses for English Language learning which include Grammar (Basic and Intermediate), Reading Skills, Speaking Skills, Listening Skills, and Writing Skills.</p>
                                </div>
                                <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12" style={{ padding: 22 }}>
                                    <img src={computing} alt="Computing" className="subimg" />
                                    <h3 style={{ fontSize: 40, textAlign: 'center' }}>Computing</h3>
                                    <p className="mt-3 textjustify block_font" >Computing is an important part of the UK National Curriculum. Children who learn to code and who learn about computer science can become independent adults in a technology-rich society. We help students of Key Stage 1, Key Stage 2, Key Stage 3, GCSE, and many more with Computing Homework. Our broader and more informative program aims to offer the children opportunities to curtail themselves academically for attaining their personal best.
                                    </p>
                                </div>
                                <div className="col-md-3 col-lg-3 col-xl-3 col-sm-12 col-xs-12" style={{ padding: 22 }}>
                                    <img src={science} alt="Science" className="subimg" />
                                    <h3 style={{ fontSize: 40, textAlign: 'center' }}>Science</h3>
                                    <p className="mt-3 textjustify block_font" >Science is an informative subject, and it is also very complex and in-depth. Science is one of the basic curriculum disciplines that employers and future educational institutions will use as part of their skills evaluation, along with English and Math. We have a group of Science experts who are qualified enough to make learning fun and effective for kids.
                                    </p>
                                </div>
                            </div>
                            <div className="row mb-4" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 lending-btn">
                                    <a href="/book-a-tutor">Book free Lesson Now</a>
                                </div>
                            </div>
                            <div className="col-12 mt-5">
                                <h2 className="largefont">Homework Online Tuitions-<h2>Help on All-Academic Levels</h2>  </h2>
                                <p className="mt-2" style={{ fontSize: 18, textAlign: 'center' }}>Education Capsule provides tutors for homework help services online</p>
                                <h3 className="mt-4" style={{ fontSize: 38, fontWeight: 'bold', textAlign: 'center', fontFamily: 'Times New Roman Times serif' }} >Some Features That Make Us Different from Others</h3>
                                <p className="mt-4" style={{ fontSize: 15, textAlign: 'center' }}>Are you still feeling confused about whether you should go for our online homework help or not?<br></br>Well, here are some features that make us unique and popular among the students.
                                </p>
                            </div>

                            <div className="row">
                                <div className="col-md-3 col-xl-3 col-lg-3 col-sm-12 col-xs-12 text-center" style={{ padding: 22 }}>
                                    <img src={unique_learner} className="w-75" alt="unique learner" />
                                    <h3>Unique for<br></br> every learner</h3>
                                    <p class="textjustify block_font" >We understand that every learner is different and so is the way they learn. We assess your child’s needs and style of learning and personalize the learning journey.
                                        . </p>
                                </div>
                                <div className="col-md-3 col-xl-3 col-lg-3 col-sm-12 col-xs-12 text-center" style={{ padding: 22 }}>
                                    <img src={step_by_step} className="w-75" alt="step by step" />
                                    <h3>Step by step<br></br> solutions</h3>
                                    <p class="textjustify block_font" >Our primary focus is to make the student understand every concept behind every question and to solve the issues that the students experience in the learning process.</p>
                                </div>
                                <div className="col-md-3 col-xl-3 col-lg-3 col-sm-12 col-xs-12 text-center" style={{ padding: 22 }}>
                                    <img src={qualification} className="w-75" alt="qulification" />
                                    <h3>Highly<br></br> Qualified experts</h3>
                                    <p class="textjustify block_font" >We have a group of experts who are qualified enough to make learning fun and effective for kids. The sessions are based on two-way communication using editable screens where the learning happens in a fun and engaging manner.
                                    </p>
                                </div>
                                <div className="col-md-3 col-xl-3 col-lg-3 col-sm-12 col-xs-12 text-center" style={{ padding: 22 }}>
                                    <img src={progress} className="w-75" alt="progress" />
                                    <h3>Proven to build<br></br> up progress </h3>
                                    <p class="textjustify block_font">Our online one on one personalized sessions and online homework help sessions have helped the students in boosting their progress to an extraordinary level. In an independent trial, the students made 6 months of growth and progress in less than 14 weeks. </p>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-1 col-lg-1 col-xl-1 col-xs-12 col-sm-12"></div>
                                <div className="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12 text-center" style={{ padding: 22 }} >
                                    <img src={video} className="w-75" alt="video" />
                                    <h3>Interactive<br></br> Conceptual Videos</h3>
                                    <p class="textjustify block_font">We use a combination of images, text, sound, animations, and speech which easily attracts the attention of learners.</p>
                                </div>
                                <div className="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12 text-center" style={{ padding: 22 }}>
                                    <img src={quality} className="w-75" alt="quality" />
                                    <h3>High-Quality <br></br> assurance</h3>
                                    <p class="textjustify block_font">We guarantee to offer the best quality assistance that won't just bring about higher scores and will improve the understanding of the topic.</p>
                                </div>
                                <div className="col-md-3 col-lg-3 col-xl-3 col-xs-12 col-sm-12 text-center" style={{ padding: 22 }}>
                                    <img src={round_clock} className="w-75" alt="round clock" />
                                    <h3>Round the <br></br> clock support</h3>
                                    <p class="textjustify block_font">Our tutors are available 24*7 for dealing with problems that will be faced by the students at the least possible cost.</p>
                                </div>
                                <div className="col-md-1 col-lg-1 col-xl-1 col-xs-12 col-sm-12"></div>
                            </div>

                        </div>
                        <div className="row mb-4" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 lending-btn">
                                <a href="/book-a-tutor" >Book free Lesson Now</a>
                            </div>
                        </div>
                    </section >
                </div >
            </>

        )
    }
}

export default HomeWorkHelp