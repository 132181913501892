import React from "react";


class PrivacyPolicy extends React.Component {
    render() {
        return (
            <>
                <section className="privacy-policy">
                    <div className="container">
                        <div className="row m-5">
                            <div className="col-md-12">
                                <div className="card m-b-20">
                                    <div className="card-header privacy-policy-page">
                                        <h1 className="card-title">Privacy Policy</h1>
                                    </div>
                                    <div className="card-body terms">
                                        <p>
                                            All information processed by us is stored securely (the degree of security is consistent with the idea of the information and other important conditions).</p>

                                        <p>We use several authorized and specialized security measures to protect your data and ensure that it is not accidentally lost, used, or accessed in an unapproved manner.</p>

                                        <p>The password shared by us with you for login must be kept securely as you are responsible for keeping that password hidden.</p>

                                        <p>We ask you not to share your password with anyone and to use the appropriate password (for example, a combination of uppercase and lowercase letters and characters that are not used in different locations and are not easily speculated).</p>

                                        <p>Schools are responsible for the recruitment of their staff and giving them access to the platform and online learning centre.</p>

                                        <p>It is the responsibility of the School to ensure that primary approved persons refer to any information.</p>

                                        <p>We cannot be held responsible for unapproved access caused by the school’s failure.</p>

                                        <p>Unfortunately, the transmission of data over the network is not completely secure. While we do our best to secure your information, we cannot guarantee the security of your information transmitted to our site, and any transmission is at your own risk.</p>

                                        <p>As long as we accept your information, we will apply appropriate security policies and regulations to prevent unauthorized access.</p>

                                        <h5 style={{ fontWeight: 'bold' }}>With Whom we share your Personal Data</h5>
                                        <p style={{ fontWeight: 'bold' }}>Everyone in our meeting in which our assistants, our holding organization, and their assistants participate.</p>

                                        <p>• Suitable outsiders including: our colleagues, customers, suppliers, and sub-project employees to present any contracts we have entered into or various transactions that we conduct in the normal course of business with you or the person you work for.</p>

                                        <p>• Specialized third-party cooperatives that provide us with site and application enhancements, hosting, matching steps, warehousing, virtual foundations, quota management, research, and various services that may expect them to access or use data about you. If a specialized cooperative requires obtaining data about you to carry out administrative activities for our benefit, it does so by following our guidelines, including by arrangements and methods aimed at protecting your data.</p>

                                        <p>• Our examiners, legal consultants, and other expert advisers or specialized organizations.</p>

                                        <p>• Credit bureaus or other benchmarking reference offices to assess your suitability or ability to enter into (or propose to enter into) a contract with you or the person you work for.</p>

                                        <h5 style={{ fontWeight: 'bold' }}>According to the data obtained through our website:</h5>
                                        <p>• Our advertisers and ad networks require your information to select and show relevant ads to you and others. We do not disclose to our advertiser’s information about identifiable persons, but we will provide them with full information about our customers. We may also use this aggregate data to help advertisers reach their target audience. We may use the individual information we have collected from you to authorize us to agree to our sponsors’ wishes by showing your promotion to an intended interest group and subject to our reward strategy;</p>

                                        <p>• Online index and test providers that help us advance and improve our website are subject to our reward strategy.</p>

                                        <h5 style={{ fontWeight: 'bold' }}>Other Disclosure</h5>
                                        <p style={{ fontWeight: 'bold' }}>We may disclose your data to third parties:</p>

                                        <p>• If we sell or buy any business or asset, we may disclose your information to the prospective buyer or buyer of that business or asset subject to the provisions of this security policy.</p>

                                        <p>• If we or substantially all of our resources are acquired by a third party if the individual information you have about your customers will be one of the resources provided.</p>

                                        <p>• Where we are required to disclose or share your information to comply with any legal obligations or to implement or enforce our terms of delivery and various agreements with you or the person you work for; or to protect the rights, property, or well-being of our company. This includes business data with various organizations and associations to protect from fraud or reduce credit risk.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>

        )
    }
}

export default PrivacyPolicy