import React from "react";

import * as Constant from '../Constant'
import students from "../../assets/img/students.png";
import { NavLink, Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { HiHome } from 'react-icons/hi'
import { BiUserCircle } from 'react-icons/bi'
import { MdLogout } from 'react-icons/md'

class StudentProfile extends React.Component {

    constructor() {
        super();
        this.state = {
            firstname: '',
            lastname: '',
            email: '',
            contact_number: '',
            country: '',
            country_id: '',
            subject: '',
            timezone: '',
            role: '',
            isLoading: false,
            editProfile: false,
            studentName: '',
            CountryList: [],
            is_verified_phone: ''
        }
        this.handleChangeCountry = this.handleChangeCountry.bind(this);
    }

    editPersonal = () => {
        this.setState({
            editProfile: true,
        })
    }

    editStore = async () => {
        this.setState({
            editProfile: false,
            isLoading: true
        })

        var userId = await localStorage.getItem('userId')

        const formData = new FormData();
        formData.append("first_name", this.state.firstname);
        formData.append("last_name", this.state.lastname);
        formData.append("email", this.state.email);
        formData.append("contact_number", this.state.contact_number);
        formData.append("country_id", this.state.country_id);
        formData.append("user_id", userId);

        await fetch(Constant.editUserProfile, {
            method: 'POST',
            body: formData
        })
            .then(Response => Response.json())
            .then(data => {
                console.log(data)
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.getStudentProfile();
                    toast.success('Profile Updated successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    LogOut = () => {
        localStorage.clear();
        window.location.href = Constant.BaseUrl + "login"
    }

    componentDidMount = () => {
        this.getStudentProfile();
        this.getCountry()
    }

    getStudentProfile = async () => {
        this.setState({ isLoading: true })

        var userId = localStorage.getItem('userId');

        await fetch(Constant.getUserProfile + '/' + userId, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                this.setState({
                    firstname: data.data.first_name,
                    lastname: data.data.last_name,
                    email: data.data.email,
                    contact_number: data.data.contact_number,
                    country: data.data.country,
                    country_id: data.data.country_id,
                    subject: data.data.subject,
                    timezone: data.data.timezone,
                    role: data.data.role,
                    studentName: data.data.first_name + '  ' + data.data.last_name,
                    is_verified_phone: data.data.is_verified_phone
                });
            })
    }

    getCountry = async () => {
        await fetch(Constant.getCountry, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    if (data.success === true) {
                        this.setState({ CountryList: data.data })
                    } else {
                        console.log('Country not found')
                    }

                }
            })
    }

    handleChangeCountry(e) {
        console.log("country_id Selected!!", e.target.value);
        this.setState({ country_id: e.target.value });
        // console.log(this.state.country_id)
    }

    verifyPhone = () => {
        window.location.href = Constant.BaseUrl + 'otp'
    }

    render() {
        var userId = localStorage.getItem("userId");
        if (userId) {
            return (
                <>
                    <section id="loginnn">
                        <div className="main-content">
                            <h1 className="atf-single-details text-center text-light ">Student Profile</h1>
                        </div>

                        <div className="container">
                            <div className="bg-white shadow rounded-lg d-block d-sm-flex pb-4">
                                <div className="profile-tab-nav border-right" style={{ width: '330px' }}>
                                    <div className="p-4 profile_card">
                                    </div>
                                    <div className="img-circle text-center mb-3 " style={{ marginTop: '-62px' }}>
                                        <img src={students} className="brround profile-img" alt="user" id="imgupload" style={{ height: '100px' }} />

                                    </div>
                                    <div className="card-body item-user text-center mt-2">
                                        <div className="ml-1">
                                            <a href="javascrpt:void(0)" className="text-dark">
                                                <h4 className="mt-0 mb-2 font-weight-bold">{this.state.studentName}<i class="ion-checkmark-circled text-success"></i></h4>
                                            </a>
                                            <span className="mb-3">{this.state.role}</span><br></br>
                                        </div>
                                    </div>
                                    <ToastContainer />
                                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        <NavLink className="nav-link" to="/student-dashboard" style={{ color: '#000' }}>
                                            <HiHome style={{ fontSize: 18 }} />
                                            Dashboard
                                        </NavLink>
                                        <a className="nav-link active" style={{ backgroundColor: '#941C66', marginLeft: '5px', marginRight: '5px' }} id="account-tab" data-toggle="pill" href="javascript:void(0);" role="tab" aria-controls="account" aria-selected="true">
                                            <BiUserCircle style={{ marginLeft: -5, marginRight: 5 }} />
                                            My Profile
                                        </a>
                                        <a className="nav-link" onClick={() => this.LogOut()} style={{ color: '#000' }}>
                                            <MdLogout />Logout
                                        </a>
                                    </div>
                                </div>
                                <div className="tab-content p-4 p-md-5" style={{ width: '880px' }}>
                                    <div className="tab-pane fade show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                                        <h3 className="mb-4">My Profile</h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="heading-lable black">First Name</label>
                                                    <input className="form-control"
                                                        style={this.state.editProfile ?
                                                            { marginBottom: '0px', fontSize: '16px', marginLeft: '0px' } : { cursor: 'pointer', fontSize: '16px', marginBottom: '0px', marginLeft: '0px', border: 0, backgroundColor: '#D9DDDC' }}
                                                        value={this.state.firstname}
                                                        type="text"
                                                        onChange={(event) =>
                                                            this.setState({ firstname: event.target.value })
                                                        }
                                                        readOnly={!this.state.editProfile} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="heading-lable black">Last Name</label>
                                                    <input className="form-control" style={this.state.editProfile ?
                                                        { marginBottom: '0px', fontSize: '16px', marginLeft: '0px' } : { cursor: 'pointer', fontSize: '16px', marginBottom: '0px', marginLeft: '0px', border: 0, backgroundColor: '#D9DDDC' }}
                                                        value={this.state.lastname}
                                                        type="text"
                                                        onChange={(event) =>
                                                            this.setState({ lastname: event.target.value })
                                                        }
                                                        readOnly={!this.state.editProfile} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="heading-lable black">Email</label>
                                                    <input className="form-control" style={{ cursor: 'pointer', fontSize: '16px', marginBottom: '0px', marginLeft: '0px', border: 0, backgroundColor: '#D9DDDC' }}
                                                        value={this.state.email}
                                                        type="text" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="heading-lable black">Phone</label>
                                                    <input className="form-control" style={this.state.editProfile ?
                                                        { marginBottom: '0px', fontSize: '16px', marginLeft: '0px' } : { cursor: 'pointer', fontSize: '16px', marginBottom: '0px', marginLeft: '0px', border: 0, backgroundColor: '#D9DDDC' }}
                                                        value={this.state.contact_number}
                                                        type="text"
                                                        onChange={(event) =>
                                                            this.setState({ contact_number: event.target.value })
                                                        }
                                                        readOnly={!this.state.editProfile} />
                                                    {/* {
                                                        this.state.is_verified_phone == 0 ? <><p onClick={this.verifyPhone} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', float: 'right' }}>click here to verify phone number</p></> : ''
                                                    } */}
                                                </div>

                                            </div>
                                            {
                                                this.state.editProfile === true ? <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="heading-lable black">Country</label>
                                                        <select name="country_id" id="country_id" value={this.state.country_id} onChange={this.handleChangeCountry} className="form-control country_dropdown">
                                                            <option value="">Select Country</option>
                                                            {
                                                                this.state.CountryList.map((item, i) => (
                                                                    <option key={i} value={item.id} selected={item.id === this.state.country_id}>{item.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div> : <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="heading-lable black">Country</label>
                                                        <input className="form-control" style={{ cursor: 'pointer', fontSize: '16px', marginBottom: '0px', marginLeft: '0px', border: 0, backgroundColor: '#D9DDDC' }}
                                                            value={this.state.country}
                                                            type="text" />
                                                    </div>
                                                </div>
                                            }
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6"></div>
                                            <div className="col-md-6 mt-3">
                                                <div className="form-group">
                                                    {
                                                        this.state.editProfile === true ? <button type="button" className="btn edit-profile" onClick={() => this.editStore()}>Save Profile</button> : <button type="button" className="btn edit-profile" onClick={() => this.editPersonal()}>Edit Profile</button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
        } else {
            return <Navigate to="/" />;
        }

    }
}

export default StudentProfile