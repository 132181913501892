import React, { useRef, useEffect } from 'react';
import video_three from '../assets/videos/testimonialsfinal.mp4'
import poster3 from '../assets/img/newhomepage/testimonialsfinal.png'

const LazyVideo = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };

        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && !videoRef.current.paused) {
                    // Video is playing and becomes visible
                    videoRef.current.play(); // Play the video if it's not already playing
                } else if (!entry.isIntersecting && !videoRef.current.paused) {
                    // Video is playing and goes out of the viewport
                    videoRef.current.pause(); // Pause the video
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);
        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        // Clean up the observer when the component unmounts
        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };
    }, []);

    return (
        <video ref={videoRef} className="videowidthh" width="700" poster={poster3} controls>
            <source src={video_three} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
};

export default LazyVideo;
