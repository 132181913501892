import React from "react";
import * as Constant from '../Constant'

import LoadingOverlay from 'react-loading-overlay';


class BookOnlineExport extends React.Component {

  constructor() {
    super();
    this.state = {
      LevelList: [],
      isLoading: false,
      SubjectList: [],

      student_name: '',
      email: '',
      contact_number: '',
      subject_id: '',
      level_id: '',
      topic: '',

      student_nameError: null,
      emailError: null,
      contact_numberError: null,
      subject_idError: null,
      level_idError: null,
      topicError: null,

      captchaText: '',
      userInput: '',
      isCaptchaValid: false,
      captchError: null,
      num1: Math.floor(Math.random() * 10) + 1,
      num2: Math.floor(Math.random() * 10) + 1,
      answer: '',
      isCorret: ''
    };

    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeLevel = this.handleChangeLevel.bind(this);
  }

  handleChangeSubject = (e) => {
    console.log("subject_id Selected!!", e.target.value);
    this.setState({ subject_id: e.target.value });
    this.getLevel(e.target.value);
    // console.log(this.state.country_id)
  }

  handleChangeLevel = (e) => {
    console.log("level_id Selected!!", e.target.value);
    this.setState({ level_id: e.target.value });
    // console.log(this.state.country_id)
  }

  componentDidMount = () => {
    this.getSubject()
  }

  generateNewNumbers = () => {
    this.setState({
      num1: Math.floor(Math.random() * 10) + 1,
      num2: Math.floor(Math.random() * 10) + 1,
      answer: '',
      isCorret: false
    })
  }

  handleUserInput = (event) => {
    this.setState({ userInput: event.target.value })
  };

  getLevel = async (value) => {

    await fetch(Constant.getLevels + '/' + value, {
      method: 'GET'
    })
      .then(Response => Response.json())
      .then(data => {
        // console.log('level', data.data)
        if (data.success === true) {
          this.setState({
            LevelList: data.data,
            isLoading: false
          })
        }
      })
  }

  getSubject = async () => {

    await fetch(Constant.getSubject, {
      method: "GET"
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            SubjectList: data.data
          })
        } else {
          console.log('subject not available')
        }
      })
  }

  onBookSession = async () => {
    this.setState({ isLoading: true })

    const formData = new FormData();
    formData.append("student_name", this.state.student_name);
    formData.append("contact_number", this.state.contact_number);
    formData.append("email", this.state.email);
    formData.append("level_id", this.state.level_id);
    formData.append("subject_id", this.state.subject_id);
    formData.append("topic", this.state.topic);

    await fetch(Constant.bookOnlinEexport, {
      method: 'POST',
      body: formData
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({
            isLoading: false
          })
          window.location.href = Constant.BaseUrl + 'thank-you'
        } else {
          console.log('There is something wrong')
        }
      })
  }

  validate = () => {
    let student_nameError = '';
    let emailError = '';
    let contact_numberError = '';
    let subject_idError = '';
    let level_idError = '';
    let topicError = '';
    let captchError = ''
    let expectedAnswer = this.state.num1 + this.state.num2;

    if (!this.state.student_name) {
      student_nameError = 'Please Enter Your Name'
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Please Enter Email.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid Email Address.";
      }
    }

    if (!this.state.contact_number) {
      contact_numberError = "Please Enter Contact Number"
    } else {
      if (this.state.contact_number.length <= 7) {
        contact_numberError = "Phone number cannot be less than eight digit"
      }
    }

    if (!this.state.level_id) {
      level_idError = "Please select level"
    }
    if (!this.state.subject_id) {
      subject_idError = "Please select subject"
    }
    if (!this.state.topic) {
      topicError = "Please Enter Topic Name"
    }

    if (!this.state.userInput) {
      captchError = "Please Enter Captcha Code"
    } else {
      if (parseInt(this.state.userInput, 10) !== expectedAnswer) {
        captchError = "Please Enter Valid Captcha"
      }
    }

    if (student_nameError || emailError || contact_numberError || level_idError || captchError || subject_idError || topicError) {
      this.setState({
        student_nameError,
        emailError,
        contact_numberError,
        level_idError,
        subject_idError,
        topicError,
        captchError
      })
      return false
    }

    return true
  }

  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        student_nameError: null,
        emailError: null,
        contact_numberError: null,
        level_idError: null,
        subject_idError: null,
        topicError: null
      })
      this.onBookSession();
    }
  }

  handleChangeName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({ student_name: result })
  };
  handleChangePhone = event => {
    const result = event.target.value.replace(/[^0-9]/gi, '');
    this.setState({ contact_number: result })
  };

  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        spinner
        fadeSpeed={10}
        text='Request Sending...'
      >
        <>
          <section id="book-a-turorrr">
            <div className="container">
              <div className="registration-form-outer">
                <div className="container register">
                  <div
                    className="row"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <div className="col-md-12 register-right">
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="step_1">
                          <h1 class="register-heading contact-heading">Book Online Demo</h1>
                          <div className="row tutor-register-form">
                            <div className="col-md-6">
                              <h3 className="step_title left-className">What is your name?</h3>
                              <div className="form-group ">
                                <input type="text" className="form-control" autoComplete="on" value={this.state.student_name} onChange={this.handleChangeName} name="student_name" />
                              </div>
                              {!!this.state.student_nameError && (
                                <text style={{ color: "red", marginLeft: "10px" }}>
                                  {this.state.student_nameError}
                                </text>
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="form-group ">
                                <h4 className="step_title left-className">Email</h4>
                                <input type="email" autoComplete="on" className="form-control" onChange={(event) =>
                                  this.setState({ email: event.target.value })
                                } name="email" />
                              </div>
                              {!!this.state.emailError && (
                                <text style={{ color: "red", marginLeft: "10px" }}>
                                  {this.state.emailError}
                                </text>
                              )}
                            </div>
                            <div className="col-md-6">
                              <div className="form-group ">
                                <h4 className="step_title left-className">Contact Number</h4>
                                <input type="text" className="form-control" autoComplete="on" value={this.state.contact_number} onChange={this.handleChangePhone} id="contact_number" name="contact_number" minLength="10" maxLength="10" />
                              </div>
                              {!!this.state.contact_numberError && (
                                <text style={{ color: "red", marginLeft: "10px" }}>
                                  {this.state.contact_numberError}
                                </text>
                              )}
                            </div>
                            <div className="col-md-6">
                              <h3 className="step_title">Which subject do you want to learn?</h3>
                              <div className="form-group has-feedback form-input">
                                <select className="form-control" autoComplete="on" name="subject_id" id="subject_id" value={this.state.subject_id} onChange={this.handleChangeSubject}>
                                  <option value="">Select Subject</option>
                                  {
                                    this.state.SubjectList.map((item, i) => (
                                      <option key={i} value={item.id}>{item.title}</option>
                                    ))
                                  }
                                </select>
                              </div>
                              {!!this.state.subject_idError && (
                                <text style={{ color: "red", marginLeft: "10px" }}>
                                  {this.state.subject_idError}
                                </text>
                              )}
                            </div>
                            <div className="col-md-6">
                              <h3 className="step_title">What Level Is This For?</h3>
                              <div className="form-group ">
                                {
                                  this.state.LevelList.length === 0 ?
                                    <select name="level_id" className="form-control"  >
                                      <option value="">Level Not Found</option>
                                    </select> : <select name="level_id" className="form-control" value={this.state.level_id} id="level_id" onChange={this.handleChangeLevel}  >
                                      <option value="">Select Year</option>
                                      {
                                        this.state.LevelList.map((item, i) => (
                                          <option key={i} value={item.id}>{item.name}</option>
                                        ))
                                      }
                                    </select>
                                }

                              </div>
                              {!!this.state.level_idError && (
                                <text style={{ color: "red", marginLeft: "10px" }}>
                                  {this.state.level_idError}
                                </text>
                              )}
                            </div>
                            <div className="col-md-6">
                              <h3 className="step_title">Which topic do you want to learn?</h3>
                              <div className="form-group">
                                <input type="text" autoComplete="on" className="form-control" name="topic" onChange={(event) => this.setState({
                                  topic: event.target.value
                                })} />
                              </div>
                              {!!this.state.topicError && (
                                <text style={{ color: "red", marginLeft: "10px" }}>
                                  {this.state.topicError}
                                </text>
                              )}
                            </div>
                            <div className="col-md-6"><p style={{ float: 'left' }} >Enter Captcha: &nbsp;</p></div>
                            <div className="col-md-6 ">
                              <div className="d-flex align-items-center">
                                <span className="equation"> {this.state.num1} + {this.state.num2} =</span>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.userInput}
                                  style={{ width: 60, height: 35 }}
                                  onChange={(e) => this.setState({ userInput: e.target.value })}
                                />
                              </div>
                            </div>
                            {!!this.state.captchError && (
                              <text style={{ color: "red", marginLeft: "10px" }}>
                                {this.state.captchError}
                              </text>
                            )}
                            <div className="col-md-6"></div>
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                              <button className="btn btn-next next_button " onClick={() => this.onSubmit()} type="submit" id="submit" style={{ backgroundColor: 'orange', color: '#fff' }}>Submit</button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      </LoadingOverlay>
    )
  }
}

export default BookOnlineExport;