import React from "react";

//images
import { NavLink, Navigate } from "react-router-dom";
import * as Constant from './Constant'

import LoadingOverlay from 'react-loading-overlay';

class TutorRegistration extends React.Component {
  constructor() {
    super();
    this.state = {
      firstform: true,
      secondform: false,
      thirdform: false,
      fourthform: false,
      fifthform: false,
      sixform: false,
      lastform: false,

      first_name: '',
      last_name: '',
      email: '',
      password: '',
      c_password: '',

      first_nameError: null,
      last_nameError: null,
      emailError: null,
      passwordError: null,
      c_passwordError: null,

      gender_id: 1,
      date_of_birth: '',
      location: '',
      country_id: 99,
      pincode: '',
      contact_number: '',
      profile_picture: '',
      bio: '',
      identity: '',

      genderError: null,
      date_of_birthError: null,
      locationError: null,
      country_idError: null,
      pincodeError: null,
      contact_numberError: null,
      profile_pictureError: null,
      identityError: null,
      bioError: null,

      CountryList: [],
      rowList: [true],

      isLoading: false,
      showInput: false,

      education_level: [],
      field_of_education: [],
      institute_name: [],
      certificate: '',
      any_certificate: [],

      education_levelError: null,
      field_of_educationError: null,
      institute_nameError: null,
      anycertificateError: null,

      work_experience: '',
      teaching_experience: '',
      type_of_student: [],
      organization_name: '',
      exp_certificate: '',

      work_experienceError: null,
      teaching_experienceError: null,
      type_of_studentError: null,
      organization_nameError: null,
      exp_certiError: null,

      subject_id: '',
      preferred_level: '',
      preferred_level_to: '',

      subject_idError: null,

      travel_to_student: '',
      available_hours: '',
      available_days: [],

      travel_to_studentError: null,
      available_hoursError: null,
      available_daysError: null,

      checked: false,
      isActive: false,
      SubjectList: [],
      LevelList: [],
      SubLevelList: [],
      checkEmail: false

    };

    this.handleChangeCountry = this.handleChangeCountry.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeLevel = this.handleChangeLevel.bind(this);
    this.handleChangeSubLevel = this.handleChangeSubLevel.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.travelStudent = this.travelStudent.bind(this);
    this.handleChangeCertificate = this.handleChangeCertificate.bind(this);
    this.changeanyCertificate = this.changeanyCertificate.bind(this);
    this.onChangeEduLevel = this.onChangeEduLevel.bind(this);
    this.availableHours = this.availableHours.bind(this);
    this.availableDaysCheck = this.availableDaysCheck.bind(this);
  }

  handleChangeCheckbox = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      this.setState({
        checked: true,
        type_of_student: [...this.state.type_of_student, value]
      })
    } else {
      this.setState({
        checked: false,
        type_of_student: this.state.type_of_student.filter((e) => e !== value)
      })
    }

  }

  handleBioChange = (event) => {
    const inputText = event.target.value;
    const words = inputText.split(/\s+/);
    const minWords = 150;

    this.setState({ bio: inputText });

    if (words.length >= minWords) {
      this.setState({ bio: inputText, bioError: "" });
    } else {
      this.setState({ bioError: "Bio must be at least 150 words." });
    }
  };

  calculateRows = () => {
    const rowCount = this.state.bio.split("\n").length;
    return Math.max(rowCount, 10);
  };

  availableDaysCheck = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      this.setState({
        checked: true,
        available_days: [...this.state.available_days, value]
      })
    } else {
      this.setState({
        checked: false,
        available_days: this.state.available_days.filter((e) => e !== value)
      })
    }
  }

  nextClick = async (name) => {
    if (name == "firstform") {
      if (this.validateFirst()) {
        const emailExists = await this.chechEmailExist();
        if (emailExists) {
          this.setState({
            firstform: false,
            secondform: true,
            thirdform: false,
            fourthform: false,
            fifthform: false,
            sixform: false,
            lastform: false,
          });
        }
      }
    } else if (name == "secondform") {
      if (this.validateSecond()) {
        this.setState({
          firstform: false,
          secondform: false,
          thirdform: true,
          fourthform: false,
          fifthform: false,
          sixform: false,
          lastform: false,
        });
      }

    } else if (name == "thirdform") {
      if (this.validateThirdform()) {
        this.setState({
          firstform: false,
          secondform: false,
          thirdform: false,
          fourthform: true,
          fifthform: false,
          sixform: false,
          lastform: false,
        });
      }
    } else if (name == "fourthform") {
      if (this.validateForth()) {
        this.setState({
          firstform: false,
          secondform: false,
          thirdform: false,
          fourthform: false,
          fifthform: true,
          sixform: false,
          lastform: false,
        });
      }
    } else if (name == "fifthform") {
      if (this.validateSubject()) {
        this.setState({
          firstform: false,
          secondform: false,
          thirdform: false,
          fourthform: false,
          fifthform: false,
          sixform: true,
          lastform: false,
        });
      }
    } else {
      if (this.validateSixform()) {
        this.setState({
          firstform: false,
          secondform: false,
          thirdform: false,
          fourthform: false,
          fifthform: false,
          sixform: false,
          lastform: true,
        });
      }
    }
  };

  backClick = (name) => {
    if (name == "secondform") {
      this.setState({
        firstform: true,
        secondform: false,
        thirdform: false,
        fourthform: false,
        fifthform: false,
        sixform: false,
        lastform: false,
      });
    } else if (name == "thirdform") {
      this.setState({
        firstform: false,
        secondform: true,
        thirdform: false,
        fourthform: false,
        fifthform: false,
        sixform: false,
        lastform: false,
      });
    } else if (name == "fourthform") {
      this.setState({
        firstform: false,
        secondform: false,
        thirdform: true,
        fourthform: false,
        fifthform: false,
        sixform: false,
        lastform: false,
      });
    } else if (name == "fifthform") {
      this.setState({
        firstform: false,
        secondform: false,
        thirdform: false,
        fourthform: true,
        fifthform: false,
        sixform: false,
        lastform: false,
      });
    } else if (name == "sixform") {
      this.setState({
        firstform: false,
        secondform: false,
        thirdform: false,
        fourthform: false,
        fifthform: true,
        sixform: false,
        lastform: false,
      });
    } else {
      this.setState({
        firstform: false,
        secondform: false,
        thirdform: false,
        fourthform: false,
        fifthform: false,
        sixform: true,
        lastform: false,
      });
    }
  }

  componentDidMount = () => {
    this.getCountry();
    this.getSubject();
  }

  validateFirst = () => {
    let first_nameError = '';
    let last_nameError = '';
    let emailError = '';
    let passwordError = '';
    let c_passwordError = '';

    if (!this.state.first_name) {
      first_nameError = "Please Enter First Name"
    }
    if (!this.state.last_name) {
      last_nameError = "Please Enter Last Name"
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "Please Enter Email"
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid Email Address.";
      }
    }

    var pwd = this.state.password;
    var regPwd = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (!this.state.password) {
      passwordError = "The Password cannot be empty";
    } else {
      if (pwd.length < 8) {
        passwordError = "Password cannot be less than eight characters";
      } else {
        if (!regPwd.test(pwd)) {
          passwordError =
            "Please enter a valid password, and it should contain at least one uppercase letter, one lowercase letter, one number and one special character. e.g. (Welcome@1)";
        }
      }
    }

    if (!this.state.c_password) {
      c_passwordError = "Please Enter Confirm Password"
    } else {
      if (this.state.password !== this.state.c_password) {
        c_passwordError = "The Password does not match. Try again";
      }
    }

    if (first_nameError || last_nameError || emailError || passwordError || c_passwordError) {
      this.setState({
        first_nameError,
        last_nameError,
        emailError,
        passwordError,
        c_passwordError
      })

      return false;
    }

    return true;
  }

  handleChangeLevel(e) {
    console.log("level_id Selected!!", e.target.value);
    this.setState({ level_id: e.target.value });
    this.getSubLevel(e.target.value)
  }

  handleChangeSubLevel(e) {
    console.log("sub_level_id Selected!!", e.target.value);
    this.setState({ sub_level_id: e.target.value });
  }

  validateSecond = () => {
    let genderError = '';
    let date_of_birthError = '';
    let locationError = '';
    let country_idError = '';
    let pincodeError = '';
    let contact_numberError = '';
    let profile_pictureError = '';
    let identityError = '';
    let bioError = '';

    if (!this.state.gender_id) {
      genderError = "Please Select gender"
    }
    if (!this.state.date_of_birth) {
      date_of_birthError = "Please Enter Birth Date"
    }
    if (!this.state.location) {
      locationError = "Please Enter Location"
    }
    if (!this.state.country_id) {
      country_idError = "Please Select Country"
    }
    if (!this.state.pincode) {
      pincodeError = "Please Enter Pincode"
    }

    if (!this.state.contact_number) {
      contact_numberError = "Please Enter Contact Number"
    } else {
      if (this.state.contact_number.length <= 7) {
        contact_numberError = "Phone number cannot be less than eight digit"
      }
    }

    if (!this.state.profile_picture) {
      profile_pictureError = "Please Upload Profile Picture"
    }

    if (!this.state.identity) {
      identityError = "Please Upload Id"
    }
    if (!this.state.bio) {
      bioError = "Please Enter Bio"
    }

    if (genderError || identityError || date_of_birthError || locationError || country_idError || pincodeError || contact_numberError || profile_pictureError || bioError) {
      this.setState({
        genderError,
        date_of_birthError,
        locationError,
        country_idError,
        pincodeError,
        contact_numberError,
        profile_pictureError,
        identityError,
        bioError
      })

      return false;
    }

    console.log('profile', this.state.profile_picture)

    return true
  }

  validateThirdform = () => {
    let education_levelError = '';
    let field_of_educationError = '';
    let institute_nameError = '';
    let anycertificateError = '';

    if (!this.state.education_level) {
      education_levelError = "Please Select Education Level"
    }

    if (!this.state.field_of_education) {
      field_of_educationError = "Please Enter Field of Education"
    }

    if (!this.state.institute_name) {
      institute_nameError = "Please Enter Institute Name"
    }

    if (this.state.any_certificate.length === 0) {
      anycertificateError = "Please Select Certificate or Not"
    }

    if (education_levelError || field_of_educationError || institute_nameError || anycertificateError) {
      this.setState({
        education_levelError,
        field_of_educationError,
        institute_nameError,
        anycertificateError
      })
      return false;
    }

    return true;
  }

  validateForth = () => {
    let work_experienceError = '';
    let teaching_experienceError = '';
    let type_of_studentError = '';
    let organization_nameError = '';
    // let exp_certiError = '';

    if (!this.state.work_experience) {
      work_experienceError = 'Please Enter WorkExperience'
    }

    if (!this.state.teaching_experience) {
      teaching_experienceError = 'Please Enter Teaching Experience'
    }

    if (this.state.type_of_student.length === 0) {
      type_of_studentError = 'Please Select Type of student'
    }

    if (!this.state.organization_name) {
      organization_nameError = 'Please Enter Organization Name'
    }

    // if (!this.state.exp_certificate) {
    //   exp_certiError = 'Please Upload Experience Cerificate'
    // }

    if (work_experienceError || teaching_experienceError || type_of_studentError || organization_nameError) {
      this.setState({
        work_experienceError,
        teaching_experienceError,
        type_of_studentError,
        organization_nameError,
        // exp_certiError
      })

      return false
    }

    return true;

  }

  validateSubject = () => {
    let subject_idError = '';

    if (!this.state.subject_id) {
      subject_idError = "Please Select Subject"
    }

    if (subject_idError) {
      this.setState({
        subject_idError,
      })

      return false;
    }

    return true;
  }

  getSubject = async () => {

    await fetch(Constant.getSubject, {
      method: "GET"
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            SubjectList: data.data
          })
        } else {
          console.log('subject not available')
        }
      })
  }

  getLevel = async (value) => {

    await fetch(Constant.getSubjectLevels + '/' + value, {
      method: 'GET'
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({
            LevelList: data.data,
            isLoading: false
          })
        }
      })
  }

  getSubLevel = async (value) => {
    this.setState({ isLoaded: true })

    await fetch(Constant.getSubLevels + '/' + value, {
      method: 'GET'
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({
            SubLevelList: data.data,
            isLoaded: false
          })
        }
      })
  }

  validateSixform = () => {
    let travel_to_studentError = '';
    let available_hoursError = '';
    let available_daysError = '';

    if (!this.state.travel_to_student) {
      travel_to_studentError = "Please select one of them"
    }

    if (!this.state.available_hours) {
      available_hoursError = "Please select available hours"
    }

    if (this.state.available_days.length === 0) {
      available_daysError = "Please select available days"
    }

    if (travel_to_studentError || available_hoursError || available_daysError) {
      this.setState({
        travel_to_studentError,
        available_hoursError,
        available_daysError
      })

      return false
    }

    return true

  }

  getCountry = async () => {
    await fetch(Constant.getCountry, {
      method: 'GET'
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          // console.log(data.data)
          this.setState({ CountryList: data.data })
        }
      })
  }

  handleChange(event) {
    console.log(event.target.value)
    this.setState({ gender_id: event.target.value });
  }

  handleChangeCountry(e) {
    console.log("country_id Selected!!", e.target.value);
    this.setState({ country_id: e.target.value });
    // console.log(this.state.country_id)
  }

  handleChangeSubject(e) {
    console.log("subject_id Selected!!", e.target.value);
    this.setState({ subject_id: e.target.value });
    this.getLevel(e.target.value);
  }

  changeanyCertificate(i, event) {

    let current_question = this.state.any_certificate;
    current_question[i] = event;
    this.setState({
      any_certificate: current_question
    })

    // console.log(this.state.country_id)
  }

  handleChangeCertificate(i, event) {

    let current_question = this.state.certificate;
    current_question[i] = event;
    this.setState({
      certificate: current_question
    })

    // console.log(this.state.country_id)
  }

  onChangeEduLevel = (i, event) => {
    let current_question = this.state.education_level;
    current_question[i] = event;
    this.setState({
      education_level: current_question
    })

    console.log(this.state.education_level)
  }

  onChangeField = (i, event) => {
    let current_question = this.state.field_of_education;
    current_question[i] = event;
    this.setState({
      field_of_education: current_question
    })

    console.log(this.state.field_of_education)
  }

  onChangeInstitute = (i, event) => {
    let current_question = this.state.institute_name;
    current_question[i] = event;
    this.setState({
      institute_name: current_question
    })

    console.log(this.state.institute_name)
  }

  travelStudent = (value) => {
    if (value === 'yes') {
      this.setState({
        travel_to_student: 'yes'
      })
    } else {
      this.setState({
        travel_to_student: 'no'
      })
    }
  }

  chechEmailExist = async () => {
    this.setState({ checkEmail: true });

    const formData = new FormData();
    formData.append("email", this.state.email);

    try {
      const response = await fetch(Constant.emailExist, {
        method: "POST",
        body: formData
      });
      const data = await response.json();

      if (data.success === false) {
        this.setState({
          emailError: data.data,
          checkEmail: false
        });
        return false;
      } else {
        this.setState({
          checkEmail: false
        });
        return true;
      }
    } catch (error) {
      console.error('Error:', error);
      this.setState({
        checkEmail: false
      });
      return false;
    }
  };

  onSubmit = async () => {
    this.setState({ isLoading: true, isActive: true })

    const formData = new FormData();
    formData.append("first_name", this.state.first_name);
    formData.append("last_name", this.state.last_name);
    formData.append("role_id", 4);
    formData.append("email", this.state.email);
    formData.append("contact_number", this.state.contact_number);
    formData.append("password", this.state.password);
    formData.append("c_password", this.state.c_password);
    formData.append("country_id", this.state.country_id);
    formData.append("education_level", this.state.education_level);
    formData.append("field_of_education", this.state.field_of_education);
    formData.append("institute_name", this.state.institute_name);
    formData.append("certificate", this.state.certificate);
    formData.append("any_certificate", this.state.any_certificate);
    formData.append("gender_id", this.state.gender_id);
    formData.append("location", this.state.location);
    formData.append("date_of_birth", this.state.date_of_birth);
    formData.append("pincode", this.state.pincode);
    formData.append("profile_picture", this.state.profile_picture);
    formData.append("identity", this.state.identity);
    formData.append("bio", this.state.bio);
    formData.append("subject_id", this.state.subject_id);
    formData.append("work_experience", this.state.work_experience);
    formData.append("teaching_experience", this.state.teaching_experience);
    formData.append("type_of_student", this.state.type_of_student);
    formData.append("organization_name", this.state.organization_name);
    formData.append("exp_certificate", this.state.exp_certificate);
    formData.append("level_id", this.state.level_id);
    formData.append("sub_level_id", this.state.sub_level_id);
    formData.append("travel_to_student", this.state.travel_to_student);
    formData.append("available_hours", this.state.available_hours);
    formData.append("available_days", this.state.available_days);


    await fetch(Constant.TutorRegisterUrl, {
      method: "POST",
      body: formData
    })
      .then(Response => Response.json())
      .then(data => {
        console.log(data)
        if (data.success === true) {
          this.setState({
            isLoading: false,
            isActive: false
          })
          window.location.href = Constant.BaseUrl + 'thank-you-register'
        } else {
          this.setState({
            isActive: false
          })
          this.setState({
            firstform: false,
            secondform: true,
            thirdform: false,
            fourthform: false,
            fifthform: false,
            lastform: false,
            contact_numberError: data.data.contact_number
          });
        }
      })
  }

  availableHours = (value) => {
    if (value === 'upto_2') {
      this.setState({
        available_hours: 'upto_2'
      })
    } else if (value === '2-4') {
      this.setState({
        available_hours: '2-4'
      })
    } else if (value === '4-6') {
      this.setState({
        available_hours: '4-6'
      })
    } else if (value === '6-8') {
      this.setState({
        available_hours: '6-8'
      })
    } else {
      this.setState({
        available_hours: '8-10'
      })
    }
  }

  handleChangeFirstName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({ first_name: result })
  };

  handleChangeLastName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({ last_name: result })
  };

  teachingExperience = (event) => {
    const result = event.target.value.replace(/[^0-9]/gi, "");
    if (/^\d{0,2}$/.test(result)) {
      this.setState({ teaching_experience: result });
    }
  };

  handleWorkExp = (event) => {
    const result = event.target.value.replace(/[^0-9]/gi, "");

    if (/^\d{0,2}$/.test(result)) {
      this.setState({ work_experience: result });
    }
  };

  handleChangePhone = event => {
    const result = event.target.value.replace(/[^0-9]/gi, '');
    this.setState({ contact_number: result })
  };

  addMore = () => {
    let rowList = [...this.state.rowList];
    rowList.push(true);
    this.setState({ rowList });
  }

  render() {

    const wordCount = this.state.bio
      .split(/\s+/)
      .filter((word) => word !== "").length;

    let { rowList } = this.state;

    var userId = localStorage.getItem('userId');

    if (userId === null || userId == 3) {
      return (
        <LoadingOverlay
          active={this.state.isActive}
          spinner
          fadeSpeed={10}
          text='Register...'
        >
          <section id="book-a-turorrr">
            <div className="container">
              <div className="registration-form-outer">
                <div className="container register">
                  <div
                    className="row"
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <div className="col-md-12 register-right">
                      <div className="tab-content" id="myTabContent">
                        {this.state.firstform == true ? (
                          <div className="tab-pane fade show active" id="step_1">
                            <h1 className="register-heading contact-heading">
                              Become A Tutor
                            </h1>
                            <div className="row tutor-register-form">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>First Name</h5>
                                  <input
                                    type="text"
                                    className="form-control height"
                                    name="first_name"
                                    placeholder="First Name*"
                                    value={this.state.first_name}
                                    required
                                    onChange={this.handleChangeFirstName}
                                  />

                                  {!!this.state.first_nameError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.first_nameError}
                                    </text>
                                  )}
                                </div>
                                <div className="form-group">
                                  <h5>Email</h5>
                                  <input
                                    type="email"
                                    className="form-control height"
                                    id="email"
                                    name="email"
                                    placeholder="Email*"
                                    value={this.state.email}
                                    required
                                    onChange={(event) =>
                                      this.setState({ email: event.target.value })
                                    }
                                  />
                                  {!!this.state.emailError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.emailError}
                                    </text>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Last Name</h5>
                                  <input
                                    type="text"
                                    className="form-control height"
                                    name="last_name"
                                    placeholder="Last Name*"
                                    required
                                    value={this.state.last_name}
                                    onChange={this.handleChangeLastName}
                                  />
                                  {!!this.state.last_nameError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.last_nameError}
                                    </text>
                                  )}
                                </div>
                                <div className="form-group">
                                  <h5>Password</h5>
                                  <input
                                    type="password"
                                    className="form-control height"
                                    id="password"
                                    name="password"
                                    placeholder="Password*"
                                    required
                                    value={this.state.password}
                                    onChange={(event) =>
                                      this.setState({ password: event.target.value })
                                    }
                                  />
                                  {!!this.state.passwordError && (
                                    <text style={{ fontSize: '15px', color: "red" }}>
                                      {this.state.passwordError}
                                    </text>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Confirm Password</h5>
                                  <input
                                    type="password"
                                    className="form-control height"
                                    placeholder="Confirm Password*"
                                    required
                                    value={this.state.c_password}
                                    onChange={(event) =>
                                      this.setState({ c_password: event.target.value })
                                    }
                                    name="c_password"
                                    id="c_password"
                                  />
                                  {!!this.state.c_passwordError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.c_passwordError}
                                    </text>
                                  )}
                                </div>
                              </div>
                            </div>
                            <a
                              className="btn btn-next next_button"
                              href="javascript:void(0);"
                              onClick={() => this.nextClick("firstform")}
                            >
                              Next
                            </a>
                            <NavLink
                              to="/register"
                              className=" backbutton Back btn"
                            >
                              Back
                            </NavLink>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.secondform == true ? (
                          <div id="step_2">
                            <h3 className="register-heading contact-heading">
                              Profile
                            </h3>
                            <div className="row tutor-register-form">
                              <div className="col-md-6">
                                <h5> Gender</h5>
                                <div className="form-group">
                                  <select
                                    className="form-control height"
                                    name="gender_id"
                                    id="gender_id"
                                    required
                                    autoComplete="on"
                                    value={this.state.gender_id}
                                    onChange={this.handleChange}
                                  >
                                    <option value={1}>Male</option>
                                    <option value={2}>Female</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Date Of Birth</h5>
                                  <input
                                    type="date"
                                    id="date_of_birth"
                                    name="date_of_birth"
                                    className="form-control height"
                                    required
                                    autoComplete="on"
                                    value={this.state.date_of_birth}
                                    onChange={(event) =>
                                      this.setState({ date_of_birth: event.target.value })
                                    }
                                  />
                                  {!!this.state.date_of_birthError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.date_of_birthError}
                                    </text>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Location(City)</h5>
                                  <input
                                    type="text"
                                    className="form-control height"
                                    placeholder="Location*"
                                    id="location"
                                    name="location"
                                    autoComplete="on"
                                    value={this.state.location}
                                    required
                                    onChange={(event) =>
                                      this.setState({ location: event.target.value })
                                    }
                                  />
                                  {!!this.state.locationError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.locationError}
                                    </text>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Country</h5>
                                  <select name="country_id" id="country_id" value={this.state.country_id} onChange={this.handleChangeCountry} className="form-control height">
                                    <option value="">Select Country</option>
                                    {
                                      this.state.CountryList.map((item, i) => (
                                        <option key={i} value={item.id}>{item.name}</option>
                                      ))
                                    }
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Pincode</h5>
                                  <input
                                    type="number"
                                    placeholder="Pincode*"
                                    className="form-control height"
                                    id="pincode"
                                    name="pincode"
                                    autoComplete="on"
                                    value={this.state.pincode}
                                    required
                                    onChange={(event) =>
                                      this.setState({ pincode: event.target.value })
                                    }
                                  />
                                  {!!this.state.pincodeError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.pincodeError}
                                    </text>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Phone</h5>
                                  <input
                                    type="text"
                                    id="contact_number"
                                    minLength="11"
                                    maxLength="11"
                                    className="form-control height"
                                    name="contact_number"
                                    placeholder="Mobile"
                                    value={this.state.contact_number}
                                    onChange={this.handleChangePhone}
                                    required
                                  />
                                  {!!this.state.contact_numberError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.contact_numberError}
                                    </text>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Profile</h5>

                                  <input
                                    type="file"
                                    id="profile_picture"
                                    name="profile_picture"
                                    accept=".png,.jpg"
                                    onChange={(event) => this.setState({
                                      profile_picture: event.target.files[0].name
                                    })}
                                  />
                                </div>
                                {!!this.state.profile_pictureError && (
                                  <text style={{ color: "red", marginLeft: "10px" }}>
                                    {this.state.profile_pictureError}
                                  </text>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>Upload Id(Adhar,Licence,Passport)</h5>
                                  <input
                                    type="file"
                                    id="identity"
                                    name="identity"
                                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                    onChange={(event) => this.setState({
                                      identity: event.target.files[0].name
                                    })}
                                  />
                                </div>
                                {!!this.state.identityError && (
                                  <text style={{ color: "red", marginLeft: "10px" }}>
                                    {this.state.identityError}
                                  </text>
                                )}
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <h5>Bio</h5>
                                  <textarea
                                    className="form-control "
                                    style={{
                                      maxHeight: "200px",
                                      minHeight: "100px",
                                      overflowY: "scroll",
                                    }}
                                    rows="18"
                                    id="bio"
                                    name="bio"
                                    autoComplete="on"
                                    value={this.state.bio}
                                    placeholder="Bio"
                                    onChange={this.handleBioChange}
                                  ></textarea>
                                  <p className="d-flex text-right justify-content-end">
                                    Word Count: {wordCount}</p>
                                  {!!this.state.bioError && (
                                    <text style={{ color: "red", marginLeft: "10px" }}>
                                      {this.state.bioError}
                                    </text>
                                  )}
                                </div>
                              </div>
                            </div>

                            <a
                              className="btn btn-next next_button"
                              href="javascript:void(0);"
                              onClick={() => this.nextClick("secondform")}
                            >
                              Next
                            </a>
                            <a
                              className="btn back_btn backbutton"
                              href="javascript:void(0);"
                              onClick={() => this.backClick("secondform")}
                            >
                              Back
                            </a>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.thirdform == true ? (
                          <div id="step_3">
                            <h3 className="register-heading contact-heading">
                              Educational Qualification
                            </h3>

                            <div className="row tutor-register-form" style={{ paddingTop: '15%' }}>
                              <div>
                                <a
                                  style={{ marginBottom: 0, marginRight: '3%' }}
                                  className="btn btn-next next_button"
                                  href="javascript:void(0);"
                                  onClick={this.addMore}
                                >
                                  Add More
                                </a>
                              </div>
                              {rowList.map((x, i) => {
                                return (
                                  <div className="row mt-4" key={i}>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <h5>Education Level</h5>
                                        <select name="education_level" id="education_level" value={this.state.education_level[i]} onChange={(event) => this.onChangeEduLevel(i, event.target.value)} className="form-control height">
                                          <option value="">Select Level</option>
                                          <option value="under_garduate">Under Graduate</option>
                                          <option value="graduate">Graduate</option>
                                          <option value="post_graduate">Post Graduate</option>
                                          <option value="doctorate">Doctorate</option>
                                        </select>
                                      </div>
                                      {!!this.state.education_levelError && (
                                        <text style={{ color: "red", marginLeft: "10px" }}>
                                          {this.state.education_levelError}
                                        </text>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <h5>Field Of Education</h5>
                                        <input
                                          type="text"
                                          className="form-control height"
                                          name="field_of_education"
                                          placeholder="Field Of Education*"
                                          required
                                          value={this.state.field_of_education[i]}
                                          onChange={(event) => this.onChangeField(i, event.target.value)
                                          }
                                        />
                                      </div>
                                      {!!this.state.field_of_educationError && (
                                        <text style={{ color: "red", marginLeft: "10px" }}>
                                          {this.state.field_of_educationError}
                                        </text>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <h5>Institute Name</h5>
                                        <input
                                          type="text"
                                          className="form-control height"
                                          name="institute_name"
                                          placeholder="Institute Name*"
                                          required
                                          value={this.state.institute_name[i]}
                                          onChange={(event) => this.onChangeInstitute(i, event.target.value)
                                          }
                                        />
                                      </div>
                                      {!!this.state.institute_nameError && (
                                        <text style={{ color: "red", marginLeft: "10px" }}>
                                          {this.state.institute_nameError}
                                        </text>
                                      )}
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <h5>Any Certificate</h5>
                                        <select name="any_certificate" id="any_certificate" onChange={(event) => this.changeanyCertificate(i, event.target.value)} value={this.state.any_certificate[i]} className="form-control height">
                                          <option value="">Select</option>
                                          <option value="yes">Yes</option>
                                          <option value="no">No</option>
                                        </select>
                                      </div>
                                      {!!this.state.anycertificateError && (
                                        <text style={{ color: "red", marginLeft: "10px" }}>
                                          {this.state.anycertificateError}
                                        </text>
                                      )}
                                    </div>

                                    {
                                      this.state.any_certificate[i] === 'yes' ? <div className="col-md-6">
                                        <div className="form-group">
                                          <h5>Upload Docs</h5>
                                          <input
                                            type="file"
                                            id="certificate"
                                            name="certificate"
                                            accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                            value={this.state.certificate[i]}
                                            onChange={(event) => this.handleChangeCertificate(
                                              i, event.target.files[0].name
                                            )}
                                          />
                                        </div>
                                      </div> : ''
                                    }

                                  </div>
                                )
                              })}
                            </div>

                            <a
                              className="btn btn-next next_button"
                              href="javascript:void(0);"
                              onClick={() => this.nextClick("thirdform")}
                            >
                              Next
                            </a>
                            <a
                              className="btn back_btn backbutton"
                              href="javascript:void(0);"
                              onClick={() => this.backClick('thirdform')}
                            >
                              Back
                            </a>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.fourthform == true ? (
                          <div id="step_4">
                            <h3 className="register-heading contact-heading">
                              Work Experience
                            </h3>
                            <div className="row tutor-register-form">
                              <div className="col-md-6">
                                <div
                                  className="form-group"
                                  id="years_of_experience"
                                >
                                  <h5>
                                    Work Experience in Years/Months
                                  </h5>

                                  <input
                                    type="text"
                                    className="form-control height mt-2"
                                    name="work_experience"
                                    placeholder="Work Experience*"
                                    required
                                    autoComplete="on"
                                    value={this.state.work_experience}
                                    onChange={this.handleWorkExp}
                                  />
                                </div>
                                {!!this.state.work_experienceError && (
                                  <text style={{ color: "red", marginLeft: "10px" }}>
                                    {this.state.work_experienceError}
                                  </text>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-group"
                                  id="teaching_experience" >
                                  <h5> Teaching Experience in Years/Months </h5>
                                  <input
                                    type="text"
                                    className="form-control height mt-2"
                                    name="teaching_experience"
                                    placeholder="Teaching Experience*"
                                    required
                                    autoComplete="on"
                                    maxLength={2}
                                    value={this.state.teaching_experience}
                                    onChange={this.teachingExperience}
                                  />
                                </div>
                                {!!this.state.teaching_experienceError && (
                                  <text style={{ color: "red", marginLeft: "10px" }}>
                                    {this.state.teaching_experienceError}
                                  </text>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <h5>
                                    Level Of Student Taught
                                  </h5>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      id="primary"
                                      name="type_of_student"
                                      value='primary'
                                      onChange={this.handleChangeCheckbox}
                                    />
                                    <label for="0" className="ml-2">
                                      Primary
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      id="secondary"
                                      name="type_of_student"
                                      value="secondary"
                                      onChange={this.handleChangeCheckbox}
                                    />
                                    <label for="1" className="ml-2">
                                      Secondary
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      id="college"
                                      name="type_of_student"
                                      value="college"
                                      onChange={this.handleChangeCheckbox}
                                    />
                                    <label for="2-4" className="ml-2">
                                      College
                                    </label>
                                  </div>
                                </div>

                                {!!this.state.type_of_studentError && (
                                  <text style={{ color: "red", marginLeft: "10px" }}>
                                    {this.state.type_of_studentError}
                                  </text>
                                )}
                              </div>

                              <div className="col-md-6">
                                <div className="form-group"
                                  id="teaching_experience" >
                                  <h5>
                                    Organization Name With City
                                  </h5>
                                  <input
                                    type="text"
                                    className="form-control height mt-2"
                                    name="organization_name"
                                    placeholder="Organization Name*"
                                    required
                                    autoComplete="on"
                                    value={this.state.organization_name}
                                    onChange={(event) =>
                                      this.setState({ organization_name: event.target.value })
                                    }
                                  />
                                </div>
                                {!!this.state.organization_nameError && (
                                  <text style={{ color: "red", marginLeft: "10px" }}>
                                    {this.state.organization_nameError}
                                  </text>
                                )}
                              </div>

                              <div className="col-md-6">
                                <div className="form-group mt-2" >
                                  <h5>
                                    Upload Work Experience Certificate
                                  </h5>
                                  <input
                                    type="file"
                                    id="exp_certificate"
                                    name="exp_certificate"
                                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                    onChange={(event) => this.setState({
                                      exp_certificate: event.target.files[0].name
                                    })}
                                  />
                                </div>
                              </div>
                            </div>
                            <a
                              className="btn btn-next next_button"
                              href="javascript:void(0);"
                              onClick={() => this.nextClick("fourthform")}
                            >
                              Next
                            </a>
                            <a
                              className="btn back_btn backbutton"
                              href="javascript:void(0);"
                              onClick={() => this.backClick('fourthform')}
                            >
                              Back
                            </a>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.fifthform == true ? (
                          <div id="step_3">
                            <h3 className="register-heading contact-heading">
                              Subjects
                            </h3>

                            <div className="row tutor-register-form">
                              <div className="row mt-4">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <h5>Subject Preference</h5>
                                    <select name="subject_id" id="subject_id" value={this.state.subject_id} onChange={this.handleChangeSubject} className="form-control height">
                                      <option value="">Select Subject</option>
                                      {
                                        this.state.SubjectList.map((item, i) => (
                                          <option key={i} value={item.id}>{item.title}</option>
                                        ))
                                      }
                                    </select>
                                    {!!this.state.subject_idError && (
                                      <text style={{ color: "red", marginLeft: "10px" }}>
                                        {this.state.subject_idError}
                                      </text>
                                    )}

                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <h5>Preferred Level</h5>
                                    {
                                      this.state.LevelList.length === 0 ? <select className="form-control"><option>Level Not Found</option></select> :
                                        <select name="level_id" autoComplete="on" value={this.state.level_id} id="level_id" onChange={this.handleChangeLevel} className="form-control">
                                          <option value="">Select Level</option>
                                          {
                                            this.state.LevelList.map((item, i) => (
                                              <option key={i} value={item.id}>{item.name}</option>
                                            ))
                                          }
                                        </select>
                                    }
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <h5>Preferred Sub Level</h5>
                                    {
                                      this.state.SubLevelList.length === 0 ? <select className="form-control"><option>Level Not Found</option></select> :
                                        <select name="sub_level_id" autoComplete="on" value={this.state.sub_level_id} id="sub_level_id" onChange={this.handleChangeSubLevel} className="form-control">
                                          <option value="">Select Level</option>
                                          {
                                            this.state.SubLevelList.map((item, i) => (
                                              <option key={i} value={item.id}>{item.name}</option>
                                            ))
                                          }
                                        </select>
                                    }
                                  </div>
                                </div>
                              </div>

                            </div>

                            <a
                              className="btn btn-next next_button"
                              href="javascript:void(0);"
                              onClick={() => this.nextClick("fifthform")}
                            >
                              Next
                            </a>
                            <a
                              className="btn back_btn backbutton"
                              href="javascript:void(0);"
                              onClick={() => this.backClick('fifthform')}
                            >
                              Back
                            </a>
                          </div>
                        ) : ''
                        }

                        {this.state.sixform == true ? (
                          <div id="step_5">
                            <h3 className="register-heading contact-heading">
                              Work  Availability
                            </h3>
                            <div className="row tutor-register-form ">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="heading-lable black">
                                    Are you willing to travel to Student?
                                  </label>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="radio"
                                      id="yes"
                                      name="travel_to_student"
                                      value="Yes"
                                      onChange={() => this.travelStudent('yes')}
                                    />
                                    <label for="yes" className="ml-2">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="radio"
                                      id="no"
                                      name="travel_to_student"
                                      value="no"
                                      onChange={() => this.travelStudent('no')}
                                    />
                                    <label for="no" className="ml-2">
                                      No
                                    </label>
                                  </div>
                                </div>
                                {!!this.state.travel_to_studentError && (
                                  <text style={{ color: "red", marginLeft: "10px" }}>
                                    {this.state.travel_to_studentError}
                                  </text>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="heading-lable black">
                                    How many hours a day are you willing to work?
                                  </label>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="radio"
                                      name="available_hours"
                                      value="upto_2"
                                      onChange={() => this.availableHours('upto_2')}
                                    />
                                    <label for="yes" className="ml-2">
                                      Upto 2
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="radio"
                                      name="available_hours"
                                      value="2-4"
                                      onChange={() => this.availableHours('2-4')}
                                    />
                                    <label for="no" className="ml-2">
                                      2-4
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="radio"
                                      name="available_hours"
                                      value="4-6"
                                      onChange={() => this.availableHours('4-6')}
                                    />
                                    <label for="no" className="ml-2">
                                      4-6
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="radio"
                                      name="available_hours"
                                      value="6-8"
                                      onChange={() => this.availableHours('6-8')}
                                    />
                                    <label for="no" className="ml-2">
                                      6-8
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="radio"
                                      name="available_hours"
                                      value="8-10"
                                      onChange={() => this.availableHours('8-10')}
                                    />
                                    <label for="no" className="ml-2">
                                      8-10
                                    </label>
                                  </div>
                                </div>
                                {!!this.state.available_hoursError && (
                                  <text style={{ color: "red", marginLeft: "10px" }}>
                                    {this.state.available_hoursError}
                                  </text>
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="heading-lable black">
                                    What days are you available for sessions?
                                  </label>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      name="available_days"
                                      value="monday"
                                      onChange={this.availableDaysCheck}
                                    />
                                    <label for="yes" className="ml-2">
                                      Monday
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      name="available_days"
                                      value="tuesday"
                                      onChange={this.availableDaysCheck}
                                    />
                                    <label for="no" className="ml-2">
                                      Tuesday
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      name="available_days"
                                      value="wednesday"
                                      onChange={this.availableDaysCheck}
                                    />
                                    <label for="no" className="ml-2">
                                      Wednesday
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      name="available_days"
                                      value="thursday"
                                      onChange={this.availableDaysCheck}
                                    />
                                    <label for="no" className="ml-2">
                                      Thursday
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      name="available_days"
                                      value="friday"
                                      onChange={this.availableDaysCheck}
                                    />
                                    <label for="no" className="ml-2">
                                      Friday
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      name="available_days"
                                      value="saturday"
                                      onChange={this.availableDaysCheck}
                                    />
                                    <label for="no" className="ml-2">
                                      Saturday
                                    </label>
                                  </div>
                                  <div className="radio-outer-single black">
                                    <input
                                      type="checkbox"
                                      name="available_days"
                                      value="sunday"
                                      onChange={this.availableDaysCheck}
                                    />
                                    <label for="no" className="ml-2">
                                      Sunday
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {!!this.state.available_daysError && (
                                <text style={{ color: "red", marginLeft: "10px" }}>
                                  {this.state.available_daysError}
                                </text>
                              )}
                            </div>
                            <a
                              className="btn btn-next next_button"
                              href="javascript:void(0);"
                              onClick={() => this.nextClick("sixform")}
                            >
                              Next
                            </a>
                            <a
                              className="btn back_btn backbutton"
                              href="javascript:void(0);"
                              onClick={() => this.backClick('sixform')}
                            >
                              Back
                            </a>
                          </div>
                        ) : (
                          ""
                        )}

                        {this.state.lastform == true ? (
                          <div id="step_6">
                            <h3 className="register-heading contact-heading">
                              Terms & Conditions
                            </h3>
                            <div className="tutor-register-form-terms">
                              <div className="form-group scroll" style={{ width: 900 }}>
                                <p>
                                  1. Education Capsule reserves the right to alter
                                  these terms and conditions.
                                </p>
                                <p>
                                  2. No variation or modification of these Terms and
                                  Conditions by the Tutor shall be valid unless
                                  approved in writing by Education Capsule.
                                </p>
                                <p>
                                  3. The Tutor and Education Capsule acknowledge
                                  that these terms govern legal rights and
                                  obligations between them.
                                </p>
                                <p>
                                  4. Education Capsule acts as an employer for the
                                  Tutor by introducing them to suitable students.
                                </p>
                                <p>
                                  5. Once a tutoring position has been confirmed,
                                  Tutors are entering into a contract between Tutors
                                  (the Tutor) and the Education Capsule (the
                                  employer for a period of 6 months).
                                </p>
                                <p>
                                  6. The Tutor has no power or authority to
                                  increase, reduce, or vary the tuition as set out
                                  or referred to in these Terms or to alter or waive
                                  any of these Terms or to bind or commit the
                                  Company in any way.
                                </p>
                                <p>
                                  7. The Tutor should carry out the tutoring
                                  services in a professional manner with due skill,
                                  diligence and care. Tutors will provide the
                                  tutoring services in accordance with laws and
                                  regulations applicable in the state or country
                                  where Tutors are providing the tutoring services.
                                </p>
                                <p>
                                  8. The Tutor must ensure the safeguarding of
                                  students.
                                </p>
                                <p>
                                  9. The Tutor is responsible for the content of
                                  lessons. These are to be carried out with proper
                                  preparation in accordance with a particular course
                                  of study, and in accordance with the Client’s
                                  instructions and/or relevant exam syllabus.
                                  Reports must be written after each lesson and
                                  uploaded to the tutor’s Education Capsule portal
                                  within 2 hours of the lesson.
                                </p>
                                <p>
                                  10. Although Education Capsule has some teaching
                                  resources and may be able to support Tutors by
                                  providing suitable materials, Tutors should be
                                  aware that they are responsible for providing all
                                  reasonable teaching materials.
                                </p>
                                <p>
                                  11. Tutors are reminded that they agree not to
                                  accept funds directly from the Client for any
                                  reason.
                                </p>
                                <p>
                                  12. Tutors are responsible for keeping their time
                                  sheets up to date.
                                </p>
                                <p>
                                  13. Tutors must ensure the availability of a
                                  strong WIFI connection and a pair of headphones.
                                </p>
                                <p>
                                  14. Tutors must ensure that they are not using or
                                  sharing any official data or resources for
                                  personal use or with any one respectively.
                                </p>
                                <p>
                                  15. Tutors are expected to be responsive when
                                  contacted by the Education Capsule. If Tutors
                                  persistently fail to respond to communication from
                                  Education Capsule (via email or telephone or both)
                                  then we reserve the right to remove Tutors from
                                  our books.
                                </p>
                                <p>
                                  16. Education Capsule reserves the right to remove
                                  Tutors from our books for breaching this agreement
                                  in any way. In addition, the Education Capsule
                                  reserves the right to remove Tutors from our books
                                  due to unreasonable, inappropriate or rude
                                  behaviour such that we feel we are unable to act
                                  as Tutor’s employer.
                                </p>
                                <p>
                                  17. In consideration of Education Capsule
                                  providing Tutors with Students, Tutors are not
                                  permitted to make private arrangements for tuition
                                  with Students introduced by Education Capsule or
                                  with new students introduced by Education Capsule’
                                  student. This shall lead to termination and a fine
                                  of £1000.
                                </p>
                                <p>
                                  18. The Tutor will not provide or agree to provide
                                  any services or carry out any work for the Client
                                  or the Student other than the Tuition, save with
                                  the prior consent of Education Capsule.
                                </p>
                                <p>
                                  19. The Tutor must notify the Education Capsule of
                                  any planned holiday dates in advance.
                                </p>
                                <p>
                                  20. Education Capsule does not accept any
                                  liability for any claims by the Client arising out
                                  of or related to the carrying out of the tutoring
                                  by Tutors and Tutors agree to indemnify Education
                                  Capsule without limit in respect of any such
                                  claims.
                                </p>
                                <p>
                                  21. The Tutor undertakes that they will not at any
                                  time during or after the conclusion of this
                                  agreement, disclose to any person any confidential
                                  information concerning the business, affairs,
                                  customers, clients or suppliers of Education
                                  Capsule, except that Tutors may disclose Education
                                  Capsule’ confidential information as may be
                                  required by law, court order, or any governmental
                                  or regulatory authority.
                                </p>
                                <p>
                                  22. The Tutor undertakes not to make use of
                                  confidential information encountered through
                                  association with Education Capsule for any purpose
                                  other than to perform Tutors r obligations under
                                  this Agreement.
                                </p>
                                <p>
                                  23. Education Capsule uses tutor data for the
                                  purposes of the services that it provides.
                                </p>
                                <p>
                                  24. Education Capsule may record and monitor
                                  Service sessions, including, but not limited to,
                                  any audios, videos, chats, files, and documents,
                                  at any time for quality assurance and any other
                                  internal purposes as Education Capsule deems
                                  necessary without further notice of consent to
                                  Tutors .
                                </p>
                                <p>
                                  25. In any time during Tutors’ employment, Tutors
                                  are found guilty of any misconduct of any wilful
                                  breach or negligence of the terms of this offer /
                                  appointment letter or the rules or dereliction of
                                  duty and/or instructions given to Tutors from time
                                  to time, the management may without any notice or
                                  payment in lieu of any notice, terminate Tutors
                                  employment with the Company.
                                </p>
                              </div>
                            </div>
                            <button
                              className="btn next_button"
                              type="submit"
                              id="submit"
                              onClick={() => this.onSubmit()}
                              style={{ backgroundColor: "orange", float: "right" }}
                            >
                              Submit

                            </button>
                            <a
                              className="btn back_btn backbutton"
                              href="javascript:void(0);"
                              onClick={() => this.backClick('lastform')}
                            >
                              Back
                            </a>
                          </div>
                        ) : (
                          ""
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LoadingOverlay>
      );
    } else {
      return <Navigate to="/tutor-dashboard" />;
    }

  }
}

export default TutorRegistration;
